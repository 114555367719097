import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useDebounce } from 'use-debounce';
import { Grid, Typography } from '@mui/material';

import { useUser } from '../../../auth/useUser';
import { getDistributionStateWithholding } from '../../../api/DistributionApi';
import { StateWithholdingPercentType } from '../../../api/DistributionApi.d';
import { DistributionAmountFormData } from './DistributionAmountForm.d';
import SiraPercentField from '../SiraPercentField';
import SiraCurrencyField from '../SiraCurrencyField';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import { errorMessages } from '../../../utils/errorhandling.utils';

function StateWithholdingFields() {
  let isMounted = true;
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const { values, setFieldValue } = useFormikContext();
  const [isLoading, setIsLoading] = useState(false);
  const [disablePercent, setDisablePercent] = useState(true);
  const [showAdditionalState, setShowAdditionalState] = useState(false);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [debouncedDistribution] = useDebounce(values, 600);
  const { withholdingState, stateWithholdingPercent, allowNoneState } =
    debouncedDistribution as DistributionAmountFormData;
  const [withholdingValues, setWithholdingValues] = useState(false);
  // if state is CA federal Withholding/10%
  // if state is VA federal Withholding/3%
  const statesAndPercent = {
    CA: 10,
    VT: 30,
  };

  async function onWithholdingStateChange() {
    await getDistributionStateWithholding(
      values as DistributionAmountFormData,
      user.token,
      user,
    )
      .then((res) => {
        const {
          percent = 0,
          canSpecifyPercent = StateWithholdingPercentType.disabled,
          canSpecifyAmount = false,
        } = res.data;


        if (
          canSpecifyAmount &&
          canSpecifyPercent === StateWithholdingPercentType.disabled &&
          percent === 0
        ) {
          setWithholdingValues(true);
        } else {
          setWithholdingValues(false);
        }

        // Check for user-entered value on mount and don't overwrite it
        const useRecommendedWithholding =
          hasInitialized ||
          (hasInitialized &&
            stateWithholdingPercent.toString() === percent.toString());

        if (isMounted) {
          // Hold these values only in formik state for schema validation

          setFieldValue('canSpecifyPercent', canSpecifyPercent);
          setShowAdditionalState(canSpecifyAmount);
          setDisablePercent(
            canSpecifyPercent === StateWithholdingPercentType.disabled,
          );
          if (!canSpecifyAmount) {
            setFieldValue('additionalStateWithholding', '');
          }

          // check to see if state has a value in the object
          if (statesAndPercent[withholdingState]) {
            // run calculation with state value
            const percent = statesAndPercent[withholdingState];
            const { federalWithholdingPercent } = values as any;
            const amount =
              (parseInt(federalWithholdingPercent) * percent) / 100;
            setFieldValue('stateWithholdingPercent', amount);
            setFieldValue('suggestedStateWithholding', amount);
          } else {
            setFieldValue('suggestedStateWithholding', percent);
            if (useRecommendedWithholding) {
              setFieldValue('stateWithholdingPercent', percent);
            }
          }

          setHasInitialized(true);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setFieldValue('additionalStateWithholding', '');
          setFieldValue('stateWithholdingPercent', '0');
          setDisablePercent(false);
          setShowAdditionalState(false);
          setIsLoading(false);
          addGlobalMessage(errorMessages(err));
        }
      });
  }

  useEffect(() => {
    if (withholdingState && !allowNoneState) {
      setIsLoading(true);
      onWithholdingStateChange();
    } else {
      // check to see if state has a value in the object
      if (statesAndPercent[withholdingState]) {
        // run calculation with state value
        const percent = statesAndPercent[withholdingState];
        const { federalWithholdingPercent } = values as any;
        const amount =
          (parseInt(federalWithholdingPercent) * percent) / 100;
        setFieldValue('stateWithholdingPercent', amount);
        setFieldValue('suggestedStateWithholding', amount);
      }
    }

    if (allowNoneState) {
      setDisablePercent(true);
    }

    return () => {
      isMounted = false;
    };
  }, [withholdingState, allowNoneState]);

  return (
    <>
      {!withholdingValues && (
        <Grid item xs={12} sm={7}>
          <SiraPercentField
            disabled={isLoading || disablePercent}
            name="stateWithholdingPercent"
            label="State Tax"
          />
        </Grid>
      )}
      {showAdditionalState && (
        <>
          {!withholdingValues && (
            <Grid item xs={12}>
              <Typography variant="body2">
                Enter any additional amount you will withhold for state taxes.
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={7}>
            <SiraCurrencyField
              disabled={isLoading}
              name="additionalStateWithholding"
              label={
                withholdingValues ? 'State Tax Amount' : 'Additional Amount'
              }
              allowNegative={false}
            />
          </Grid>
        </>
      )}
    </>
  );
}

export default StateWithholdingFields;
