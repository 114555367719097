import React, { useEffect, useState } from 'react';
import { FieldArray, Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import {
  useTheme,
  Grid,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  LinearProgress,
  Paper,
  Card,
  CardContent,
} from '@mui/material';
import Alert from '@mui/material/Alert';

import { useUser } from '../../../auth/useUser';
import {
  Distribution,
  DistributionAllocation,
} from '../../../api/DistributionApi.d';
import { getInvestmentRates } from '../../../api/InvestmentRateApi';
import { InvestmentRate } from '../../../api/InvestmentRateApi.d';
import StepButtonBar from '../../steps/StepButtonBar';
import SiraCurrencyField from '../SiraCurrencyField';
import { useGlobalContext, usePaginationContext } from '../../../auth/useGlobalContext';
import AllocationPieDisplay from '../newAccount/AllocationPieDisplay';
import SiraTextField from '../SiraTextField';
import { roundNumber } from '../../../utils/App.utils';
import { globalPaginationOptions } from '../../../app.constants';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { set } from 'cypress/types/lodash';
import { use } from 'chai';

export interface DistributionAllocationFormData {
  allocations: Array<DistributionAllocation>;
}

export interface DistributionAllocationFormProps {
  existingAllocations: Array<DistributionAllocation>;
  distributionInfo: Distribution;
  onSubmit?: Function;
  onReset?: Function;
  submitName?: string;
  resetName?: string;
}

export const DISTRIBUTION_ALLOCATION_INIT: Array<DistributionAllocation> = [];

function DistributionAllocationForm({
  existingAllocations = [],
  distributionInfo,
  onSubmit = () => { },
  onReset = () => { },
  submitName,
  resetName,
}: DistributionAllocationFormProps) {
  let isMounted = true;
  const theme = useTheme();
  const [allocations, setAllocations] = useState(existingAllocations);
  const [investmentRates, setInvestmentRates] = useState(
    [] as Array<InvestmentRate>
  );
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const { totalAmount = 0, penaltyAmount } = distributionInfo;
  const { globalPageSize } = usePaginationContext();
  const [showFeeAmount, setShowFeeAmount] = useState(false);
  const [rows, setRows] = useState([]);
  const classes = {
    tableLabel: {
      marginBottom: '1.5rem',
    },
    tableCell: {
      borderBottom: 'none',
      borderRight: '1px solid grey',
    },
    headCell: {
      paddingTop: 0,
      borderBottom: 'none',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
    lastTableCell: {
      borderBottom: 'none',
    },
    lastHeadCell: {
      paddingTop: 0,
      borderBottom: 'none',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
  };

  const DISTRIBUTION_ALLOCATION_SCHEMA = yup.object().shape({
    allocations: yup
      .array()
      .of(
        yup.object().shape({
          amount: yup.string(),
          investmentNumber: yup.string(),
        })
      )
      .required()
      .test(
        'total',
        `The sum of all allocation amounts must equal $${totalAmount}`,
        (allocs = []) => {
          const totalAlloc = allocs.reduce((total, allocation) => {
            return roundNumber(total) + (Number(allocation.amount) || 0);
          }, 0);

          return Number(totalAlloc) === Number(totalAmount);
        }
      ).test(
        'penalty',
        `The sum of all fee amounts must equal $${penaltyAmount}`,
        (allocs = []) => {
          const totalAlloc = allocs.reduce((penalty, allocation) => {
            return roundNumber(penalty) + roundNumber(allocation.penaltyAmount);
          }, 0);

          return Number(totalAlloc) === Number(penaltyAmount);
        })

  });

  // Trims 0 distribution allocations and adds fields that aren't in the form data
  function distributionAllocationsFactory(
    formAllocations: Array<DistributionAllocation>
  ): Array<DistributionAllocation> {
    return investmentRates.reduce(
      (acc: Array<DistributionAllocation>, rate: InvestmentRate, index) => {
        const { amount = '', investmentNumber = '', penaltyAmount = '' } =
          formAllocations[index] || {};
        const { investmentRateId = '' } = rate || {};

        if (amount) {
          acc.push({
            amount,
            investmentRateId,
            investmentNumber,
            penaltyAmount
          } as DistributionAllocation);
        }

        return acc;
      },
      []
    );
  }

  // Maps incoming allocations to their respective investment rate
  function mapInitialAllocations(
    rates: Array<InvestmentRate>
  ): Array<DistributionAllocation> {
    return rates.map((rate) => {
      const { amount = 0, investmentNumber = '', penaltyAmount = 0 } =
        allocations.find(
          ({ investmentRateId }) => rate.investmentRateId === investmentRateId
        ) || {};

      return {
        amount,
        investmentRateId: rate.investmentRateId,
        investmentNumber,
        penaltyAmount,
      } as DistributionAllocation;
    });
  }

  async function getInitialInvestmentRates(): Promise<void> {
    if (user.token && user.organizationId) {
      setIsLoading(true);

      await getInvestmentRates(
        user.organizationId,
        user.token,
        {
          active: true,
        },
        user
      )
        .then((resp) => {
          const initialAllocations = mapInitialAllocations(resp.data);
          if (isMounted) {
            setInvestmentRates(resp.data);
            setAllocations(initialAllocations);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          const { response: { data = {} } = {} } = err;

          setIsLoading(false);
          addGlobalMessage(data.message);
        });
    }
  }

  useEffect(() => {
    getInitialInvestmentRates();
    // check if penalty fee is not 0
    setShowFeeAmount(penaltyAmount > 0);

    return () => {
      isMounted = false;
    };
  }, []);


  // grab the investmentRates before we build the rows
  useEffect(() => {
    // if we have investmentRates and allocations, build the rows
    if (investmentRates.length > 0 && allocations.length > 0) {
      const rowsData = allocations.map((investment: DistributionAllocation, index: number) => {
        return {
          id: investmentRates[index].investmentRateId,
          description: investmentRates[index].description,
          index
        }
      }
      )
      setRows(rowsData);
    } else {
      getInitialInvestmentRates();

    }

    return () => {
      isMounted = false;
    };
  }, [investmentRates, allocations]);

  if (isLoading) {
    return (
      <Box width="1" mt={5} mb={3}>
        <LinearProgress color="secondary" />
      </Box>
    );
  }

  const columns: GridColDef[] = [
    {
      field: 'description',
      headerName: 'Account Type',
      flex: 2
    },
    {
      field: 'investmentNumber',
      headerName: 'Investment Number',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ py: 1, maxHeight: '2rem' }}>

          <SiraTextField
            name={`allocations[${params.row.index}].investmentNumber`}
            height='40px'
          />
        </Box>
      ),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ pt: 1, pb: 1 }}>
          <SiraCurrencyField name={`allocations[${params.row.index}].amount`} height='40px'
          />
        </Box>
      ),
    },

    {
      field: 'penaltyAmount',
      headerName: 'Fee Amount',
      flex: 1,
      hideable: showFeeAmount,
      renderCell: (params) => (
        <Box sx={{ pt: 1, pb: 1 }}>
          <SiraCurrencyField name={`allocations[${params.row.index}].penaltyAmount`} height='40px'
          />
        </Box>
      ),
    },
  ]

  return (
    <Formik
      initialValues={{ allocations }}
      onSubmit={async (values) => {
        await onSubmit(distributionAllocationsFactory(values.allocations));
      }}
      enableReinitialize
      onReset={() => onReset()}
      validationSchema={DISTRIBUTION_ALLOCATION_SCHEMA}
    >
      {({ isSubmitting }) => (
        <Form>
          {investmentRates.length > 0 && (
            <AllocationPieDisplay
              investmentRates={investmentRates}
              amount={totalAmount}
              feeAmount={penaltyAmount}
            />
          )}

          <Box maxWidth={580}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={classes.tableLabel}>
                  Enter the amounts being distributed from the investments
                  below. Select Skip if you do not wish to enter any
                  information.
                </Typography>

              </Grid>
              <Grid item xs={12}>
                <Card sx={{ padding: 2, boxShadow: 3 }}>
                  <CardContent>
                    <DataGridPro
                      columns={columns}
                      rows={rows}
                      disableRowSelectionOnClick
                      columnVisibilityModel={{
                        penaltyAmount: showFeeAmount,
                      }}
                      slots={{
                        noRowsOverlay: () => (
                          <Box sx={{ p: 2 }}>
                            <Typography>No investments found.</Typography>
                          </Box>
                        ),
                      }}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            // Hide columns based on user role
                            penaltyAmount: showFeeAmount,
                          },
                        },
                        pagination: { paginationModel: { pageSize: globalPageSize } },
                      }}
                      pagination
                      pageSizeOptions={globalPaginationOptions}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <ErrorMessage name="allocations">
                  {(msg) => (
                    <Alert severity="error" variant="outlined">
                      {msg}
                    </Alert>
                  )}
                </ErrorMessage>
              </Grid>
            </Grid>
          </Box>
          <StepButtonBar
            isSubmitting={isSubmitting}
            submitName={submitName}
            resetName={resetName}
          />
        </Form>
      )}
    </Formik>
  );
}

export default DistributionAllocationForm;
