import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Paper, Typography, Alert, AlertTitle, Link } from '@mui/material';

import { format, parseISO } from 'date-fns';
import { useUser } from '../auth/useUser';
import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';
import {
  addTransactionData,
  setSearchResponse,
  skipStep,
  completeTransaction,
  rejectTransaction,
  useTransactionReducer,
  setSelectedAccount,
} from './TransactionReducer';
import DeceasedOwnerForm, {
  AccountLocationEnum,
} from '../components/form/inheritedIra/DeceasedOwnerForm';
import { AccountOwner } from '../api/AccountOwnerApi.d';
import { beneficiaryRelationshipOptions } from '../app.constants';
import {
  createOrSaveAccountOwner,
  getAccountOwner,
} from '../api/AccountOwnerApi';
import InheritedAccountOwner from '../components/form/inheritedIra/InheritedAccountOwner';
import DesignatePrimaryBeneficiaries from '../components/beneficiaries/DesignatePrimaryBeneficiaries';
import {
  Beneficiaries,
  Beneficiary,
  BeneficiaryTypes,
} from '../api/BeneficiariesApi.d';
import DesignateSecondaryBeneficiaries from '../components/beneficiaries/DesignateSecondaryBeneficiaries';
import { useGlobalContext } from '../auth/useGlobalContext';
import {
  changeAccountStatus,
  createAccountDocument,
  createOrSaveAccount,
  getAccount,
  getAccountDocument,
} from '../api/AccountApi';
import ContributionInfoForm, {
  CONTRIBUTION_INFO_INIT,
} from '../components/form/newAccount/ContributionInfoForm';
import {
  createOrSaveContributionInformation,
  getAccountContributions,
} from '../api/ContributionApi';
import {
  AccountContribution,
  ContributionStatus,
} from '../api/ContributionApi.d';
import ContributionAllocationForm from '../components/form/newAccount/ContributionAllocationForm';
import { createOrSaveAllocations } from '../api/InvestmentRateApi';
import { Allocation } from '../api/InvestmentRateApi.d';
import SkipChallenge from '../components/steps/SkipChallenge';
import ReviewDocumentForm from '../components/form/newAccount/ReviewDocumentForm';
import {
  Account,
  AccountStatus,
  AccountStatusResponse,
  AccountStatusState,
  AccountType,
  TransactionType,
  WorkflowDates,
} from '../api/AccountApi.d';
import TransactionStepper from './TransactionStepper';
import { ConstantsMappingKey } from '../api/SharedTextApi.d';
import {
  useUnsavedChangesWarning,
  RowDefinition,
} from '../components/useUnsavedChangesWarning';
import SiraAccountOwnerInfo from '../components/form/SiraAccountOwnerInfo';
import { newAccountDepositHelpTxt } from '../components/form/newAccount/resource.txt';
import { useBeneficiariesReducer } from '../components/beneficiaries/BeneficiariesReducer';
import { IMMDocumentType } from '../api/ESignApi.d';
import { errorMessages } from '../utils/errorhandling.utils';
import { is } from 'cypress/types/bluebird';

const getInheritedAccountType = (
  deceasedAccountType: AccountType
): AccountType => {
  return {
    [AccountType.employerRoth]: AccountType.inheritedRoth,
    [AccountType.employerTraditional]: AccountType.inheritedTraditional,
    [AccountType.rothIra]: AccountType.inheritedRoth,
    [AccountType.traditionalIra]: AccountType.inheritedTraditional,
    [AccountType.traditionalSep]: AccountType.inheritedTraditional,
    [AccountType.inheritedTraditional]: deceasedAccountType, // 1:1 with the deceased acct
    [AccountType.inheritedRoth]: deceasedAccountType, // 1:1 with the deceased acct
    [AccountType.simpleIra]: AccountType.inheritedTraditional,
  }[deceasedAccountType];
};

function InheritedIra() {
  let isMounted = true;
  const { user } = useUser();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [pageState] = useTransactionReducer();
  const [beneficiariesState] = useBeneficiariesReducer();
  const [allocations, setAllocations] = useState([] as Array<Allocation>);
  const { getAppConstant, addGlobalMessage, organization } = useGlobalContext();
  const { accountStatus } = pageState.accountInformation;
  const { deceasedOwnerOption = AccountLocationEnum.unknown } =
    pageState.deceasedAccountInformation;
  const isAwaiting = [
    AccountStatus.signature,
    AccountStatus.review,
    AccountStatus.submitOwner,
  ].includes(accountStatus);
  const isFinalizeStatus = [AccountStatus.open, AccountStatus.review].includes(
    accountStatus
  );
  const queryParams = new URLSearchParams(useLocation().search);
  const loadingExistingAccount = Boolean(queryParams.get('accountId'));
  const { UnsavedChangesPrompt, setUnsavedChanges } =
    useUnsavedChangesWarning();
  const [accountIdValue, setAccount] = useState('');
  const [accountOwner, setAccountOwner] = useState('');

  const setDeceasedAccount = (
    deceasedAccountInformation: Account & AccountOwner
  ) => {
    setSelectedAccount(deceasedAccountInformation);
    addTransactionData({ deceasedAccountInformation }, false);
  };

  const setDeceasedAccountOwner = (
    deceasedAccountOwnerInformation: AccountOwner
  ) => {
    addTransactionData({ deceasedAccountOwnerInformation }, false);
  };

  const setDeceasedAccountInfo = (deceasedAccountInformation: Account) => {
    addTransactionData({ deceasedAccountInformation }, false);
  };

  const goToRecurringTransaction = (): void => {
    navigate(
      `/transactions/recurringDistributions?accountId=${pageState.accountInformation.accountId}&accountOwnerId=${pageState.accountInformation.accountOwnerId}`
    );
  };

  // Revert transaction to pending before saving to allow updates
  const revertToPending = async (): Promise<AccountStatus> => {
    const response: AccountStatusResponse = await changeAccountStatus(
      user.organizationId,
      pageState.accountInformation.accountId,
      pageState.accountInformation.accountOwnerId,
      AccountStatusState.previous,
      {} as WorkflowDates,
      user.token,
      user
    );
    const { accountStatus: newAccountStatus } = response.data;

    if (response.errorMessage) {
      addGlobalMessage('Error reverting account status');
      return accountStatus;
    }
    if (isMounted) {
      addTransactionData({ accountInformation: response.data }, false);
    }

    return newAccountStatus;
  };

  const isChildUnder21 = (
    childBirthDate: string,
    deceasedOwnerDeathDate: string,
    relationship: string
  ): boolean => {
    const childBirth = new Date(childBirthDate);
    const deceasedDeath = new Date(deceasedOwnerDeathDate);
    if (relationship === BeneficiaryTypes.SPOUSE) return false;

    if (relationship === BeneficiaryTypes.OTHER) {
      // other date oif birth is the child's date of birth - deceased date of birth(deceased owner's date of death)
      let age = childBirth.getFullYear() - deceasedDeath.getFullYear();

      const m = childBirth.getMonth() - deceasedDeath.getMonth();

      if (
        m < 0 ||
        (m === 0 && deceasedDeath.getDate() < childBirth.getDate())
      ) {
        age--;
      }
      return age < 11;
    } else {
      let age = deceasedDeath.getFullYear() - childBirth.getFullYear();

      const m = deceasedDeath.getMonth() - childBirth.getMonth();
      if (
        m < 0 ||
        (m === 0 && deceasedDeath.getDate() < childBirth.getDate())
      ) {
        age--;
      }
      return age < 21;
    }
  };

  // Create or update the owner and the account the inheritance is going into
  const createOrUpdateAccount = async (
    beneficiary: Beneficiary
  ): Promise<void> => {
    setIsLoading(true);
    const mergedBeneficiary = beneficiary;

    if (pageState.accountInformation.accountId && isAwaiting) {
      await revertToPending();
    }

    const removedRelationship = beneficiary.relationship;

    delete mergedBeneficiary.relationship;

    await createOrSaveAccountOwner(
      mergedBeneficiary as AccountOwner,
      user.organizationId,
      user.token,
      user
    )
      .then(({ data }) => {
        // THIS CREATES NEW ACCOUNT OWNER
        const combinedBeneficiary = { ...beneficiary, ...data };
        const {
          accountId: deceasedAccountId = '',
          accountType: deceasedAccountType,
        } = pageState.deceasedAccountInformation;
        const { externalAccountOwnerAccountId = '' } =
          pageState.deceasedAccountOwnerInformation;

        if (isMounted) {
          addTransactionData(
            { inheritingAccountOwnerInformation: combinedBeneficiary },
            false
          );
        }

        let account = {
          ...pageState.accountInformation,
          accountOwnerId: data.accountOwnerId,
          accountType: getInheritedAccountType(deceasedAccountType),
          accountNumber: beneficiary.accountNumber,
          deceasedAccountId,
          externalAccountOwnerAccountId,
          relationship: removedRelationship,
          openDate: mergedBeneficiary.openDate,
        };

        const transactionData: RowDefinition = {
          accountId: pageState.accountInformation.accountId,
          accountOwnerId: pageState.selectedAccount.accountOwnerId,
          transactionType: TransactionType.account,
        };

        const deceasedOwnerDeathDate =
          pageState.deceasedAccountInformation.dateOfDeath;
        const childBirthDate = beneficiary.dateOfBirth;
        const deceasedOWnerBirthDate =
          pageState.deceasedAccountOwnerInformation.dateOfBirth;
        const isChild =
          childBirthDate &&
          deceasedOwnerDeathDate &&
          isChildUnder21(
            childBirthDate,
            deceasedOwnerDeathDate,
            removedRelationship
          );
        const isEliligible =
          childBirthDate &&
          deceasedOWnerBirthDate &&
          isChildUnder21(
            childBirthDate,
            deceasedOWnerBirthDate,
            removedRelationship
          );

        if (
          ![
            AccountType.inheritedRoth,
            AccountType.inheritedTraditional,
          ].includes(pageState.deceasedAccountInformation.accountType)
        ) {
          switch (removedRelationship) {
            case BeneficiaryTypes.CHILD:
              // account owber  is the child now but are they 21 by the deceased owner's death date
              // if not, they are a minor
              // isChild = false meaning child is not a minor
              // isChild = true meaning child is a minor

              if (!isChild) {
                account = {
                  ...account,
                  eligibleDesignatedBeneficiary: isChild,
                };
              }
              break;
            case BeneficiaryTypes.SPOUSE:
              account = {
                ...account,
                eligibleDesignatedBeneficiary: true,
              };

              break;
            case BeneficiaryTypes.OTHER:
              account = {
                ...account,
                eligibleDesignatedBeneficiary: isEliligible,
              };
              break;

            default:
              account = {
                ...account,
                eligibleDesignatedBeneficiary: false,
              };

              break;
          }
        }

        setUnsavedChanges(transactionData);

        return createOrSaveAccount(
          account,
          user.organizationId,
          data.accountOwnerId,
          user.token,
          user
        );
      })
      .then(({ data }) => {
        if (isMounted) {
          addTransactionData({ accountInformation: data });
        }

        const transactionData: RowDefinition = {
          accountId: data.accountId,
          accountOwnerId: data.accountOwnerId,
          transactionType: TransactionType.account,
        };
        setAccountOwner(data.accountOwnerId);
        setAccount(data.accountId);

        setUnsavedChanges(transactionData);
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Could not create new account or account owner'
        );
      });

    if (isMounted) {
      setIsLoading(false);
    }
  };

  // Save the contribution made for this inherited account that was created
  const saveContribution = async (data: AccountContribution): Promise<void> => {
    if (isAwaiting) {
      revertToPending();
    }

    const { data: contributionInformation, errorMessage } =
      await createOrSaveContributionInformation(
        data,
        user.organizationId,
        pageState.accountInformation.accountOwnerId,
        pageState.accountInformation.accountId,
        pageState.contributionInformation.contributionId,
        user.token,
        user
      );

    if (errorMessage) {
      addGlobalMessage(errorMessage || 'Error saving contribution information');
    }

    if (isMounted) {
      addTransactionData({ contributionInformation });
    }
  };

  // Also save the allocations for the contribution made previously
  const saveAllocations = async (data: any): Promise<void> => {
    if (isAwaiting) {
      revertToPending();
    }

    const { data: newAllocations, errorMessage } =
      await createOrSaveAllocations(
        data,
        user.organizationId,
        pageState.accountInformation.accountId,
        pageState.inheritingAccountOwnerInformation.accountOwnerId,
        pageState.contributionInformation.contributionId,
        user.token,
        user
      );

    if (errorMessage) {
      addGlobalMessage(errorMessage || 'Error saving allocation information');
    }

    if (isMounted) {
      setAllocations(newAllocations);
      skipStep(4);
    }
  };

  // Save beneficiaries for the new inherited account
  const saveBeneficiaries = async (data: Beneficiaries): Promise<void> => {
    if (isAwaiting) {
      revertToPending();
    }

    addTransactionData({ beneficiaryInformation: data });
  };

  // Generate the PDF in S3 and/or stream it
  const viewDocument = (): Promise<any> => {
    return isAwaiting || isFinalizeStatus
      ? getAccountDocument(
        user.organizationId,
        pageState.accountInformation.accountOwnerId,
        pageState.accountInformation.accountId,
        user.token,
        user
      )
      : createAccountDocument(
        user.organizationId,
        pageState.accountInformation.accountOwnerId,
        pageState.accountInformation.accountId,
        user.token,
        user
      );
  };

  // Update the status imperatively after document creation
  // The api does this but doesn't send a payload back so we'll hardcode it here for now
  const handleCreateDocument = useCallback(() => {
    if (accountStatus === AccountStatus.pending) {
      addTransactionData(
        {
          accountInformation: {
            ...pageState.accountInformation,
            accountStatus: AccountStatus.signature,
          },
        },
        false
      );
    }
  }, [pageState.accountInformation.accountId]);

  // Advance the account status to the next
  async function advanceAccountStatus(finalizeWorkflow: boolean) {
    await changeAccountStatus(
      user.organizationId,
      pageState.accountInformation.accountId,
      pageState.accountInformation.accountOwnerId,
      AccountStatusState.next,
      {} as WorkflowDates,
      user.token,
      user
    )
      .then((res) => {
        if (isMounted) {
          addTransactionData(
            {
              accountInformation: res.data,
            },
            false
          );

          if (finalizeWorkflow) {
            completeTransaction();
            setUnsavedChanges(null);
          }
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Error finalizing Inherited IRA account'
        );
        if (isMounted) {
          rejectTransaction();
        }
      });
  }

  // Get the accountOwner for the account loaded
  async function fetchAndSetAccountOwner(
    accountOwnerId: string
  ): Promise<void> {
    setIsLoading(true);

    await getAccountOwner(accountOwnerId, user.organizationId, user.token, user)
      .then((res) => {
        if (isMounted) {
          addTransactionData({ inheritingAccountOwnerInformation: res.data });
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Could not fetch the preselected account owner'
        );
      });

    if (isMounted) {
      setIsLoading(false);
    }
  }

  // Look up the first PENDING contribution for the looked up account
  async function fetchAndSetContributionInfo(
    contributionAccountId: string,
    contributionAccountOwnerId: string
  ): Promise<void> {
    setIsLoading(true);

    await getAccountContributions(
      contributionAccountId,
      contributionAccountOwnerId,
      user.organizationId,
      user.token,
      user,
      [ContributionStatus.pending, ContributionStatus.signature]
    )
      .then((res) => {
        const [firstPendingAccountContribution] = res.data;

        if (isMounted && firstPendingAccountContribution) {
          addTransactionData({
            contributionInformation: firstPendingAccountContribution,
          });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        addGlobalMessage(
          errorMessages(err) ||
          'Could not fetch the preselected contribution info'
        );
      });
  }

  // Look up account and set it selected with query params passed
  async function fetchAndSetAccountData(
    accountId: string,
    accountOwnerId: string
  ): Promise<void> {
    setIsLoading(true);

    await getAccount(
      queryParams.get('accountId') || accountId,
      queryParams.get('accountOwnerId') || accountOwnerId,
      user.organizationId,
      user.token,
      user
    )
      .then(async (res) => {
        const skipToEnd = [
          AccountStatus.signature,
          AccountStatus.review,
          AccountStatus.submitOwner,
        ].includes(res.data.accountStatus);

        if (isMounted) {
          addTransactionData({ accountInformation: res.data });
          await fetchAndSetAccountOwner(res.data.accountOwnerId);
          await fetchAndSetContributionInfo(
            res.data.accountId,
            res.data.accountOwnerId
          );
          skipStep(2);
          if (skipToEnd) {
            skipStep(6);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        addGlobalMessage(
          errorMessages(err) || 'Could not fetch the preselected account'
        );
      });
  }

  useEffect(() => {
    if (user.token && user.organizationId) {
      if (isMounted) {
        if (loadingExistingAccount) {
          const accountId =
            queryParams.get('accountId') || pageState.selectedAccount.accountId;
          const accountOwnerId =
            queryParams.get('accountOwnerId') ||
            pageState.selectedAccount.accountOwnerId;
          setAccount(accountId);
          setAccountOwner(accountOwnerId);

          fetchAndSetAccountData(accountId, accountOwnerId);
        }
      }
    }

    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId]);

  // set account and accountOwnerId with pagestate.selectedAccount
  useEffect(() => {
    if (pageState.selectedAccount.accountId) {
      setAccount(pageState.selectedAccount.accountId);
      setAccountOwner(pageState.selectedAccount.accountOwnerId);
    }
  }, [pageState.selectedAccount]);

  const inheritedIraSteps = [
    {
      label: 'Enter Deceased Owner and Account Information',
      stepContent: (
        <Box mt={5} mb={3}>
          <DeceasedOwnerForm
            initialValues={{
              searchResponse: pageState.searchResponse,
              externalAccount: {
                ...pageState.deceasedAccountInformation,
                ...pageState.deceasedAccountOwnerInformation,
              },
              deceasedOwnerOption:
                pageState.deceasedAccountInformation.deceasedOwnerOption,
            }}
            onSetDeceasedAccount={setDeceasedAccount}
            onSetDeceasedOwner={setDeceasedAccountOwner}
            setSearchResponse={setSearchResponse}
          />
        </Box>
      ),
    },
    {
      label: 'Enter Inherited IRA Owner and Account Information',
      stepContent: (
        <Box mt={5} mb={3}>
          <InheritedAccountOwner
            deceasedAccountOwner={pageState.deceasedAccountOwnerInformation}
            deceasedAccount={pageState.deceasedAccountInformation}
            initialValues={pageState.inheritingAccountOwnerInformation}
            onUpdateOwner={setDeceasedAccountOwner}
            onUpdateAccount={setDeceasedAccountInfo}
            onSubmit={createOrUpdateAccount}
          />
        </Box>
      ),
    },
    {
      label: 'Provide Deposit Information',
      stepContent: (
        <SkipChallenge
          onSkip={() => skipStep(pageState.activeStep + 2)} // Also skip investments step
          skipText="Skip"
          acceptText="Make Initial Deposit"
        >
          <ContributionInfoForm
            account={pageState.accountInformation}
            deceasedAccount={pageState.deceasedAccountInformation}
            initialValues={{
              ...CONTRIBUTION_INFO_INIT,
              ...pageState.contributionInformation,
            }}
            onSubmit={saveContribution}
            accountOwner={pageState.inheritingAccountOwnerInformation}
          />
        </SkipChallenge>
      ),
    },
    {
      label: 'Select Investment Options',
      stepContent: organization.coreIntegration === 'SYMITAR' ? (
        <ContributionAllocationForm
          existingAllocations={[...allocations]}
          contributionInfo={pageState.contributionInformation}
          onSubmit={saveAllocations}
          accountOwnerId={pageState.contributionInformation.accountOwnerId}
        />
      ) : (
        <SkipChallenge
          onSkip={() => skipStep(pageState.activeStep + 1)}
          skipText="Skip"
          acceptText="Allocate Assets"
        >
          <ContributionAllocationForm
            existingAllocations={[...allocations]}
            contributionInfo={pageState.contributionInformation}
            onSubmit={saveAllocations}
            accountOwnerId={pageState.contributionInformation.accountOwnerId}
          />
        </SkipChallenge>
      ),
    },
    {
      label: 'Primary Beneficiaries',
      stepContent: (
        <SkipChallenge
          onSkip={() => skipStep(pageState.activeStep + 2)}
          skipText="Skip"
          acceptText="Add Beneficiaries"
          skipModal={
            newAccountDepositHelpTxt.primaryBeneficiary.skipBenefeciaries
          }
          skipModalTitle="Primary Beneficiary"
        >
          <DesignatePrimaryBeneficiaries
            state={beneficiariesState}
            onSave={saveBeneficiaries}
            account={pageState.accountInformation}
          />
        </SkipChallenge>
      ),
    },
    {
      label: 'Contingent Beneficiaries',
      stepContent: (
        <SkipChallenge
          onSkip={() => skipStep(pageState.activeStep + 1)}
          skipText="Skip"
          acceptText="Add Beneficiary"
        >
          <DesignateSecondaryBeneficiaries
            state={beneficiariesState}
            onSave={saveBeneficiaries}
            account={pageState.accountInformation}
          />
        </SkipChallenge>
      ),
    },
    {
      label: 'Review and Sign Document',
      stepContent: (
        <>
          <ReviewDocumentForm
            searchResult={pageState.selectedAccount}
            allowSignature={isAwaiting}
            submitName="Done"
            getDocument={viewDocument}
            onGetDocument={handleCreateDocument}
            hideStepButtonBar={pageState.completed}
            onSubmit={async () => {
              if (isAwaiting) {
                await advanceAccountStatus(true);
              }
            }}
            documentType={IMMDocumentType.account}
            transactionName="Inherited account"
            account={accountIdValue}
            accountOwner={accountOwner}
            inheritedOwner={pageState.inheritingAccountOwnerInformation}
            transactionStatus={
              pageState.inheritingAccountOwnerInformation.accountStatus
            }
          />
          {isFinalizeStatus && (pageState.completed || pageState.rejected) && (
            <Alert
              severity={pageState.rejected ? 'error' : 'success'}
              variant="outlined"
            >
              <AlertTitle>
                {pageState.rejected ? (
                  'Inherited account could not be added.'
                ) : (
                  <Typography gutterBottom>
                    Inherited account successfully added.
                    <Link
                      sx={{ fontStyle: 'italic' }}
                      href={`/accountOwner/${pageState.inheritingAccountOwnerInformation.accountOwnerId}`}
                    >
                      View Account
                    </Link>
                  </Typography>
                )}
              </AlertTitle>
              {pageState.completed && (
                <>
                  Complete the{' '}
                  <Link
                    onClick={goToRecurringTransaction}
                    sx={{ cursor: 'pointer' }}
                  >
                    Add Recurring Distribution
                  </Link>{' '}
                  workflow to set up the inherited IRA owner&apos;s required distribution schedule.
                </>
              )}
            </Alert>
          )}
        </>
      ),
    },
  ];

  const {
    deceasedAccountOwnerInformation,
    deceasedAccountInformation,
    inheritingAccountOwnerInformation,
  } = pageState;

  const displayInfo = {
    name:
      `${deceasedAccountOwnerInformation.firstName} ${deceasedAccountOwnerInformation.lastName}` ||
      '',
    accountTypeName:
      getAppConstant(
        ConstantsMappingKey.accountType,
        deceasedAccountInformation.accountType
      ) || '',
    dateOfBirth:
      inheritingAccountOwnerInformation.dateOfBirth &&
      format(
        parseISO(inheritingAccountOwnerInformation.dateOfBirth),
        'MM/dd/yyyy'
      ),
    beneficiaryName:
      inheritingAccountOwnerInformation.firstName &&
      `${inheritingAccountOwnerInformation.firstName} ${inheritingAccountOwnerInformation.lastName}`,
    relationship:
      beneficiaryRelationshipOptions.find(
        ({ value = '' }) =>
          value === inheritingAccountOwnerInformation.relationship
      )?.label || '',
  };

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Transactions
          </Typography>

          <Typography color="secondary" variant="h1" gutterBottom>
            Open New Inherited IRA
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            Follow the steps below to open an inherited IRA.
          </Typography>

          {deceasedOwnerOption !== AccountLocationEnum.unknown && (
            <Box mt={2}>
              <Paper elevation={3}>
                <SiraAccountOwnerInfo
                  selectedAccount={pageState.deceasedAccountInformation}
                  accountOwner={pageState.deceasedAccountOwnerInformation}
                  inheritingAccountOwner={displayInfo}
                  deceasedInfo
                />
              </Paper>
            </Box>
          )}

          <Box mt={5}>
            <TransactionStepper
              steps={
                organization.displayInvestments
                  ? inheritedIraSteps
                  : inheritedIraSteps.filter(
                    (step) => step.label !== 'Select Investment Options'
                  )
              }
              activeStep={pageState.activeStep}
              isLoading={isLoading}
              onStepClick={(index) => {
                skipStep(index);
              }}
            />
          </Box>
          {UnsavedChangesPrompt}
        </>
      </FormPaper>
    </Layout>
  );
}

export default InheritedIra;
