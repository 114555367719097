import React, { useEffect, useState } from 'react';
import { convertToRaw } from 'draft-js';
import MUIRichTextEditor from 'mui-rte';
import draftToHtml from 'draftjs-to-html';

import { Box, InputLabel, useTheme } from '@mui/material';
import { useDebounce } from 'use-debounce';
import { ThemeProvider } from '@mui/styles';

export interface SiraTextEditorProps {
  showToolbar?: boolean;
  fieldLabel?: string;
  setValue?: Function;
  placeholder?: string;
  defaultValue?: string;
  setReadOnly?: boolean;
  formatToHtml?: Function;
  notTask?: boolean;
  accountOwnerPortalCustomError?: boolean;
}

function SiraTextEditor(props: SiraTextEditorProps) {
  const {
    showToolbar = false,
    fieldLabel = 'Description',
    placeholder = '',
    setValue = () => { },
    formatToHtml = () => { },
    defaultValue = '',
    setReadOnly = false,
    notTask = false,
    accountOwnerPortalCustomError = false
  } = props;
  const [description, setDescription] = useState<string>('');
  const [active, setActive] = useState<boolean>(false);
  const [debouncedDescrip] = useDebounce(description, 600);
  const [plainMarkup, setPlainMarkup] = useState<any>();
  const theme = useTheme();

  Object.assign(theme, {
    overrides: {
      MUIRichTextEditor: {
        toolbar: {
          width: '100%',
          height: '100%',
          borderBottom: `1px solid ${active ? 'black' : 'gray'}`,
        },
        editor: {
          width: '100%',
          minHeight: '200px',
          cursor: 'text',
        },
        container: {
          padding: '10px', // Your custom padding
          overflow: 'auto',
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
        },
        editorContainer: {
          width: '100%',
          minHeight: '200px',
          paddingLeft: '5px',
          overflow: "auto",
        },

        //*[@id="mui-rte-editor-container"]/div/div
      },

    },
  });

  useEffect(() => {
    if (debouncedDescrip !== '') {
      setValue(debouncedDescrip, plainMarkup);
    }
  }, [debouncedDescrip]);

  const onChange = (event) => {
    const content = convertToRaw(event.getCurrentContent()); // for rte content with text formating
    const markup = draftToHtml(content); // convert to html
    formatToHtml(markup);
    setPlainMarkup(markup);
    setDescription(JSON.stringify(content));
  };

  return (
    <>
      {!notTask && <InputLabel sx={{ fontSize: theme.typography.body1.fontSize }}>
        {fieldLabel}
      </InputLabel>}
      <Box
        aria-label="Task Description"
        sx={{
          border: `1px solid ${active ? 'black' : 'gray'}`,
          borderRadius: '5px',
          minHeight: '200px',
          overflow: 'auto', // Ensure the editor content is scrollable
        }}
      >
        <ThemeProvider theme={theme}>
          <MUIRichTextEditor
            readOnly={setReadOnly}
            defaultValue={defaultValue}
            label={placeholder}
            toolbar={showToolbar}
            toolbarButtonSize="small"
            controls={[
              'title',
              'bold',
              'italic',
              'underline',
              'strikethrough',
              'highlight',
              'undo',
              'redo',
              'link',
              'media',
              'numberList',
              'bulletList',
              'quote',
              'code',
              'clear',
            ]}
            onChange={(event) => {
              onChange(event);
            }}
            onFocus={() => {
              setActive(true);
            }}
            onBlur={() => {
              setActive(false);
            }}

          />
        </ThemeProvider>
      </Box>
    </>
  );
}

export default SiraTextEditor;
