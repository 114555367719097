import React, { useEffect, useState } from 'react';
import {
  useTheme,
  AppBar,
  Container,
  Grid,
  Toolbar,
  Button,
  Box,
  IconButton,
  useMediaQuery,
  Typography,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

import DensitySmallIcon from '@mui/icons-material/DensitySmall';
import { useGlobalContext } from '../../auth/useGlobalContext';
import LogoutButton from './LogoutLink';
import UserAvatar from './UserAvatar';
import SvgSuperiorLogoHorizontal from '../../icons/SvgSuperiorLogoHorizontal';

import { Organization } from '../../api/OrganizationApi.d';
import NotificationBadge from './Notification';
import { UserRole } from '../../api/UserApi.d';
import { useUser } from '../../auth/useUser';


interface StandardAppBarProps {
  openNav: Function;
}

const StandardAppBar = (props: StandardAppBarProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { organization, customerPortalUser } = useGlobalContext();
  const {
    name = '',
    legalName = '',
    financialOrganizationId = '',
    logoUrl,
  } = organization as Organization;
  const { openNav } = props;
  const classes = {
    root: {
      flexGrow: 1,
      zIndex: theme.zIndex.appBar,
      position: 'relative' as any,
    },
    logo: {
      svg: {
        height: 64,
      },
    },
    smallLogo: {
      svg: {
        height: 32,
      },
    },
    header: {
      color: `${theme.palette.text.secondary} !important`,
    },
  };
  const isSmallScreen = useMediaQuery('(max-width: 1000px)');
  const [showLogout, setShowLogout] = useState(false as boolean);
  const [isSymitarIntegrated, setIsSymitarIntegrated] = useState(false);

  const goToOrgProfile = (): void => {
    if (financialOrganizationId) {
      navigate(`/orgSettings`);
    }
  };

  const [screenSize, setScreenSize] = useState(false as boolean);
  const [hasMatchingRoles, setHasMatchingRoles] = useState(true as Boolean);
  const { user } = useUser();

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width < 950) {
        setScreenSize(true);
      } else if (width >= 950 && width < 960) {
        setScreenSize(false);
      } else {
        setScreenSize(false);
      }
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Call the handleResize function initially
    handleResize();
    // Check for Symitar integration
    if (organization.coreIntegration === 'SYMITAR') {
      setIsSymitarIntegrated(true);
    }
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (user.roles) {
      setHasMatchingRoles(user.roles.includes(UserRole.accountOwner));
      if (user.roles.includes(UserRole.accountOwner) && customerPortalUser) {
        setShowLogout(true);
      }

      if (user.roles.includes(UserRole.accountOwner) && !customerPortalUser) {
        setShowLogout(true);
      }

      if (!user.roles.includes(UserRole.accountOwner)) {
        setShowLogout(true);
      }

      // Check for Symitar integration
      setIsSymitarIntegrated(true);
    }
  }, [user.roles, organization]);

  return (
    <Box sx={classes.root}>
      <AppBar position="fixed" >
        <Container maxWidth="xl">
          <Box sx={classes.root}>
            <Toolbar>
              <Grid
                justifyContent="flex-end"
                container
                direction="row"
                wrap="nowrap"
                alignItems="center"
                spacing={1}
              >
                {isSmallScreen &&
                  (!hasMatchingRoles ? (
                    <Grid item>
                      <IconButton
                        color="secondary"
                        onClick={() => {
                          openNav();
                        }}
                        size="large"
                      >
                        <DensitySmallIcon />
                      </IconButton>
                    </Grid>
                  ) : (
                    <Box textAlign="left" pt={1}>
                      {organization.logoUrl && (
                        <Button
                          style={{
                            height: '44px',
                          }}
                          variant="outlined"
                          color="secondary"
                          onClick={goToOrgProfile}
                        >
                          <img
                            style={{
                              maxHeight: '38px',
                              maxWidth: '156px',
                            }}
                            src={logoUrl}
                            alt={legalName}
                          />
                        </Button>
                      )}
                    </Box>
                  ))}
                {!hasMatchingRoles ? (
                  <Grid item xs={12} md={6} sm={4}>
                    <Box sx={isSmallScreen ? classes.smallLogo : classes.logo}>
                      <SvgSuperiorLogoHorizontal />
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        ...(isSmallScreen ? classes.smallLogo : classes.logo),
                        display: 'flex',
                      }}
                    >
                      {organization.legalName && !screenSize && (
                        <>
                          {' '}
                          <Box textAlign="left">
                            {organization.logoUrl && (
                              <Button
                                style={{
                                  height: '44px',
                                }}
                                color="secondary"
                                onClick={goToOrgProfile}
                              >
                                <img
                                  style={{
                                    maxHeight: '38px',
                                    maxWidth: '156px',
                                  }}
                                  src={logoUrl}
                                  alt={legalName}
                                />
                              </Button>
                            )}
                          </Box>
                          <Typography
                            variant="h4"
                            sx={{ paddingLeft: 1, ...classes.header }}
                            noWrap
                          >
                            {name}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12}>
                  {!hasMatchingRoles && legalName && !screenSize && (
                    <Box textAlign="center">
                      {logoUrl && (
                        <Button
                          style={{ height: '44px' }}
                          color="secondary"
                          onClick={goToOrgProfile}
                        >
                          <img
                            style={{ maxHeight: '38px', maxWidth: '156px' }}
                            src={logoUrl}
                            alt={name}
                          />
                        </Button>
                      )}
                      <Box component="span" sx={{ ml: 2 }}>
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={goToOrgProfile}
                        >
                          {name}
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Grid>
                <Grid item>
                  <Grid container wrap="nowrap" direction="row">
                    {!hasMatchingRoles && (
                      <>
                        <NotificationBadge />
                        <UserAvatar />
                      </>
                    )}
                    {showLogout && <LogoutButton />}
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </Box>
        </Container>
      </AppBar>
    </Box>
  );
};

export default StandardAppBar;
