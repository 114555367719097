import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Box,
  LinearProgress,
  Button,
  TextField,
  Alert,
} from '@mui/material';

import { RgbColorPicker } from 'react-colorful';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { useUser } from '../../auth/useUser';
import { updateOrgBranding, uploadLogo } from '../../api/OrganizationApi';
import FileUploadForm, {
  FileUpload,
  FILE_UPLOAD_INIT,
} from '../form/FileUploadForm';
import { errorMessages } from '../../utils/errorhandling.utils';

// Translate the string from the DB in to an RGB object the picker can use
const dbColorStringToColorObject = (colorString) => {
  let rgbColor = { r: 0, g: 0, b: 0 };
  if (colorString) {
    const rgbArray = colorString.split(',');
    if (rgbArray.length === 3) {
      rgbColor = {
        r: Number(rgbArray[0]),
        g: Number(rgbArray[1]),
        b: Number(rgbArray[2]),
      };
    }
  }
  return rgbColor;
};

function OrganizationBrandingDetails() {
  let isMounted = true;
  const { user } = useUser();
  const { organization, addGlobalMessage, setCurrentOrg } = useGlobalContext();
  const [color, setColor] = useState({ r: 0, g: 0, b: 0 });
  const [secondaryColor, setsecondaryColor] = useState({ r: 0, g: 0, b: 0 });
  const [textColor, setTextColor] = useState({ r: 0, g: 0, b: 0 });
  const [secondaryTextColor, setSecondaryTextColor] = useState({
    r: 0,
    g: 0,
    b: 0,
  });

  useEffect(() => {
    if (isMounted) {
      setColor(dbColorStringToColorObject(organization?.primaryColorRGB));
      setsecondaryColor(
        dbColorStringToColorObject(organization?.secondaryColorRGB)
      );
      setTextColor(dbColorStringToColorObject(organization?.primaryTextRGB));
      setSecondaryTextColor(
        dbColorStringToColorObject(organization?.secondaryTextRGB)
      );
    }

    return () => {
      isMounted = false;
    };
  }, [organization?.primaryColorRGB]);

  const [isLoading, setIsLoading] = useState(false);

  const setAnRGorB = (colorAspect, value: string) => {
    let numericValue = Number(value);
    numericValue = Math.min(255, numericValue);
    numericValue = Math.max(0, numericValue);

    const newColor = { ...color };
    newColor[colorAspect] = numericValue;
    setColor(newColor);
  };

  const setSecondaryAnRGorB = (colorAspect, value: string) => {
    let numericValue = Number(value);
    numericValue = Math.min(255, numericValue);
    numericValue = Math.max(0, numericValue);

    const newColor = { ...secondaryColor };
    newColor[colorAspect] = numericValue;
    setsecondaryColor(newColor);
  };

  const setTextAnRGorB = (colorAspect, value: string) => {
    let numericValue = Number(value);
    numericValue = Math.min(255, numericValue);
    numericValue = Math.max(0, numericValue);

    const newColor = { ...textColor };
    newColor[colorAspect] = numericValue;
    setTextColor(newColor);
  };

  const setSecondaryTextAnRGorB = (colorAspect, value: string) => {
    let numericValue = Number(value);
    numericValue = Math.min(255, numericValue);
    numericValue = Math.max(0, numericValue);

    const newColor = { ...secondaryTextColor };
    newColor[colorAspect] = numericValue;
    setSecondaryTextColor(newColor);
  };

  const { legalName, logoUrl } = organization;

  const uploadLogoFile = async (
    fileUploadInformation: FileUpload
  ): Promise<void> => {
    const { importFiles } = fileUploadInformation;

    await uploadLogo(importFiles, user.organizationId, user.token)
      .then((res) => {
        setCurrentOrg(res.data);
        setIsLoading(false);
        addGlobalMessage('Logo uploaded successfully', {
          severity: 'success',
        });
      })
      .catch(() => {
        if (isMounted) {
          setIsLoading(false);
        }
        addGlobalMessage('Error uploading Logo');
      });
  };

  const saveColor = async () => {
    setIsLoading(true);

    const primaryColorRGB = `${color.r},${color.g},${color.b}`; // Converts from JSON to 'r,b,g'
    const secondaryColorRGB = `${secondaryColor.r},${secondaryColor.g},${secondaryColor.b}`; // Converts from JSON to 'r,b,g'
    const primaryTextRGB = `${textColor.r},${textColor.g},${textColor.b}`; // Converts from JSON to 'r,b,g'
    const secondaryTextRGB = `${secondaryTextColor.r},${secondaryTextColor.g},${secondaryTextColor.b}`; // Converts from JSON to 'r,b,g'

    await updateOrgBranding(
      { primaryColorRGB, secondaryColorRGB, primaryTextRGB, secondaryTextRGB },
      organization,
      user.token
    )
      .then((res) => {
        if (isMounted) {
          setIsLoading(false);
          setCurrentOrg(res.data);
          addGlobalMessage('Changes saved successfully', {
            severity: 'success',
          });
        }
      })
      .catch((err) => {
        if (isMounted) {
  
          setIsLoading(false);
 addGlobalMessage(errorMessages(err) || 'Error saving organization color information'
          );
        }
      });
  };

  const resetColor = () => {
    setColor(dbColorStringToColorObject(organization?.primaryColorRGB));
    setsecondaryColor(
      dbColorStringToColorObject(organization?.secondaryColorRGB)
    );
    setTextColor(dbColorStringToColorObject(organization?.primaryTextRGB));
    setSecondaryTextColor(
      dbColorStringToColorObject(organization?.secondaryTextRGB)
    );
  };

  return isLoading ? (
    <Box width="1">
      <LinearProgress color="secondary" />
    </Box>
  ) : (
    <>
      {/* LOGO */}
      <Box p={2} border="1px solid lightgrey" borderRadius={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">Company Logo</Typography>
          </Grid>
          <Grid item xs={12}>
            {logoUrl ? (
              <Button
                style={{ height: '44px', backgroundColor: 'white' }}
                variant="outlined"
                color="secondary"
              >
                <img
                  style={{ maxHeight: '38px', maxWidth: '156px' }}
                  src={logoUrl}
                  alt={legalName}
                />
              </Button>
            ) : (
              <Alert severity="info">
                No Logo has been set for {organization.legalName}
              </Alert>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box mt={1}>
              <FileUploadForm
                fileTypes={{ 'image/*': ['.png', '.jpeg', '.jpg'] }}
                fileText="jpg, png, jpeg"
                initialValues={FILE_UPLOAD_INIT}
                onSubmit={uploadLogoFile}
                submitName="Upload Logo"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* COLOR */}
      <Box mt={3} p={2} border="1px solid lightgrey" borderRadius={2}>
        <Grid container spacing={3}>
          <Grid item xs={3} sm={6} md={4}>
            <Grid item xs={12} pb={1}>
              <Typography variant="h6">Primary Color</Typography>
            </Grid>
            <Grid item xs={12} pb={1}>
              <RgbColorPicker color={color} onChange={setColor} />
            </Grid>
            <Grid item xs={12} pb={1}>
              <TextField
                sx={{ maxWidth: 80 }}
                variant="outlined"
                name="red"
                label="Red"
                value={color.r}
                type="number"
                onChange={(evt) => setAnRGorB('r', evt.currentTarget.value)}
              />
              <TextField
                sx={{ maxWidth: 80 }}
                variant="outlined"
                name="green"
                label="Green"
                value={color.g}
                type="number"
                onChange={(evt) => setAnRGorB('g', evt.currentTarget.value)}
              />
              <TextField
                sx={{ maxWidth: 80 }}
                variant="outlined"
                name="blue"
                label="Blue"
                value={color.b}
                type="number"
                onChange={(evt) => setAnRGorB('b', evt.currentTarget.value)}
              />
            </Grid>

            {!organization?.primaryColorRGB && (
              <Grid item xs={12}>
                <Alert severity="info">
                  No Color has been set for {organization.legalName}
                </Alert>
              </Grid>
            )}
          </Grid>
          <Grid item xs={3} sm={6} md={4}>
            <Grid item xs={12} pb={1}>
              <Typography variant="h6">Secondary Color</Typography>
            </Grid>
            <Grid item xs={12} pb={2}>
              <RgbColorPicker
                color={secondaryColor}
                onChange={setsecondaryColor}
              />
            </Grid>
            <Grid item xs={12} pb={1}>
              <TextField
                sx={{ maxWidth: 80 }}
                variant="outlined"
                name="red"
                label="Red"
                value={secondaryColor.r}
                type="number"
                onChange={(evt) =>
                  setSecondaryAnRGorB('r', evt.currentTarget.value)
                }
              />
              <TextField
                sx={{ maxWidth: 80 }}
                variant="outlined"
                name="green"
                label="Green"
                value={secondaryColor.g}
                type="number"
                onChange={(evt) =>
                  setSecondaryAnRGorB('g', evt.currentTarget.value)
                }
              />
              <TextField
                sx={{ maxWidth: 80 }}
                variant="outlined"
                name="blue"
                label="Blue"
                value={secondaryColor.b}
                type="number"
                onChange={(evt) =>
                  setSecondaryAnRGorB('b', evt.currentTarget.value)
                }
              />
            </Grid>
            {!organization?.secondaryColorRGB ? (
              <Grid item xs={12}>
                <Alert severity="info">
                  No Color has been set for {organization.legalName}&apos;s
                  Account Owner Portal
                </Alert>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Alert severity="info">
                  Secondary Color has been set for {organization.legalName}
                  &apos;s Account Owner Portal
                </Alert>
              </Grid>
            )}
          </Grid>
          <Grid item xs={3} sm={6} md={4}>
            <Grid item xs={12} pb={1}>
              <Typography variant="h6">Primary Text Color</Typography>
            </Grid>
            <Grid item xs={12} pb={1}>
              <RgbColorPicker color={textColor} onChange={setTextColor} />
            </Grid>
            <Grid item xs={12} pb={1}>
              <TextField
                sx={{ maxWidth: 80 }}
                variant="outlined"
                name="red"
                label="Red"
                value={textColor.r}
                type="number"
                onChange={(evt) => setTextAnRGorB('r', evt.currentTarget.value)}
              />
              <TextField
                sx={{ maxWidth: 80 }}
                variant="outlined"
                name="green"
                label="Green"
                value={textColor.g}
                type="number"
                onChange={(evt) => setTextAnRGorB('g', evt.currentTarget.value)}
              />
              <TextField
                sx={{ maxWidth: 80 }}
                variant="outlined"
                name="blue"
                label="Blue"
                value={textColor.b}
                type="number"
                onChange={(evt) => setTextAnRGorB('b', evt.currentTarget.value)}
              />
            </Grid>

            {!organization?.primaryTextRGB ? (
              <Grid item xs={12} pt={1} pb={2}>
                <Alert severity="info">
                  No Color has been set for {organization.legalName}&apos;s
                  Account Owner Portal
                </Alert>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Alert severity="info">
                  Primary text color has been set for {organization.legalName}
                  &apos;s Account Owner Portal
                </Alert>
              </Grid>
            )}
          </Grid>
          <Grid item xs={3} sm={6} md={4}>
            <Grid item xs={12} pb={1}>
              <Typography variant="h6">Secondary Text Color</Typography>
            </Grid>
            <Grid item xs={12} pb={1}>
              <RgbColorPicker
                color={secondaryTextColor}
                onChange={setSecondaryTextColor}
              />
            </Grid>
            <Grid item xs={12} pb={1}>
              <TextField
                sx={{ maxWidth: 63 }}
                variant="outlined"
                name="red"
                label="Red"
                value={secondaryTextColor.r}
                type="number"
                onChange={(evt) =>
                  setSecondaryTextAnRGorB('r', evt.currentTarget.value)
                }
              />
              <TextField
                sx={{ maxWidth: 63 }}
                variant="outlined"
                name="green"
                label="Green"
                value={secondaryTextColor.g}
                type="number"
                onChange={(evt) =>
                  setSecondaryTextAnRGorB('g', evt.currentTarget.value)
                }
              />
              <TextField
                sx={{ maxWidth: 63 }}
                variant="outlined"
                name="blue"
                label="Blue"
                value={secondaryTextColor.b}
                type="number"
                onChange={(evt) =>
                  setSecondaryTextAnRGorB('b', evt.currentTarget.value)
                }
              />
            </Grid>

            {!organization?.secondaryTextRGB ? (
              <Grid item xs={12} pt={1} pb={2}>
                <Alert severity="info">
                  No Color has been set for {organization.legalName}&apos;s
                  Account Owner Portal
                </Alert>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Alert severity="info">
                  Secondary text color has been set for {organization.legalName}
                  &apos;s Account Owner Portal
                </Alert>
              </Grid>
            )}
          </Grid>
          {/* 
          centering the buttons
          */}
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button
                type="submit"
                disabled={isLoading}
                variant="contained"
                color="primary"
                data-qa="color-submit-button"
                onClick={saveColor}
              >
                Save Color
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="reset"
                disabled={isLoading}
                variant="outlined"
                data-qa="color-reset-button"
                onClick={resetColor}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default OrganizationBrandingDetails;
