import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useTheme,
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  Popover,
  Tooltip,
} from '@mui/material';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  useGlobalContext,
  usePaginationContext,
} from '../../../auth/useGlobalContext';
import { exportUsers } from '../../../api/UserApi';
import { UsersResponse, User, NotificationPreferences } from '../../../api/UserApi.d';
import PluralizedString from '../../PluralizedString';
import { useUser } from '../../../auth/useUser';
import SiraNoRowsOverlay from '../../SiraNoRowsOverlay';
import {
  globalPaginationOptions,
  notificationPrefItems,
} from '../../../app.constants';
import { SiraDeleteModal } from '../../SiraDeleteModal';
import { deleteApiCall } from '../../dashboard/dashboard.utils';
import MultiButton, { MultiButtonAction } from '../../MultiButton';
import { errorMessages } from '../../../utils/errorhandling.utils';

interface UserSearchResultsProps {
  onResultClick?: Function;
  response: UsersResponse;
  onDeleteUser?: Function;
}

interface SearchResultRow extends User {
  id: number;
  fullName: string;
  communityPropertyState: boolean;
}

export function AccountSearchForm(props: UserSearchResultsProps) {
  const {
    response = {},
    onResultClick = () => { },
    onDeleteUser = () => { },
  } = props;
  const [isLoading, setIsLoading] = useState(false as boolean);
  const { roles = [], addGlobalMessage } = useGlobalContext();
  const { setGlobalPageSize } = usePaginationContext();
  const { user: currentUser } = useUser();
  const { data: results = [], query = '' } = response as UsersResponse;
  const theme = useTheme();
  const navigate = useNavigate();
  const [modalData, setModalData] = useState({ body: '', title: '' });
  const [modalOpen, setmodalOpen] = useState(false);
  const [rowData, setrowData] = useState({});

  const classes = {
    cellButton: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
    buttonNoWrap: {
      whiteSpace: 'nowrap' as any,
    },
    buttonBar: {
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
  };

  async function discardItemClick(): Promise<void> {
    await deleteApiCall(rowData, currentUser.organizationId, currentUser.token, currentUser)
      .then((res) => {
        if (res.status === 200) {
          setmodalOpen(false);
          onDeleteUser();
        }
      })
      .catch((err) => {

        addGlobalMessage(errorMessages(err) || `Error deleting user`);
        setmodalOpen(false);
      });
  }

  const buildData = (row) => {
    setrowData({
      id: row.userId,
      transactionType: 'user',
    });
    setModalData({
      title: 'User',
      body: `Do you want to delete this user ${row.firstName} ${row.lastName} ?`,
    });
    setmodalOpen(true);
  };

  const filteredResults = useMemo(
    () =>
      query
        ? results.filter(({ firstName, lastName, emailAddress }) => {
          return (
            firstName.toLowerCase().includes(query.toLowerCase()) ||
            lastName.toLowerCase().includes(query.toLowerCase()) ||
            emailAddress.toLowerCase().includes(query.toLowerCase())
          );
        })
        : results,
    [query, results]
  );

  const columns = [
    {
      field: 'fullName',
      headerName: 'Name',
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;

        return (
          <Button
            onClick={() => {
              onResultClick(row);
            }}
            sx={classes.cellButton}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {value.toString()}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'emailAddress',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'roles',
      headerName: 'Roles',
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        // Split to array and translate names to displayName
        const cellRoles = value
          .toString()
          .split(',')
          .map((roleName = '') => {
            const { displayName } =
              roles.find(({ name = '' }) => name === roleName) || {};

            return displayName || roleName;
          });
        const rolesText = cellRoles.join(', ');
        const [rolesAnchorEl, setRolesAnchorEl] =
          useState<HTMLButtonElement | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          setRolesAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
          setRolesAnchorEl(null);
        };

        return (
          <>
            <Button onClick={handleClick} sx={classes.cellButton}>
              <Box overflow="hidden" textOverflow="ellipsis">
                {rolesAnchorEl ? '' : rolesText}
              </Box>
            </Button>
            <Popover
              elevation={3}
              open={Boolean(rolesAnchorEl)}
              onClose={handleClose}
              anchorEl={rolesAnchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              <Box p={2}>
                {cellRoles.map((cellText) => (
                  <div key={`role_${cellText}`}>
                    <Typography variant="body2">{cellText}</Typography>
                  </div>
                ))}
              </Box>
            </Popover>
          </>
        );
      },
    },
    {
      field: 'mainContact',
      headerName: 'Main Contact',
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;

        return (
          <>
            {row.mainContact && (
              <Grid container alignItems="center" justifyContent="center">
                <CheckIcon />
              </Grid>
            )}
          </>
        );
      },
    },
    {
      field: 'notificationPreference',
      headerName: 'Notification Preference',
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        const enumValue = notificationPrefItems[value as NotificationPreferences];
        return (
          <Tooltip title={enumValue}>
            <Box overflow="hidden" textOverflow="ellipsis">
              {enumValue}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;

        return (
          <Button
            variant="outlined"
            onClick={() => {
              buildData(row);
            }}
            sx={{ whiteSpace: 'nowrap' }}
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  const goToAddUser = (): void => {
    navigate(`/users/addUser`);
  };

  const goToAddUsers = (): void => {
    navigate(`/dataImport/addUsers`);
  };

  const exportOrgUsers = async (fileType: string): Promise<any> => {
    setIsLoading(true);
    await exportUsers(currentUser.organizationId, currentUser.token, fileType)
      .then(() => {
        addGlobalMessage(
          'Your file will be available shortly under Notifications, located at the top of the page.',
          {
            severity: 'success',
          }
        );
      })
      .catch(() => {
        addGlobalMessage('Error exporting organization users');
      });
    setIsLoading(false);
  };

  // Define the download types
  const downloadActions: Array<MultiButtonAction> = [
    {
      label: 'Download *.tsv',
      handleAction: () => exportOrgUsers('tsv'),
      icon: <DownloadIcon />,
    },
    {
      label: 'Download *.xlsx',
      handleAction: () => exportOrgUsers('xlsx'),
      icon: <DownloadIcon />,
    },
  ];

  function resultRowFactory(userValue: User, index: number): SearchResultRow {
    const { firstName, lastName } = userValue;

    return {
      ...userValue,
      id: index,
      fullName: `${firstName} ${lastName}`,
      communityPropertyState: false,
    };
  }

  const rows = filteredResults.map((userValue, index) =>
    resultRowFactory(userValue, index)
  );

  const handleClose = () => {
    setmodalOpen(false);
  };

  return (
    <>
      <Box mb={4}>
        <Typography variant="body1">
          {query ? 'Showing results for' : 'Showing all results'}
        </Typography>

        {query && <Typography variant="h5">&quot;{query}&quot;</Typography>}
      </Box>

      <Paper elevation={3}>
        <Box p={2}>
          <Grid container wrap="nowrap" spacing={2} sx={classes.buttonBar}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {filteredResults.length}{' '}
                <PluralizedString
                  noun="user"
                  quantity={filteredResults.length}
                />{' '}
                found
              </Typography>
            </Grid>
            <Grid item>
              <Button
                sx={classes.buttonNoWrap}
                variant="contained"
                color="primary"
                startIcon={<PersonAddIcon />}
                onClick={goToAddUser}
              >
                Add User
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={classes.buttonNoWrap}
                variant="contained"
                color="primary"
                startIcon={<GroupAddIcon />}
                onClick={goToAddUsers}
              >
                Import Users
              </Button>
            </Grid>
            <Grid item>
              <MultiButton
                actions={downloadActions}
                sx={{ whiteSpace: 'nowrap' }}
                disabled={isLoading}
                variant="contained"
                color="primary"
                startIcon={<DownloadIcon />}
                endIcon={<ExpandMoreIcon />}
              >
                Export Users
              </MultiButton>
            </Grid>
          </Grid>

          <Box mt={4}>
            <DataGrid
              components={{
                NoRowsOverlay: SiraNoRowsOverlay,
              }}
              autoHeight
              columns={columns}
              rows={rows}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={globalPaginationOptions}
              onPaginationModelChange={setGlobalPageSize}
            />
          </Box>
        </Box>
        <SiraDeleteModal
          title={modalData.title}
          body={modalData.body}
          showPrompt={modalOpen}
          handleClose={handleClose}
          deleteTransaction={() => {
            discardItemClick();
          }}
        />
      </Paper>
    </>
  );
}

export default AccountSearchForm;
