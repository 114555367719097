import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Typography } from '@mui/material';

import { changeAccountStatus, getAccountDocument } from '../api/AccountApi';
import {
  AccountStatusState,
  AccountType,
  TransactionType,
} from '../api/AccountApi.d';
import { BeneficiaryStatusState } from '../api/BeneficiariesApi.d';
import {
  changeBeneficiariesStatus,
  getBeneficiariesDocument,
} from '../api/BeneficiariesApi';
import { ContributionStatusState } from '../api/ContributionApi.d';
import {
  changeContributionStatus,
  getContributionDocument,
} from '../api/ContributionApi';
import { DistributionStatusState } from '../api/DistributionApi.d';
import {
  changeDistributionStatus,
  getDistributionDocument,
} from '../api/DistributionApi';
import {
  changeRecurringDistributionStatus,
  getRecurringDistributionDocument,
} from '../api/RecurringDistributionApi';
import {
  changeTransferRequestStatus,
  getTransferRequestDocument,
} from '../api/TransferApi';
import { TransferRequestStatusState } from '../api/TransferApi.d';
import { SiraUser } from '../auth/useUser';
import { create } from 'cypress/types/lodash';

/**
  TRANSACTION NORMALIZER

  The function here are meant to stitch together transactions regardless
  of their type and treat them as if they are one type with one function
  call for common actions. Any common functionality between transactions
  that are listed together (e.g. for display on dashboard) belongs here.
  */

// Link to and load a transaction workflow based on its type
export function linkToTransaction(
  accountOwnerId: string,
  accountId: string,
  transaction: any,
  navigate: NavigateFunction
) {
  const {
    transactionType,
    contributionId = '',
    distributionId = '',
    recurringDistributionId = '',
    parentRecurringDistributionId = '',
    transferRequestId = '',
  } = transaction;

  // Should we link to the 'edit recurring distribution' workflow?
  const editActive = Boolean(parentRecurringDistributionId);

  const accountLink =
    transaction.accountType &&
    [AccountType.inheritedRoth, AccountType.inheritedTraditional].includes(
      transaction.accountType
    )
      ? '/transactions/inheritIra'
      : '/transactions/newAccount';

  switch (transactionType) {
    case TransactionType.account:
      navigate(
        `${accountLink}?accountId=${accountId}&accountOwnerId=${accountOwnerId}`
      );
      break;
    case TransactionType.beneficiaries:
      navigate(
        `/transactions/beneficiaries?accountId=${accountId}&accountOwnerId=${accountOwnerId}`
      );
      break;
    case TransactionType.contribution:
      navigate(
        `/transactions/contributions?accountId=${accountId}&accountOwnerId=${accountOwnerId}&contributionId=${contributionId}`
      );
      break;
    case TransactionType.distribution:
      navigate(
        `/transactions/distributions?accountId=${accountId}&accountOwnerId=${accountOwnerId}&distributionId=${distributionId}`
      );
      break;
    case TransactionType.recurringDistribution:
      navigate(
        `/transactions/recurringDistributions?accountId=${accountId}&accountOwnerId=${accountOwnerId}&recurringDistributionId=${recurringDistributionId}${
          editActive ? '&editActive=true' : ''
        }`
      );
      break;
    case TransactionType.transfer:
      navigate(
        `/transactions/rolloverTransfer?accountId=${accountId}&accountOwnerId=${accountOwnerId}&transferRequestId=${transferRequestId}`
      );
      break;
    case TransactionType.beneficiaryClaims:
      navigate(
        `/beneficiaryClaims/viewBeneficiaryClaim/beneficiaryClaimsProfile?accountId=${accountId}&accountOwnerId=${accountOwnerId}`
      );
      break;
    default:
      break;
  }
}

// Link to and load a transaction workflow based on its type
export function linkToOwnerTransaction(
  accountOwnerId: string,
  accountId: string,
  transaction: any,
  navigate: NavigateFunction
) {
  const {
    transactionType,
    contributionId = '',
    distributionId = '',
    recurringDistributionId = '',
    parentRecurringDistributionId = '',
    transferRequestId = '',
  } = transaction;

  // Should we link to the 'edit recurring distribution' workflow?
  const editActive = Boolean(parentRecurringDistributionId);

  const accountLink =
    transaction.accountType &&
    [AccountType.inheritedRoth, AccountType.inheritedTraditional].includes(
      transaction.accountType
    )
      ? '/owner/inheritIra'
      : '/owner/newAccount';

  switch (transactionType) {
    case TransactionType.account:
      navigate(
        `${accountLink}?accountId=${accountId}&accountOwnerId=${accountOwnerId}`
      );
      break;
    case TransactionType.beneficiaries:
      navigate(
        `/owner/beneficiaries?accountId=${accountId}&accountOwnerId=${accountOwnerId}`
      );
      break;
    case TransactionType.contribution:
      navigate(
        `/owner/contributions?accountId=${accountId}&accountOwnerId=${accountOwnerId}&contributionId=${contributionId}`
      );
      break;
    case TransactionType.distribution:
      navigate(
        `/owner/distributions?accountId=${accountId}&accountOwnerId=${accountOwnerId}&distributionId=${distributionId}`
      );
      break;
    case TransactionType.recurringDistribution:
      navigate(
        `/owner/recurringDistributions?accountId=${accountId}&accountOwnerId=${accountOwnerId}&recurringDistributionId=${recurringDistributionId}${
          editActive ? '&editActive=true' : ''
        }`
      );
      break;
    case TransactionType.beneficiaryClaims:
      navigate(
        `/beneficiaryClaims/viewBeneficiaryClaim/beneficiaryClaimsProfile?accountId=${accountId}&accountOwnerId=${accountOwnerId}`
      );
      break;
      case TransactionType.transfer:
        navigate(
          `/owner/rolloverTransfer?accountId=${accountId}&accountOwnerId=${accountOwnerId}&transferRequestId=${transferRequestId}`
        );
        break;
    default:
      break;
  }
}

// Function to get a transaction PDF request by transaction type
export function getTransactionDocument(transaction: any, user: SiraUser) {
  const { accountOwnerId, accountId, transactionId, transactionType } =
    transaction;

  const requestMap = {
    [TransactionType.account]: () =>
      getAccountDocument(
        user.organizationId,
        accountOwnerId,
        accountId,
        user.token,
        user
      ),
    [TransactionType.beneficiaries]: () =>
      getBeneficiariesDocument(
        user.organizationId,
        accountOwnerId,
        accountId,
        transactionId,
        user.token,
        '',
        user
      ),
    [TransactionType.contribution]: () =>
      getContributionDocument(
        user.organizationId,
        accountOwnerId,
        accountId,
        transactionId,
        user.token,
        user
      ),
    [TransactionType.distribution]: () =>
      getDistributionDocument(
        user.organizationId,
        accountOwnerId,
        accountId,
        transactionId,
        user.token,
        '',
        user
      ),
    [TransactionType.recurringDistribution]: () =>
      getRecurringDistributionDocument(
        user.organizationId,
        accountOwnerId,
        accountId,
        transactionId,
        user.token,
        '',
        user
      ),
    [TransactionType.transfer]: () =>
      getTransferRequestDocument(
        user.organizationId,
        accountOwnerId,
        accountId,
        transactionId,
        user.token
      ),
  };

  return requestMap[transactionType]();
}

// Function to return the correct stautus progress request based on any transaction
export function progressTransactionStatus(transaction: any, user: SiraUser) {
  const { accountOwnerId, accountId, transactionId, transactionType } =
    transaction;

  const requestMap = {
    [TransactionType.account]: () =>
      changeAccountStatus(
        user.organizationId,
        accountId,
        accountOwnerId,
        AccountStatusState.next,
        {},
        user.token,
        user
      ),
    [TransactionType.beneficiaries]: () =>
      changeBeneficiariesStatus(
        user.organizationId,
        accountId,
        accountOwnerId,
        transactionId,
        BeneficiaryStatusState.next,
        {},
        user.token,
        user
      ),
    [TransactionType.contribution]: () =>
      changeContributionStatus(
        user.organizationId,
        accountId,
        accountOwnerId,
        transactionId,
        ContributionStatusState.next,
        {},
        user.token,
        user
      ),
    [TransactionType.distribution]: () =>
      changeDistributionStatus(
        user.organizationId,
        accountId,
        accountOwnerId,
        transactionId,
        DistributionStatusState.next,
        {},
        user.token,
        user
      ),
    [TransactionType.recurringDistribution]: () =>
      changeRecurringDistributionStatus(
        user.organizationId,
        accountId,
        accountOwnerId,
        transactionId,
        DistributionStatusState.next,
        {},
        user.token,
        user
      ),
      [TransactionType.transfer]: () =>
        changeTransferRequestStatus(
          user.organizationId,
          accountId,
          accountOwnerId,
          transactionId,
          TransferRequestStatusState.next,
          {},
          user.token
        ),
  
  };

  return requestMap[transactionType]();
}

// Map transaction fields to generic dashboard fields
export function dahsboardTransactionFactory(
  data: Array<any>,
  transactionType: TransactionType
) {
  return data.map((transaction) => {
    const baseObj = {
      transactionType,
      ...transaction.account,
      ...transaction.accountOwner,
    };

    switch (transactionType) {
      case TransactionType.account:
        return {
          ...baseObj,
          transactionStatus: transaction.account.accountStatus,
          transactionId: transaction.account.accountId,
          date: transaction.account.effectiveDate || transaction.account.signedDate,
          lastSavedBy: transaction.account.lastSavedBy,
          createdByUserId: transaction.account.createdByUserId
        };
      case TransactionType.beneficiaries:
        return {
          ...baseObj,
          // Beneficiaries use the version's number instead of an ID
          transactionId: transaction.beneficiaryVersion.version,
          transactionStatus: transaction.beneficiaryVersion.beneficiaryStatus,
          lastSavedBy: transaction.beneficiaryVersion.lastSavedBy,
          createdByUserId: transaction.beneficiaryVersion.createdByUserId,
          date: transaction.beneficiaryVersion.signedDate,
        };
      case TransactionType.contribution:
        return {
          ...baseObj,
          transactionId: transaction.contribution.contributionId,
          contributionId: transaction.contribution.contributionId,
          transactionStatus: transaction.contribution.contributionStatus,
          date: transaction.contribution.effectiveDate||transaction.contribution.signedDate,
          lastSavedBy: transaction.contribution.lastSavedBy,
          createdByUserId: transaction.contribution.createdByUserId
        };
      case TransactionType.distribution:
        return {
          ...baseObj,
          transactionId: transaction.distribution.distributionId,
          distributionId: transaction.distribution.distributionId,
          transactionStatus: transaction.distribution.distributionStatus,
          date: transaction.distribution.effectiveDate||transaction.distribution.signedDate,
          lastSavedBy: transaction.distribution.lastSavedBy,
          createdByUserId: transaction.distribution.createdByUserId
        };
      case TransactionType.recurringDistribution:
        return {
          ...baseObj,
          transactionId:
            transaction.recurringDistribution.recurringDistributionId,
          recurringDistributionId:
            transaction.recurringDistribution.recurringDistributionId,
          parentRecurringDistributionId:
            transaction.recurringDistribution.parentRecurringDistributionId,
          transactionStatus:
            transaction.recurringDistribution.distributionStatus,
          date: transaction.recurringDistribution.startDate,
          lastSavedBy: transaction.recurringDistribution.lastSavedBy,
          createdByUserId: transaction.recurringDistribution.createdByUserId
        };
      case TransactionType.transfer:
        return {
          ...baseObj,
          transactionId: transaction.transferRequest.transferRequestId,
          transferRequestId: transaction.transferRequest.transferRequestId,
          transactionStatus: transaction.transferRequest.transferRequestStatus,
          date: transaction.transferRequest.signedDate,
          transferAmount: transaction.transferRequest.transferAmount,
          lastSavedBy: transaction.transferRequest.lastSavedBy,
          createdByUserId: transaction.transferRequest.createdByUserId

        };
      default:
        return {};
    }
  });
}

export function getPopupText(textType) {
  switch (textType) {
    case 'BENEFICIARY_DESIGNATION':
      return (
        <Typography variant="body2">
          The account will become payable to the deceased owner’s surviving
          spouse. If there is no surviving spouse, the account becomes payable
          to the deceased owner’s estate.
        </Typography>
      );
    default:
      return (
        <Typography variant="body2">
          The account will become payable to the deceased owner’s surviving
          spouse. If there is no surviving spouse, the account becomes payable
          to the deceased owner’s estate.
        </Typography>
      );
  }
}

export function getCopyAddressText(accountType) {
  if (accountType === AccountType.esa) {
    return `Copy designated beneficiary's address`;
  }

  if (accountType === 'BENE_CLAIMS') {
    return `Copy Deceased Owner’s Address`;
  }

  return `Copy account owner's address`;
}
