import React, { useEffect, useState } from 'react';
import { Grid, Box, IconButton, Typography, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { getYear, parseISO } from 'date-fns';
import { useUser } from '../../../auth/useUser';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../../auth/useGlobalContext';
import {
  currencyValueFormatter,
  dateValueFormatter,
} from '../../../utils/DataGrid.utils';
import { RecurringDistribution } from '../../../api/RecurringDistributionApi.d';
import { Distribution, DistributionMethod, DistributionReason } from '../../../api/DistributionApi.d';
import ReconcileScheduledDistributionForm, {
  SCHED_DIST_INIT,
} from './ReconcileScheduledDistributionForm';
import { getAccountDistributions } from '../../../api/DistributionApi';
import {
  createScheduledDistribution,
  deleteScheduledDistribution,
  updateScheduledDistribution,
} from '../../../api/ScheduledDistributionApi';
import SiraNoRowsOverlay from '../../SiraNoRowsOverlay';
import { globalPaginationOptions } from '../../../app.constants';
import { Account, AccountType } from '../../../api/AccountApi.d';
import { errorMessages } from '../../../utils/errorhandling.utils';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';

interface ReconcileScheduledDistributionProps {
  account: Account;
  distribution: RecurringDistribution;
  onSubmit: Function;
  accountOwner?: AccountOwner
}

function ReconcileScheduledDistributions(
  props: ReconcileScheduledDistributionProps
) {
  let isMounted = true;
  const { account, distribution, onSubmit, accountOwner } = props;
  const { accountId, accountOwnerId } = account;
  const { state } = accountOwner;
  const [distToEdit, setDistToEdit] = useState({});
  const [isUpdate, setIsUpdate] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [errorMessage] = useState('');
  const [scheduledDists, setScheduledDists] = useState(
    [] as Array<Distribution>
  );
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const { globalPageSize, setGlobalPageSize } = usePaginationContext();
  const { token, organizationId } = user;

  // Get and set the account's scheduled distributions for the year
  async function fetchAndSetScheduledDistributions() {
    await getAccountDistributions(
      accountId,
      accountOwnerId,
      organizationId,
      token,
      user
    )
      .then(({ data }) => {
        if (isMounted) {
          const currentYearScheduledDistributions = data.filter(
            ({ scheduledDistributionId, effectiveDate }) => {
              return (
                Boolean(scheduledDistributionId) &&
                getYear(new Date()) === getYear(parseISO(effectiveDate))
              );
            }
          );

          setScheduledDists(currentYearScheduledDistributions);
        }
      })
      .catch(() => {
        if (isMounted) {
          setScheduledDists([]);
        }
      });
  }

  async function removeScheduledDistribution(
    scheduledDistribution: Distribution
  ) {
    await deleteScheduledDistribution(
      organizationId,
      accountId,
      accountOwnerId,
      scheduledDistribution.scheduledDistributionId,
      token,
      user
    ).then(() => {
      const newDists = [...scheduledDists];
      const targetIndex = newDists.findIndex(
        (item) =>
          item.scheduledDistributionId ===
          scheduledDistribution.scheduledDistributionId
      );
      newDists.splice(targetIndex, 1);
      setScheduledDists(newDists);
    });
  }

  async function addScheduledDistribution(scheduledDistribution: Distribution) {
    const { ownerResponsible, distributionReason, distributionMethod } = distribution;
    const { accountType } = account;

    let newSchedDist = {
      ...distribution,
      ...scheduledDistribution,
      stateWithholdingAmount:
        scheduledDistribution.stateWithholdingAmount as number,
    };

// check to see if owner responsible and distribution method and reason are null
    if (ownerResponsible && distributionMethod === undefined  && distributionReason === undefined) {
       const distributeMethod = DistributionMethod.check;
      let distributionReason:DistributionReason;
      if ([AccountType.traditionalIra, AccountType.traditionalSep, AccountType.simpleIra].includes(accountType)) {
        distributionReason = DistributionReason.IRA_NORMAL;
      } else if ([AccountType.inheritedTraditional].includes(accountType)) {
        distributionReason = DistributionReason.I_IRA_DEATH;
      } else if ([AccountType.rothIra, AccountType.inheritedRoth].includes(accountType)) {
        distributionReason = DistributionReason.ROTH_QUALIFIED;
      }

      newSchedDist = {
        ...newSchedDist,
        distributionMethod: distributeMethod,
        distributionReason: distributionReason,
        withholdingState: state,
      };

    }

    await createScheduledDistribution(
      newSchedDist,
      organizationId,
      accountId,
      accountOwnerId,
      token,
      user
    )
      .then(({ data }) => {
        const tempDists = [...scheduledDists, data];

        if (isMounted) {
          setScheduledDists(tempDists);
          setShowForm(false);
          setIsUpdate(true); // isUpdate is default successful add, set default
        }
      })
      .catch((err) => {

        if (isMounted) {
 addGlobalMessage(errorMessages(err) || 'Error Adding Scheduled Distribution'
          );
        }
      });
  }

  async function update(scheduledDistribution: Distribution) {
    const newDistribution = {
      ...scheduledDistribution,
      netAmount: 0,
    };

    await updateScheduledDistribution(
      newDistribution,
      organizationId,
      accountId,
      accountOwnerId,
      token,
      user
    )
      .then(({ data }) => {
        const tempSchedDists = [...scheduledDists];
        const changedIndex = tempSchedDists.findIndex(
          (item) =>
            item.scheduledDistributionId ===
            scheduledDistribution.scheduledDistributionId
        );
        if (changedIndex >= 0) {
          tempSchedDists[changedIndex] = data;
        }
        if (isMounted) {
          setScheduledDists(tempSchedDists);
          setShowForm(false);
        }
      })
      .catch((err) => {

        if (isMounted) {
 addGlobalMessage(errorMessages(err) || 'Error Updating Scheduled Distribution'
          );
        }
      });
  }

  async function addOrChangeDistribution(scheduledDistribution: Distribution) {
    if (isUpdate) {
      update(scheduledDistribution);
    } else {
      addScheduledDistribution(scheduledDistribution);
    }
  }

  const columns = [
    {
      field: 'totalAmount',
      headerName: 'Total Amount',
      headerAlign: 'center',
      type: 'number',
      width: 148,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'federalWithholdingAmount',
      headerName: 'Federal Tax',
      sortable: false,
      headerAlign: 'center',
      type: 'number',
      width: 124,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'stateWithholdingAmount',
      headerName: 'State Tax',
      sortable: false,
      headerAlign: 'center',
      type: 'number',
      width: 124,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: 'effectiveDate',
      headerName: 'Date',
      width: 112,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      width: 110,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;

        return (
          <>
            <Grid container justifyContent="flex-end">
              <IconButton
                data-testid="EditSchedDist"
                size="small"
                aria-label="Edit Scheduled Distribution"
                onClick={() => {
                  setDistToEdit(row as Distribution);
                  setShowForm(true);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                data-testid="DeleteScheduledDistribution"
                size="small"
                aria-label="Delete Scheduled Distribution"
                onClick={async () => {
                  await removeScheduledDistribution(row as Distribution);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </>
        );
      },
    },
  ] as GridColDef[];

  useEffect(() => {
    if (token && distribution.recurringDistributionId && accountId) {
      fetchAndSetScheduledDistributions();
    }

    return () => {
      isMounted = false;
    };
  }, [distribution.recurringDistributionId, accountId]);

  return showForm ? (
    <ReconcileScheduledDistributionForm
      initialValues={{
        ...SCHED_DIST_INIT,
        ...distToEdit,
      }}
      onSubmit={async (data) => {
        await addOrChangeDistribution(data as Distribution);
      }}
      onCancel={() => {
        setShowForm(false);
        setIsUpdate(true);
      }}
      stateAbbreviation={distribution.withholdingState}
      account={account}
    />
  ) : (
    <Box width="2">
      <Box mb={3}>
        <Typography variant="body1" color="primary">
          Any distributions added, edited, or deleted in this section will
          instantly update the Distributions listed under the owner’s account.
        </Typography>
      </Box>

      <Typography variant="body2" color="error">
        {errorMessage}
      </Typography>
      <Box>
        <DataGrid
          components={{
            NoRowsOverlay: SiraNoRowsOverlay,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: globalPageSize } },
          }}
          pageSizeOptions={globalPaginationOptions}
          onPaginationModelChange={setGlobalPageSize}
          disableColumnMenu
          autoHeight
          columns={columns}
          rows={scheduledDists}
          getRowId={(r) => r.scheduledDistributionId}
        />
      </Box>
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setIsUpdate(false);
            setDistToEdit({} as Distribution);
            setShowForm(true);
          }}
        >
          Add Distribution
        </Button>
        <Box pt={3}>
          <Button
            data-qa="reconcile-distribution-continue"
            variant="contained"
            color="primary"
            onClick={() => {
              onSubmit();
            }}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ReconcileScheduledDistributions;
