/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  ListItemTextProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircleIcon from '@mui/icons-material/Circle';

import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

import * as yup from 'yup';

import { endOfYear, format, startOfYear, subYears } from 'date-fns';
import { Form, Formik } from 'formik';
import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';
import { useGlobalContext } from '../auth/useGlobalContext';
import { useUser } from '../auth/useUser';
import {
  exportDistributions,
  getTaxDocumnentStatus,
  updateTaxDocumnentStatus,
} from '../api/TaxDocumentApi';
import { TaxDocument, TaxFormType } from '../api/TaxDocument.d';
import TaxReportingCheckListProgress from './TaxReportingCheckListProgress';
import SiraModal from '../components/SiraModal';
import MultiButton, { MultiButtonAction } from '../components/MultiButton';
import SiraSelectField, {
  SiraSelectItem,
} from '../components/form/SiraSelectField';
import SiraSwitchField from '../components/form/SiraSwitchField';
import { errorMessages } from '../utils/errorhandling.utils';
import { getConstantActiveTaxYear } from '../app.constants';
import { taxFormLables } from '../components/form/organization/FinancialOrgReportingSetting';
import { getOrganizationalSettings } from '../api/OrganizationApi';
import {
  OrganizationTaxReportingSettings,
  TaxFileReportingPreference,
} from '../api/OrganizationApi.d';
import { ta } from 'date-fns/locale';
import PaperlessPage from '../components/PaperlessPage';

// irs use tax reporting
// envelope for sent to owner
// create pdf
// change to icons instead of checkbox and change color
enum PrintableTaxForm {
  default = '',
  TAX_FORM_1099R = 'TAX_FORM_1099R',
  TAX_FORM_1099SA = 'TAX_FORM_1099SA',
  TAX_FORM_1099Q = 'TAX_FORM_1099Q',
}

export const taxFormDescriptions = {
  [PrintableTaxForm.default]: '',
  [PrintableTaxForm.TAX_FORM_1099Q]: 'ESA',
  [PrintableTaxForm.TAX_FORM_1099SA]: 'HSA',
  [PrintableTaxForm.TAX_FORM_1099R]: 'IRA',
};

export const printableTaxFormDescriptions = {
  [PrintableTaxForm.default]: '',
  [PrintableTaxForm.TAX_FORM_1099Q]: PrintableTaxForm.TAX_FORM_1099Q,
  [PrintableTaxForm.TAX_FORM_1099SA]: PrintableTaxForm.TAX_FORM_1099SA,
  [PrintableTaxForm.TAX_FORM_1099R]: PrintableTaxForm.TAX_FORM_1099R,
};

interface SecondaryActionButtonsProps {
  formtype?: TaxFormType;
  documentStatus: TaxDocument;
  buttonstring: string;
  triggerReadyToPrint: Function;
  checkButtonClicked: Function;
  accountType?: string;
  preference?: TaxFileReportingPreference;
}

interface TaxReportingSelectionProps {
  initialValues: { taxFormType: PrintableTaxForm; switchValue: boolean };
  onSubmit?: Function;
  validTaxTypes: Array<TaxDocument>;
}

export interface ActiveSteps {
  readyToPrint: boolean;
  sentToOwner: boolean;
  sentToIrs: boolean;
  correctionsReadyToPrint: boolean;
  correctionsSentToOwner: boolean;
  correctionsSentToIrs: boolean;
}

type SiraListItem = ListItemTextProps &
  ListItemProps & {
    isroot?: boolean;
    group: number;
    indent?: number;
    formtype?: TaxFormType;
    buttonstring?: string;
    url?: string;
    preference?: TaxFileReportingPreference;
    // Number of levels to indent a list item
  };

export function SecondaryActionButtons(props: SecondaryActionButtonsProps) {
  const {
    formtype,
    documentStatus,
    buttonstring,
    triggerReadyToPrint,
    checkButtonClicked,
    accountType,
    preference,
  } = props;

  const { addGlobalMessage, activeTaxYear } = useGlobalContext();
  const { user } = useUser();
  const [loading, setLoading] = useState(false);

  const exportOrgDistributions = async (fileType): Promise<void> => {
    const taxYear = activeTaxYear;
    const startDate = format(
      startOfYear(new Date(taxYear, 0, 1)),
      'yyyy-MM-dd',
    );
    const endDate = format(endOfYear(new Date(taxYear, 0, 1)), 'yyyy-MM-dd');
    const formType = taxFormDescriptions[accountType];
    let type = [];

    if (formType === 'IRA') {
      type = [
        'TRADITIONAL_IRA',
        'ROTH_IRA',
        'SEP_IRA',
        'SIMPLE_IRA',
        'INHERITED_TRADITIONAL_IRA',
        'INHERITED_ROTH_IRA',
      ];
    }

    if (formType === 'HSA') {
      type = ['HEALTH_SAVINGS_ACCOUNT'];
    }

    if (formType === 'ESA') {
      type = ['COVERDELL_ESA'];
    }

    await exportDistributions(
      user.organizationId,
      user.token,
      fileType,
      startDate,
      endDate,
      [taxYear],
      type,
    )
      .then(() => {
        setLoading(false);
        addGlobalMessage(
          'Your file will be available shortly under Notifications, located at the top of the page.',
          {
            severity: 'success',
          },
        );
      })
      .catch(() => {
        setLoading(false);
        addGlobalMessage('Error exporting organization distributions');
      });
  };

  // Define the download types
  const downloadActions: Array<MultiButtonAction> = [
    {
      label: 'Download *.tsv',
      handleAction: () => {
        setLoading(true);
        exportOrgDistributions('tsv');
      },
      icon: <DownloadIcon />,
    },
    {
      label: 'Download *.xlsx',
      handleAction: () => {
        setLoading(true);
        exportOrgDistributions('xlsx');
      },
      icon: <DownloadIcon />,
    },
  ];

  if (buttonstring && formtype && documentStatus) {
    return !documentStatus.readyToPrint ? (
      <Button
        variant="contained"
        size="small"
        onClick={() => {
          triggerReadyToPrint(documentStatus);
        }}
      >
        {buttonstring}
      </Button>
    ) : (
      <></>
    );
  }

  if (buttonstring === 'Export Distributions') {
    return (
      <MultiButton
        actions={downloadActions}
        sx={{ whiteSpace: 'nowrap' }}
        disabled={loading}
        variant="contained"
        color="primary"
        startIcon={<DownloadIcon />}
        endIcon={<ExpandMoreIcon />}
      >
        {buttonstring}
      </MultiButton>
    );
  }

  return buttonstring ? (
    <Button
      variant="contained"
      size="small"
      onClick={() => {
        checkButtonClicked(buttonstring, preference);
      }}
    >
      {buttonstring}
    </Button>
  ) : (
    <></>
  );
}

function TaxReportingChecklist() {
  // Could send this array to an API or session storage when saving progress
  // Just fetch it and pass it into the default arg
  let isMounted = true;

  const [taxDocumentStatus, settaxDocumentStatus] = useState(
    [] as Array<TaxDocument>,
  );
  const { width, height } = useWindowSize();

  const [selectedDocumentStatus, setselectedDocumentStatus] = useState(
    {} as TaxDocument,
  );
  const { user } = useUser();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const { addGlobalMessage, activeTaxYear } = useGlobalContext();
  // setting this to be used for calling document status
  const [reviewType, setReviewType] = useState(
    PrintableTaxForm.default as PrintableTaxForm,
  );
  const [validTaxTypes, setValidTaxTypes] = useState([] as Array<any>);
  const [taxReportingSettings, setTaxReportingSettings] = useState([]);
  const currentYear = useMemo(() => activeTaxYear, [activeTaxYear]);
  const [progress, setShowProgress] = useState(false);
  let docStatus: TaxDocument;
  const theme = useTheme();
  let properties: SiraListItem;

  const contentStyle = {
    height: '100%',
  };

  const buttonContentStyle = {
    height: '100%',
    display: 'flex',
    justifyContent: 'right',
    paddingTop: 2,
  };

  const classes = {
    accountLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
    buttonBar: {
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
      alignItems: 'center',
      pl: 0,
      pr: 2,
    },
  };

  async function getOrgTaxReportingSettings() {
    await getOrganizationalSettings(
      getConstantActiveTaxYear(),
      user.organizationId,
      user.token,
    )
      .then((res) => {
        if (isMounted) {
          // parse the reponse data into an object and set it to the state
          // sort the printableTaxFormType all values containing 1099
          const taxsettings = res.data.filter((setting) =>
            setting.printableTaxFormType.includes('TAX_FORM_1099'),
          );

          //remove entries that is not enabled
          taxsettings.forEach((setting) => {
            if (!setting.isEnabled) {
              const index = taxsettings.indexOf(setting);
              taxsettings.splice(index, 1);
            }
          });
          setTaxReportingSettings(taxsettings);
        }
      })
      .catch((err) => {
        if (isMounted) {
          addGlobalMessage(
            errorMessages(err) || 'Error retreiving tax reporting settings',
          );
        }
      });
  }

  useEffect(() => {
    const validTaxType = taxDocumentStatus.filter((taxDocument) =>
      [
        TaxFormType.TAX_FORM_1099R,
        TaxFormType.TAX_FORM_1099SA,
        TaxFormType.TAX_FORM_1099Q,
      ].includes(taxDocument.taxFormType),
    );

    setValidTaxTypes(validTaxType);
  }, [taxDocumentStatus]);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = (value: TaxDocument) => {
    setselectedDocumentStatus(value);
    setModalOpen(true);
  };

  const getCurrentTaxDocumentStatus = async (): Promise<void> => {
    await getTaxDocumnentStatus(
      getConstantActiveTaxYear(),
      user.organizationId,
      user.token,
    )
      .then((status) => {
        if (isMounted) {
          settaxDocumentStatus(status.data);
          getOrgTaxReportingSettings();
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Could not retreive the tax documents status',
        );
      });
  };

  const triggerReadyToPrint = async (): Promise<void> => {
    const data = { ...selectedDocumentStatus, readyToPrint: true };

    await updateTaxDocumnentStatus(data, user.organizationId, user.token)
      .then(() => {
        if (isMounted) {
          getCurrentTaxDocumentStatus();
          setModalOpen(false);
          setShowConfetti(true);
          addGlobalMessage('Tax form production approved!', {
            severity: 'success',
          });
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Could not set the tax documents status',
        );
      });
  };

  const gotoUploadDistribution = (): void => {
    navigate(`/taxReporting/checklist/dataImport/distributions`);
  };

  const gotoTaxReport = (): void => {
    navigate(`/taxReporting/checklist/1099TaxReport`);
  };

  const checkButtonClicked = (value: string, preference: any): void => {
    switch (value) {
      case 'Upload Distributions':
        gotoUploadDistribution();
        break;
      case 'Get Report':
        gotoTaxReport();
        break;
      case 'Upload 1099 Tax File':
        navigate(`/taxReporting/checklist/taxReporting1099`);
        break;
      case 'View Errors':
        if (preference === TaxFileReportingPreference.spreadsheet) {
          navigate(`/dataImport?type=DISTRIBUTION_INSERT`);
        } else {
          navigate(`/dataImport?type=TAX_REPORTING_1099`);
        }
        break;
      default:
        break;
    }
  };

  const handleListClick = (event, formtype, documentStatus: TaxDocument) => {
    if (formtype && !documentStatus.readyToPrint) {
      handleModalOpen(documentStatus);
    }
    event.stopPropagation();
  };

  useEffect(() => {
    if (user.organizationId) {
      getCurrentTaxDocumentStatus();
    }
    return () => {
      isMounted = false;
    };
  }, [user.organizationId]);

  const listItems: Array<SiraListItem> = [
    {
      group: 3,
      isroot: true,
      primary: `Validate ${currentYear} 1099 Tax Records`,
    },
    {
      group: 3,
      primary: `Export ${currentYear} 1099 report`,
      buttonstring: 'Get Report',
      indent: 1,
    },

  ];

  const reviewRListItems: Array<SiraListItem> = [
    {
      group: 0,
      isroot: true,
      primary: `Review ${currentYear} Distributions`,
    },
    {
      group: 0,
      primary: `Export ${currentYear} Distributions`,
      // TODO: Wire this button up to the desired action
      buttonstring: 'Export Distributions',
      indent: 1,
    },
    {
      group: 0,
      primary:
        'Add/update Distribution records as needed in the Excel spreadsheet',
      indent: 1,
    },
    {
      group: 0,
      primary: `If minimal updates are needed, make changes under the applicable owners' accounts`,
      indent: 2,
    },
    {
      group: 0,
      primary: `If many updates are needed, upload an updated\nDistributions spreadsheet`,
      indent: 2,
      buttonstring: 'Upload Distributions',
    },
  ];

  const fmvValuesListItems: Array<SiraListItem> = [
    {
      group: 0,
      isroot: true,
      primary: `Enter 12/31 Fair Market Values`,
    },
    {
      group: 0,
      primary: `View the Fair Market Value Needed page`,
      indent: 1,
      url: '/taxReporting/FMV',
    },
    {
      group: 0,
      primary:
        `Search for Tax Year ${currentYear} and select Coverdell ESA for the Show accounts filter`,
      indent: 1,
    },
    {
      group: 0,
      primary: 'Enter the 12/31 FMVs for each ESA listed',
      indent: 1,
    },
  ];

  const dateofDeathListItems: Array<SiraListItem> = [
    {
      group: 0,
      isroot: true,
      primary: `Enter Date of Death Values`,
    },
    {
      group: 0,
      primary: `View the Date of Death Value Needed page`,
      indent: 1,
      url: '/taxReporting/FMV',
    },
    {
      group: 0,
      primary: 'Enter Date of Death Values for each HSA listed',
      indent: 1,
    },
  ];

  const reviewSAListItems: Array<SiraListItem> = [
    {
      group: 0,
      isroot: true,
      primary: `Review ${currentYear} Distributions`,
    },
    {
      group: 0,
      primary: `Export ${currentYear} Distributions`,
      // TODO: Wire this button up to the desired action
      buttonstring: 'Export Distributions',
      indent: 1,
    },
    {
      group: 0,
      primary:
        'Add/update Distribution records as needed in the Excel spreadsheet',
      indent: 1,
    },
    {
      group: 0,
      primary: `If minimal updates are needed, make changes under the applicable owners' accounts`,
      indent: 2,
    },
    {
      group: 0,
      primary:
        'If many updates are needed, upload an updated Distributions spreadsheet',
      indent: 2,
      buttonstring: 'Upload Distributions',
    },
  ];

  const reviewQListItems: Array<SiraListItem> = [
    {
      group: 0,
      isroot: true,
      primary: `Review ${currentYear} Distributions`,
    },
    {
      group: 0,
      primary: `Export ${currentYear} distributions`,
      // TODO: Wire this button up to the desired action
      buttonstring: 'Export Distributions',
      indent: 1,
    },
    {
      group: 0,
      primary: 'Add/update deposit records as needed in the Excel spreadsheet',
      indent: 1,
    },
    {
      group: 0,
      primary: `If minimal updates are needed, make changes under the applicable owners' accounts`,
      indent: 2,
    },
    {
      group: 0,
      primary:
        'If many updates are needed, upload an updated distributions spreadsheet',
      indent: 2,
      buttonstring: 'Upload Distributions',
    },
  ];

  const errorListItems: Array<SiraListItem> = [
    {
      group: 0,
      isroot: true,
      primary: `Review Errors from Uploaded Spreadsheets (if applicable)`,
      buttonstring: 'View Errors',
      preference: TaxFileReportingPreference.spreadsheet,
    },
    {
      group: 0,
      isroot: true,
      secondary: `If you uploaded a spreadsheet to update distribution records`,
    },
    {
      group: 0,
      primary: 'Correct any errors from the upload as needed',
      indent: 1,
    },
    { group: 0, primary: `Download the error file`, indent: 2 },
    { group: 0, primary: 'Correct errors', indent: 2 },
    {
      group: 0,
      primary: 'Upload the file with the corrected records',
      indent: 2,
    },
    {
      group: 0,
      primary:
        'Review the audit details report to view records that were added/updated',
      indent: 1,
    },
  ];

  const retrieveListItems: Array<SiraListItem> = [
    {
      group: 0,
      isroot: true,
      primary: `Retrieve 1099 Tax File from Core Processing System`,
    },
    {
      group: 0,
      primary: 'Upload IRS 750 formatted tax file',
      indent: 1,
      buttonstring: 'Upload 1099 Tax File',
    },
    {
      group: 0,
      isroot: true,
      primary: `Review Errors from Uploaded Tax File`,
      buttonstring: 'View Errors',
      preference: TaxFileReportingPreference.taxFile,
    },
    {
      group: 0,
      primary: `Correct any errors from the upload as needed' accounts`,
      indent: 1,
    },
    {
      group: 0,
      primary:
        'Review audit details report to view records that were added/updated',
      indent: 1,
    },
  ];

  const getTaxReportingSteps = (
    fileType: any,
    taxReportingSettings: any,
    index: any,
  ) => {
    const accountType = taxReportingSettings[index].printableTaxFormType;
    if (
      accountType === PrintableTaxForm.TAX_FORM_1099Q &&
      fileType === TaxFileReportingPreference.spreadsheet
    ) {
      return [
        ...reviewQListItems,
        ...errorListItems,
        ...fmvValuesListItems,
        ...listItems,
      ];
    }
    if (
      accountType === PrintableTaxForm.TAX_FORM_1099R &&
      fileType === TaxFileReportingPreference.spreadsheet
    ) {
      return [...reviewRListItems, ...errorListItems, ...listItems];
    }
    if (
      accountType === PrintableTaxForm.TAX_FORM_1099SA &&
      fileType === TaxFileReportingPreference.spreadsheet
    ) {
      return [
        ...reviewSAListItems,
        ...errorListItems,
        ...dateofDeathListItems,
        ...listItems,
      ];
    }

    if (
      [
        PrintableTaxForm.TAX_FORM_1099Q,
      ].includes(accountType) &&
      fileType === TaxFileReportingPreference.taxFile
    ) {
      return [...retrieveListItems, ...fmvValuesListItems,
      ...listItems];
    }
    return [...retrieveListItems, ...listItems];
  };

  const checkListEval = (
    props: any,
    documentStatus: TaxDocument,
    i: any,
    accountType: any,
  ) => {
    if (!props.formtype) {
      return (
        <ListItem
          key={`listItem_${+1}`}
          sx={{ pl: 5 * props.indent }}
          secondaryAction={
            <SecondaryActionButtons
              formtype={props.formtype}
              documentStatus={documentStatus}
              buttonstring={props.buttonstring}
              triggerReadyToPrint={handleModalOpen}
              checkButtonClicked={checkButtonClicked}
              accountType={accountType}
              preference={props.preference}
            />
          }
          disablePadding
        >
          <ListItemButton
            onClick={(event) => {
              handleListClick(event, props.formtype, documentStatus);
            }}
          >
            {!props.isroot && (
              <ListItemIcon id={i}>
                <CircleIcon color="primary" />
              </ListItemIcon>
            )}
            {props.url ? (
              <a href={props.url}>
                <ListItemText
                  primaryTypographyProps={
                    props.isroot
                      ? {
                        variant: 'h5',
                        fontWeight: 'bold',
                      }
                      : {
                        sx: { width: '400px' },
                      }
                  }
                  {...props}
                />
              </a>
            ) : (
              <ListItemText
                primaryTypographyProps={
                  props.isroot
                    ? {
                      variant: 'h5',
                      fontWeight: 'bold',
                    }
                    : {
                      sx: { width: '400px' },
                    }
                }
                {...props}
              />
            )}
          </ListItemButton>
        </ListItem>
      );
    }

    return (
      documentStatus &&
      documentStatus.enabled &&
      !documentStatus.readyToPrint && (
        <ListItem
          key={`listItem_${+1}`}
          sx={{ pl: 5 * props.indent }}
          secondaryAction={
            <SecondaryActionButtons
              formtype={props.formtype}
              documentStatus={documentStatus}
              buttonstring={props.buttonstring}
              triggerReadyToPrint={handleModalOpen}
              checkButtonClicked={checkButtonClicked}
              accountType={reviewType}
            />
          }
          disablePadding
        >
          <ListItemButton
            onClick={(event) => {
              handleListClick(event, props.formtype, documentStatus);
            }}
          >
            {!props.isroot && (
              <ListItemIcon>
                <CircleIcon color="primary" />
              </ListItemIcon>
            )}
            {props.url ? (
              <a href={props.url}>
                <ListItemText
                  primaryTypographyProps={
                    props.isroot
                      ? {
                        variant: 'h5',
                        fontWeight: 'bold',
                      }
                      : {
                        sx: { width: '400px' },
                      }
                  }
                  {...props}
                />
              </a>
            ) : (
              <ListItemText
                primaryTypographyProps={
                  props.isroot
                    ? {
                      variant: 'h5',
                      fontWeight: 'bold',
                      noWrap: true,
                    }
                    : {
                      noWrap: true,
                      sx: { width: '400px' },
                    }
                }
                {...props}
              />
            )}
          </ListItemButton>
        </ListItem>
      )
    );
  };

  return (
    <Layout>
      <PaperlessPage>
        <Typography variant="overline" gutterBottom>
          Tax Reporting
        </Typography>
        <Typography variant="h1" color="secondary" gutterBottom>
          1099 Reporting Checklist
        </Typography>
        {taxReportingSettings.map((setting, index) => {
          const { printableTaxFormType } =
            setting;

          return (
            <Accordion
              onChange={(e, expanded) => { }}
              slotProps={{
                transition: {
                  mountOnEnter: true,
                  unmountOnExit: true,
                },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container wrap="nowrap" sx={classes.buttonBar}>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="secondary">
                      {taxFormLables[printableTaxFormType]}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>

              <AccordionDetails>
                <List
                  sx={{ width: '100%' }}
                  key={`list_${index}`}
                >
                  {getTaxReportingSteps(
                    taxReportingSettings[index].reportingFilePreference, 
                    taxReportingSettings, index,

                  ).map((props, i) => {
                    const documentStatus = taxDocumentStatus
                      ? taxDocumentStatus.find(
                        (status) => status.taxFormType === props.formtype,
                      )
                      : ({} as TaxDocument);

                    const showProgress =
                      documentStatus &&
                      documentStatus.readyToPrint &&
                      documentStatus.enabled;

                    docStatus = documentStatus;
                    properties = props;

                    return (
                      <>
                        {checkListEval(
                          props,
                          documentStatus,
                          i,
                          taxReportingSettings[index].printableTaxFormType,
                        )}
                      </>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
          );
        })}
        {taxReportingSettings.length > 0 && (
          <Accordion
            onChange={(e, expanded) => { }}
            slotProps={{
              transition: {
                mountOnEnter: true,
                unmountOnExit: true,
              },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container wrap="nowrap" sx={classes.buttonBar}>
                <Grid item xs={12}>
                  <Typography variant="h5" color="secondary">
                    Approve Tax Form Production
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>

            <AccordionDetails>
              {taxReportingSettings.map((setting, index) => {
                const {
                  isEnabled,
                  printableTaxFormType,
                } = setting;
                const documentStatus = taxDocumentStatus
                  ? taxDocumentStatus.find(
                    (status) => status.taxFormType === taxReportingSettings[index].printableTaxFormType,
                  )
                  : ({} as TaxDocument);

                const showProgress =
                  documentStatus &&
                  documentStatus.readyToPrint &&
                  documentStatus.enabled;

                if (isEnabled) {
                  // Extract the value after the underscore
                  const taxFormTypeParts = taxReportingSettings[index].printableTaxFormType.split('_');
                  const taxFormTypeValue = taxFormTypeParts[taxFormTypeParts.length - 1];

                  return (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                          pb: 2,
                        }}
                      >
                        <Typography variant="body1" color="secondary" pb={2}>
                          {taxFormLables[printableTaxFormType]}
                        </Typography>
                        {!showProgress && (
                          <Button
                            variant="contained"
                            size="small"
                            onClick={(event) => {
                              handleListClick(
                                event,
                                taxReportingSettings[index].printableTaxFormType,
                                documentStatus,
                              );
                            }}
                          >
                            {`Create ${taxFormTypeValue} Tax Forms`}
                          </Button>
                        )}
                      </Box>

                      <Box pb={2}>
                        <TaxReportingCheckListProgress
                          documentStatus={documentStatus}
                        />
                      </Box>
                    </>
                  );
                }
              })}
            </AccordionDetails>
          </Accordion>
        )}

        <SiraModal open={modalOpen} handleClose={handleClose}>
          <>
            <Box sx={contentStyle} pt={2}>
              <Typography variant="body1">
                {' '}
                Tax forms will be created upon your approval and will be
                delivered by Superior.
              </Typography>
            </Box>
            <Box sx={buttonContentStyle}>
              <Box pr={2}>
                <Button
                  data-qa="delete-modal_cancel"
                  variant="outlined"
                  onClick={() => {
                    handleClose();
                  }}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  data-qa="delete-modal_confirm"
                  variant="outlined"
                  onClick={() => {
                    triggerReadyToPrint();
                  }}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  OK
                </Button>
              </Box>
            </Box>
          </>
        </SiraModal>
        {showConfetti && (
          <Confetti
            width={width}
            height={height + 400}
            numberOfPieces={200}
            onConfettiComplete={() => {
              setShowConfetti(false);
            }}
            recycle={false}
          />
        )}
      </PaperlessPage>
    </Layout>
  );
}

export default TaxReportingChecklist;
