import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import {
  Box,
  Grid,
  Button,
  Typography,
  IconButton,
  Popover,
  LinearProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NumberFormat from 'react-number-format';
import { getYear, format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';


import {
  Distribution,
  DistributionReason,
  DistributionStatus,
} from '../../api/DistributionApi.d';
import TaxYearsFilterForm from '../form/TaxYearsFilterForm';
import TransactionSignatureIcon from '../../icons/TransactionSignature';
import TransactionPendingIcon from '../../icons/TransactionPending';
import {
  deleteDistribution,
  getAccountDistributions,
  getDistributionDocument,
  updateDistribution,
} from '../../api/DistributionApi';
import { useUser } from '../../auth/useUser';
import { UserRole } from '../../api/UserApi.d';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { linkToTransaction } from '../../utils/transaction.utils';
import { TransactionType, Account, AccountType } from '../../api/AccountApi.d';
import { ConstantsMappingKey } from '../../api/SharedTextApi.d';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import DistributionAmountForm, {
  DISTRIBUTION_AMOUNT_INIT,
} from '../form/distribution/DistributionAmountForm';
import { AccountOwner } from '../../api/AccountOwnerApi.d';
import DistributionCodeForm, {
  DISTRIBUTION_CODE_INIT,
} from '../form/distribution/DistributionCodeForm';
import DistributionMethodForm, {
  DISTRIBUTION_METHOD_INIT,
} from '../form/distribution/DistributionMethodForm';
import TransactionStepper, {
  TransactionStep,
} from '../../page/TransactionStepper';
import { SiraDeleteModal } from '../SiraDeleteModal';
import { dateValueFormatter } from '../../utils/DataGrid.utils';
import { distributionMethodShortNames } from '../../app.constants';
import { createFileBlobFromBase64 } from '../../utils/App.utils';
import ReconcileScheduledDistributionForm, {
  SCHED_DIST_INIT,
} from '../form/distribution/ReconcileScheduledDistributionForm';
import {
  deleteScheduledDistribution,
  updateScheduledDistribution,
} from '../../api/ScheduledDistributionApi';
import InfoPopover from '../InfoPopover';
import { errorMessages } from '../../utils/errorhandling.utils';

interface AccountOwnerAccountDistributionsProps {
  account: Account;
  accountOwner: AccountOwner;
}

export const TAX_YEAR_FILTER_SCHEMA = {
  taxYearFilters: yup.array(yup.number()).label('Filters'),
};

function AccountOwnerAccountDistributions(
  props: AccountOwnerAccountDistributionsProps
) {
  let isMounted = true;
  const { account = {}, accountOwner = {} } = props;
  const { accountId = '', accountOwnerId = '', accountType } = account;
  const navigate = useNavigate();
  const { getAppConstant, organization, addGlobalMessage } = useGlobalContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const { user } = useUser();
  const [allDistributions, setAllDistributions] = useState(
    [] as Array<Distribution>
  );
  const [selectedDistribution, setSelectedDistribution] = useState<any>({});
  const [filteredDistributions, setFilteredDistributions] = useState<
    Array<Distribution>
  >([]);
  const [modalData, setModalData] = useState({ body: '', title: '' });
  const [modalOpen, setmodalOpen] = useState(false);

  // Only show active and scheduled distributions here
  const distributions = useMemo(
    () =>
      allDistributions.filter(
        ({ distributionStatus, scheduledDistributionId = '' }) =>
          Boolean(scheduledDistributionId) ||
          distributionStatus === DistributionStatus.active
      ),
    [allDistributions]
  );

  const taxYearFilterOptions = useMemo(
    () => [
      ...new Set(
        distributions.map(({ effectiveDate }) =>
          getYear(parseISO(effectiveDate)).toString()
        )
      ),
    ],
    [distributions]
  );

  const buildData = () => {
    setModalData({
      title: 'Distribution',
      body: `Do you want to delete this distribution?`,
    });
    setmodalOpen(true);
  };
  // Account types and distribution codes that don't support withholding
  const showWithholding =
    ![AccountType.hsa, AccountType.esa].includes(accountType) &&
    ![
      DistributionReason.IRA_ROLLOVER,
      DistributionReason.TRANSFER,
      DistributionReason.PROHIBITED,
      DistributionReason.SAME_YEAR_RECHAR,
      DistributionReason.PRIOR_YEAR_RECHAR,
    ].includes(selectedDistribution.distributionReason);

  // Only show state withholding when an org has at least one state that supports it
  const showStateWithholding =
    organization.stateTaxIDS &&
    organization.stateTaxIDS.some(
      ({ stateWithholding = false }) => stateWithholding
    );

  const classes = {
    distributionLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
  };

  const onCancelClick = () => {
    setIsEditing(false);
    setActiveStep(0);
  };

  async function getActiveDistributions() {
    setIsLoading(true);

    await getAccountDistributions(
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user,
      [DistributionStatus.active]
    )
      .then((res) => {
        if (isMounted) {
          setAllDistributions(
            res.data.map((t) => ({
              ...t,
              transactionType: TransactionType.distribution,
            }))
          );
        }
      })
      .catch((err) => {
        if (isMounted) {
          setAllDistributions([]);
          addGlobalMessage(errorMessages(err) || `Error fetching account distributions`
          );
        }
      });

    if (isMounted) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (user.organizationId && user.token && accountId) {
      getActiveDistributions();
    }

    return () => {
      isMounted = false;
    };
  }, [user.organizationId, user.token, accountId]);

  // Override the selected distribution record (superuser permission)
  const updateSelectedDistribution = async (
    data: Distribution
  ): Promise<void> => {
    
    const merged = {
      ...selectedDistribution,
      ...data,
      federalWithholdingAmount: !data.percentFieldChange
        ? '0'
        : data.federalWithholdingAmount,
    }; // Merge a reverted status immediately when saving

    await updateDistribution(
      merged,
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user
    )
      .then(() => {
        if (isMounted) {
          setSelectedDistribution({} as Distribution);
        }

        getActiveDistributions();
        addGlobalMessage('Distribution successfully updated', {
          severity: 'success',
        });
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) || 'Could not update this distribution'
        );
      });

    if (isMounted) {
      setIsEditing(false);
    }
  };

  // Delete the selected distribution record (superuser permission)
  const deleteSelectedDistribution = async (): Promise<void> => {
    setIsDeleting(true);

    if (selectedDistribution.scheduledDistributionId) {
      await deleteScheduledDistribution(
        user.organizationId,
        accountId,
        accountOwnerId,
        selectedDistribution.scheduledDistributionId,
        user.token,
        user
      )
        .then(() => {
          setSelectedDistribution({} as Distribution);
          getActiveDistributions();
          addGlobalMessage('Scheduled Distribution successfully deleted', {
            severity: 'success',
          });
        })
        .catch((err) => {
  
          addGlobalMessage(errorMessages(err) || 'Could not delete this scheduled distribution'
          );
        });
    } else {
      await deleteDistribution(
        accountId,
        accountOwnerId,
        user.organizationId,
        selectedDistribution.distributionId,
        user.token,
        user
      )
        .then(() => {
          setSelectedDistribution({} as Distribution);
          getActiveDistributions();
          addGlobalMessage('Distribution successfully deleted', {
            severity: 'success',
          });
        })
        .catch((err) => {
  
          addGlobalMessage(errorMessages(err) || 'Could not delete this distribution'
          );
        });
    }

    if (isMounted) {
      setIsDeleting(false);
    }
  };

  function goToOrShowTransaction(row: Distribution) {
    const { distributionStatus } = row;
    // Only navigate to WIP transactions
    const shouldGoToTransaction = [
      DistributionStatus.pending,
      DistributionStatus.signature,
    ].includes(distributionStatus);

    if (shouldGoToTransaction) {
      linkToTransaction(accountOwnerId, accountId, row, navigate);
    } else {
      setSelectedDistribution(row);
    }
  }

  async function openPdf(distributionId: string) {
    await getDistributionDocument(
      user.organizationId,
      accountOwnerId,
      accountId,
      distributionId,
      user.token,
      '',
      user
    )
      .then(({ data }) => {
        const { bytes: base64 = '' } = data;
        const winURL = window.URL.createObjectURL(
          createFileBlobFromBase64(base64, 'application/pdf') || data
        );
        window.open(winURL, '_blank').focus();
      })
      .catch(() => {
        addGlobalMessage('Error fetching the selected PDF document');
      });
  }

  const columns: GridColDef[] = [
    {
      field: 'distributionStatus',
      headerName: '',
      width: 90,
      renderCell: (params: GridCellParams) => {
        const { value = '', row = {} } = params;
        const isScheduled = Boolean(row.scheduledDistributionId);
        const [isFetchingDocument, setIsFetchingDocument] = useState(
          false as boolean
        );
        const isPending = row.distributionStatus === DistributionStatus.pending;
        const statusIcons = {
          [DistributionStatus.active]: <AssignmentTurnedInIcon />,
          [DistributionStatus.pending]: <TransactionPendingIcon />,
          [DistributionStatus.signature]: <TransactionSignatureIcon />,
        };
        const [rolesAnchorEl, setRolesAnchorEl] =
          useState<HTMLButtonElement | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          setRolesAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
          setRolesAnchorEl(null);
        };

        return (
          <>
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Grid item>
                <IconButton
                  aria-label={`distribution status ${value}`}
                  size="small"
                  color="primary"
                  onClick={handleClick}
                >
                  {isScheduled ? (
                    <EventAvailableIcon />
                  ) : (
                    statusIcons[value.toString()]
                  )}
                </IconButton>
              </Grid>

              {!isPending && !isScheduled && (
                <Grid item>
                  <IconButton
                    disabled={isFetchingDocument}
                    color="primary"
                    size="small"
                    data-testid={`open-distribution-pdf_${row.distributionId}`}
                    aria-label="Open latest pdf document"
                    onClick={async () => {
                      setIsFetchingDocument(true);
                      await openPdf(row.distributionId);
                      setIsFetchingDocument(false);
                    }}
                  >
                    <PictureAsPdfIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>

            <Popover
              elevation={3}
              open={Boolean(rolesAnchorEl)}
              onClose={handleClose}
              anchorEl={rolesAnchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              <Box p={2}>
                <Typography variant="body2">
                  Status: {isScheduled ? row.distributionType : value}
                </Typography>
              </Box>
            </Popover>
          </>
        );
      },
    },
    {
      field: 'distributionReason',
      headerName: 'Reason',
      width: 228,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;
        const typeId = value.toString();

        return (
          <Button
            onClick={() => {
              goToOrShowTransaction(row);
            }}
            sx={classes.distributionLink}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {getAppConstant(ConstantsMappingKey.distributionReason, typeId)}
            </Box>
          </Button>
        );
      },
    },
    {
      field: 'effectiveDate',
      headerName: 'Date',
      width: 126,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'grossAmount',
      headerName: 'Gross Amount',
      align: 'right',
      headerAlign: 'right',
      width: 156,
      renderCell: (params: GridCellParams) => {
        const { row = {} } = params;
        const amount = row.grossAmount || row.totalAmount;

        return (
          <NumberFormat
            value={amount}
            prefix="$"
            displayType="text"
            thousandSeparator
            isNumericString
            fixedDecimalScale
            decimalScale={2}
          />
        );
      },
    },
    {
      field: 'federalWithholdingAmount',
      headerName: 'Federal Withholding',
      align: 'right',
      headerAlign: 'right',
      width: 186,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        const amount = value.toString();

        return (
          <NumberFormat
            value={amount}
            prefix="$"
            displayType="text"
            thousandSeparator
            isNumericString
            fixedDecimalScale
            decimalScale={2}
          />
        );
      },
    },
    {
      field: 'stateWithholdingAmount',
      headerName: 'State Withholding',
      align: 'right',
      headerAlign: 'right',
      width: 176,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        const amount = value.toString();

        return (
          <NumberFormat
            value={amount}
            prefix="$"
            displayType="text"
            thousandSeparator
            isNumericString
            fixedDecimalScale
            decimalScale={2}
          />
        );
      },
    },
  ];

  async function update(scheduledDistribution: Distribution) {
    const newDistribution = { ...scheduledDistribution, netAmount: 0 };

    await updateScheduledDistribution(
      newDistribution,
      user.organizationId,
      accountId,
      accountOwnerId,
      user.token,
      user
    )
      .then(() => {
        if (isMounted) {
          setSelectedDistribution({} as Distribution);
        }

        getActiveDistributions();
        addGlobalMessage('Distribution successfully updated', {
          severity: 'success',
        });
      })
      .catch((err) => {

 addGlobalMessage(errorMessages(err) || 'Could not update this distribution'
        );
      });

    if (isMounted) {
      setIsEditing(false);
    }
  }

  // LOADING
  if (isLoading) {
    return <LinearProgress />;
  }


  // EDIT
  if (isEditing && selectedDistribution.distributionId) {
    const distributionSteps: Array<TransactionStep> = [
      {
        label: 'Select Distribution Reason',
        stepContent: (
          <DistributionCodeForm
            account={account}
            accountOwner={accountOwner}
            onSubmit={(data) => {
              setSelectedDistribution({ ...selectedDistribution, ...data });
              setActiveStep(activeStep + 1);
            }}
            onCancel={onCancelClick}
            initialValues={{
              ...DISTRIBUTION_CODE_INIT,
              ...selectedDistribution,
            }}
          />
        ),
      },
      {
        label: 'Provide Distribution Amounts',
        stepContent: (
          <DistributionAmountForm
            account={account}
            accountOwner={accountOwner}
            onSubmit={(data) => {
              setSelectedDistribution({ ...selectedDistribution, ...data });
              setActiveStep(activeStep + 1);
            }}
            onCancel={onCancelClick}
            initialValues={{
              ...DISTRIBUTION_AMOUNT_INIT,
              ...selectedDistribution,
            }}
            showWithholding={showWithholding}
            showStateWithholding={showStateWithholding}
            explodeSteps
            isEditing
          />
        ),
      },
      {
        label: 'Select Distribution Method',
        stepContent: (
          <DistributionMethodForm
            initialValues={{
              ...DISTRIBUTION_METHOD_INIT,
              ...selectedDistribution,
            }}
            onSubmit={async (data) => {
              setSelectedDistribution({ ...selectedDistribution, ...data });
              await updateSelectedDistribution(data);
            }}
            onCancel={onCancelClick}
            accountType={accountType}
            distributionReason={selectedDistribution.distributionReason}
          />
        ),
      },
    ];

    return (
      <>
        <Box mb={2}>
          <TransactionStepper
            steps={distributionSteps}
            activeStep={activeStep}
            onStepClick={(stepNumber) => {
              setActiveStep(stepNumber);
            }}
          />
        </Box>
      </>
    );
  }

  // EDIT scheduled distributions
  if (isEditing && selectedDistribution.scheduledDistributionId) {
    return (
      <ReconcileScheduledDistributionForm
        initialValues={{
          ...SCHED_DIST_INIT,
          ...selectedDistribution,
        }}
        onSubmit={async (data) => {
          await update(data as Distribution);
        }}
        onCancel={() => {
          setIsEditing(false);
        }}
        stateAbbreviation={selectedDistribution.withholdingState}
        account={account}
      />
    );
  }

  const handleClose = () => {
    setmodalOpen(false);
  };

  // DETAILS
  if (
    selectedDistribution.distributionId ||
    selectedDistribution.scheduledDistributionId
  ) {
    const {
      distributionId = '',
      distributionReason = '',
      distributionMethod = '',
      toAccountNumber = '',
      toAccountType = '',
      toFinancialOrganization = '',
      toAccountTypeName = '',
      totalAmount = '',
      grossAmount = '',
      netAmount = '',
      effectiveDate = '',
      signedDate = '',
      federalWithholdingPercent = '',
      federalWithholdingAmount = '',
      stateWithholdingPercent = '',
      stateWithholdingAmount = '',
      additionalStateWithholding = '',
      penaltyAmount = '',
      netIncomeAttributable = '',
      scheduledDistributionId = '',
      excludeFromRmd = false,
    } = selectedDistribution;
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Reason</Typography>
          <Typography variant="body1">
            {getAppConstant(
              ConstantsMappingKey.distributionReason,
              distributionReason
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Method</Typography>
          <Typography variant="body1">
            {distributionMethodShortNames[distributionMethod] ||
              distributionMethod}
          </Typography>
          {toFinancialOrganization && (
            <Typography variant="body1">
              To: {toFinancialOrganization}
            </Typography>
          )}
          {toAccountNumber && (
            <Typography variant="body1"># {toAccountNumber}</Typography>
          )}
          {toAccountType && (
            <Typography variant="body1">
              {getAppConstant(ConstantsMappingKey.accountType, toAccountType)}
            </Typography>
          )}
          {toAccountTypeName && (
            <Typography variant="body1">To: {toAccountTypeName}</Typography>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">ID</Typography>
          <Typography variant="body1">
            {distributionId || scheduledDistributionId}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Date</Typography>
          <Typography variant="body1">
            {effectiveDate &&
              format(parseISO(String(effectiveDate)), 'MM/dd/yyyy')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Date Signed</Typography>
          <Typography variant="body1">
            {signedDate && format(parseISO(String(signedDate)), 'MM/dd/yyyy')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Net Amount</Typography>
          <Typography variant="body1">
            <NumberFormat
              value={netAmount}
              prefix="$"
              displayType="text"
              thousandSeparator
              isNumericString
              fixedDecimalScale
              decimalScale={2}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Total Amount</Typography>
          <Typography variant="body1">
            <NumberFormat
              value={totalAmount || grossAmount}
              prefix="$"
              displayType="text"
              thousandSeparator
              isNumericString
              fixedDecimalScale
              decimalScale={2}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Federal Withholding</Typography>
          <Typography variant="body1">
            <NumberFormat
              value={federalWithholdingPercent}
              suffix="%"
              displayType="text"
            />
          </Typography>
          <Typography variant="body1">
            <NumberFormat
              value={federalWithholdingAmount}
              prefix="$"
              displayType="text"
              thousandSeparator
              isNumericString
              fixedDecimalScale
              decimalScale={2}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">State Withholding</Typography>
          <Typography variant="body1">
            <NumberFormat
              value={stateWithholdingPercent}
              suffix="%"
              displayType="text"
            />
          </Typography>
          <Typography variant="body1">
            <NumberFormat
              value={stateWithholdingAmount}
              prefix="$"
              displayType="text"
              thousandSeparator
              isNumericString
              fixedDecimalScale
              decimalScale={2}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Penalty</Typography>
          <Typography variant="body1">
            <NumberFormat
              value={penaltyAmount}
              prefix="$"
              displayType="text"
              thousandSeparator
              isNumericString
              fixedDecimalScale
              decimalScale={2}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Additional State</Typography>
          <Typography variant="body1">
            <NumberFormat
              value={additionalStateWithholding}
              prefix="$"
              displayType="text"
              thousandSeparator
              isNumericString
              fixedDecimalScale
              decimalScale={2}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">
            {excludeFromRmd
              ? "Do not reduce RMD":null}                  
              {excludeFromRmd ? <InfoPopover text="Do not reduce remaining required minimum distributions by the amount of this distribution." /> : null}

          </Typography>
        </Grid>

        {netIncomeAttributable && (
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Net Income Attributable</Typography>
            <Typography variant="body1">
              <NumberFormat
                value={netIncomeAttributable}
                prefix="$"
                displayType="text"
                thousandSeparator
                isNumericString
                fixedDecimalScale
                decimalScale={2}
              />
            </Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item>
              <Button
                aria-label="Go back to account distributions"
                variant="outlined"
                color="primary"
                onClick={() => {
                  setSelectedDistribution({});
                }}
                startIcon={<ArrowBackIcon />}
              >
                All Distributions
              </Button>
            </Grid>

            {(user.roles.includes(UserRole.orgTransactionsAdmin) &&
              (selectedDistribution.distributionId ||
                selectedDistribution.scheduledDistributionId) && (
                <>
                  <Grid item>
                    <Button
                      aria-label="Edit distribution details"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setIsEditing(true);
                      }}
                      startIcon={<EditIcon />}
                    >
                      Edit Details
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      disabled={isDeleting}
                      aria-label="Delete this distribution"
                      variant="contained"
                      color="error"
                      onClick={buildData}
                      startIcon={<DeleteIcon />}
                    >
                      Delete Distribution
                    </Button>
                  </Grid>
                </>
              ))}
          </Grid>
        </Grid>
        <SiraDeleteModal
          title={modalData.title}
          body={modalData.body}
          showPrompt={modalOpen}
          handleClose={handleClose}
          deleteTransaction={deleteSelectedDistribution}
        />
      </Grid>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TaxYearsFilterForm
            items={distributions}
            options={taxYearFilterOptions}
            onChange={setFilteredDistributions}
          />
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            components={{
              NoRowsOverlay: SiraNoRowsOverlay,
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10]}
            disableColumnMenu
            autoHeight
            columns={columns}
            rows={filteredDistributions.map((result, id) => ({
              id,
              transactionType: TransactionType.distribution,
              ...result,
            }))}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AccountOwnerAccountDistributions;
