import { AccountType } from './AccountApi.d';
// eslint-disable-next-line import/no-cycle
import { TermChoiceOption } from './RecurringDistributionApi';

export interface DistributionsResponse {
  data?: Array<Distribution>;
  errorMessage?: any;
  status?: number;
}

export interface DistributionResponse {
  data?: Distribution;
  errorMessage?: any;
  status?: number;
}

export interface DistributionImportColumnsResponse {
  data?: DistributionImportColumns;
  errorMessage?: any;
}

export interface DistributionImportColumns {
  ourHeaders?: Array<string>;
  fileHeaders?: Array<string>;
  rowData?: Array<any>;
}

export enum DistributionTypeEnum {
  UNSCHEDULED = 'UNSCHEDULED',
  SCHEDULED = 'SCHEDULED',
  JOINT_LIFE_EXPECTANCY = 'JOINT_LIFE_EXPECTANCY',
  SINGLE_LIFE_EXPECTANCY = 'SINGLE_LIFE_EXPECTANCY',
  UNIFORM_LIFETIME = 'UNIFORM_LIFETIME',
  SINGLE_LIFE_EXPECTANCY_NON_RECALCULATED = 'SINGLE_LIFE_EXPECTANCY_NON_RECALCULATED',
}

export interface Distribution extends NetIncomeAttributableRequest {
  termEnabled?: any;
  accountId?: string;
  activeDate?: string;
  additionalStateWithholding?: number;
  closingAccount?: boolean;
  niaOptions?: string;
  totalAmount?: number;
  grossAmount?: number;
  netAmount?: number;
  netIncomeAttributable?: number | string;
  effectiveDate?: string;
  startDate?: string;
  distributionId?: string;
  distributionMethod?: DistributionMethod;
  distributionStatus?: DistributionStatus;
  federalTaxElectionType?: FederalTaxElectionType;
  federalWithholdingAmount?: any;
  federalWithholdingPercent?: number | string;
  penaltyAmount?: number;
  signedDate?: string;
  stateWithholdingAmount?: number ;
  stateWithholdingPercent?: number | string;
  distributionReason?: DistributionReason;
  toAccountNumber?: string;
  toAccountType?: AccountType;
  toFinancialOrganization?: string;
  toAccountTypeName?: string;
  routingNumber?: string;
  payableTo?: string;
  withholdingState?: string;
  distributionType?: DistributionTypeEnum;
  recurringDistributionId?: string;
  scheduledDistributionId?: string;
  ownerResponsible?: boolean;
  percentFieldChange?: boolean; // used for visual and setting withholdingamount
  fmvInDb?: boolean;
  fairMarketValue?: string;
  endDate?: string;
  excludeFromRmd?: boolean;
  termChoice?: TermChoiceOption;
}

export interface DistributionSearchResult extends Distribution {
  accountId?: string;
  accountNumber?: string;
  accountOwnerFullName?: string;
  accountOwnerId?: string;
  accountOwnerTaxpayerId?: string;
  accountType?: AccountType;
}

export interface DistributionCalculateParams {
  additionalStateWithholding?: string;
  federalWithholdingPercent: string | number;
  netAmount?: string;
  penaltyAmount?: string;
  stateWithholdingPercent: string | number;
  totalAmount: string;
}

export interface DistributionAllocationResponse {
  data?: Array<DistributionAllocation>;
  errorMessage?: any;
  status?: number;
}

export interface DistributionAllocation {
  amount?: string | number;
  distributionAllocationId?: number;
  distributionId?: number;
  investmentRateId?: string | number;
  investmentNumber?: string;
  penaltyAmount?: string | number;
}

export interface WithholdingReqParams {
  distribution: string;
  filingStatus: string;
  income: string;
  taxYear: string;
}

export interface WithholdingResponse {
  data?: Withholding;
}

export interface Withholding {
  withholdingAmount: number;
  withholdingPercent: number;
}

export enum NiaOption {
  calculate = 'calculate',
  withoutCalculation = 'withoutCalculation',
}

export enum StateWithholdingPercentType {
  lessOrEqual = 'LESS_OR_EQUAL',
  greaterOrEqual = 'GREATER_OR_EQUAL',
  any = 'ANY',
  disabled = 'NO',
  zeroOrEqaul = 'ZERO_OR_EQUAL',
}

export interface StateWithholdingReqParams {
  state: string;
  federalWithholdingPercent: number | string;
  taxYear: string;
}

export interface StateWithholdingResponse {
  data?: StateWithholding;
}

export interface StateWithholding {
  canSpecifyAmount: boolean;
  canSpecifyPercent: StateWithholdingPercentType;
  percent: number;
  state: string;
  stateWithholdingId: number;
  taxYear: number;
  withholdingLanguage: string;
}

export enum DistributionStatus {
  active = 'ACTIVE',
  inActive = 'INACTIVE',
  signature = 'AWAITING_SIGNATURE',
  pending = 'PENDING',
  review = 'PENDING_REVIEW',
  submitOwner = 'SUBMITTED_BY_OWNER',
}

export enum DistributionStatusState {
  next = 'NEXT',
  previous = 'PREVIOUS',
}

export interface DistributionStatusResponse {
  data?: Distribution;
  errorMessage?: any;
}

export interface WorkflowDates {
  activeDate?: string;
  effectiveDate?: string;
  signedDate?: string;
}

export enum DistributionTransferType {
  internal = 'internal',
  external = 'external',
}

export enum DistributionReason {
  // Age-agnostic
  PROHIBITED = 'PROHIBITED',
  TRANSFER = 'TRANSFER',
  SAME_YEAR_RECHAR = 'SAME_YEAR_RECHAR',
  PRIOR_YEAR_RECHAR = 'PRIOR_YEAR_RECHAR',

  IRA_NORMAL = 'IRA_NORMAL',
  IRA_ROLLOVER = 'IRA_ROLLOVER',
  IRA_EARLY = 'IRA_EARLY',
  IRA_DISABILITY = 'IRA_DISABILITY',
  IRA_EARLY_IRS_LEVY = 'IRA_EARLY_IRS_LEVY',
  IRA_EARLY_ROTH_CONVERSION = 'IRA_EARLY_ROTH_CONVERSION',
  IRA_NORMAL_ROTH_CONVERSION = 'IRA_NORMAL_ROTH_CONVERSION',
  IRA_EARLY_FIRST_TWO_YEARS = 'IRA_EARLY_FIRST_TWO_YEARS',

  ROTH_QUALIFIED = 'ROTH_QUALIFIED',
  ROTH_EXCEPTION = 'ROTH_EXCEPTION',
  ROTH_SAME_YEAR_EXCESS = 'ROTH_SAME_YEAR_EXCESS',
  ROTH_PRIOR_YEAR_EXCESS = 'ROTH_PRIOR_YEAR_EXCESS',
  ROTH_OTHER_REVOCATION = 'ROTH_OTHER_REVOCATION',
  ROTH_REGULAR_REVOCATION = 'ROTH_REGULAR_REVOCATION',
  ROTH_REGULAR_REVOCATION_NO_EARNINGS = 'ROTH_REGULAR_REVOCATION_NO_EARNINGS',
  ROTH_CONVERSION_REVOCATION = 'ROTH_CONVERSION_REVOCATION',
  ROTH_IRA_CONVERSION = 'ROTH_IRA_CONVERSION',
  ROTH_EARLY_EXCEPTION = 'ROTH_EARLY_EXCEPTION',
  ROTH_EARLY_NO_EXCEPTION = 'ROTH_EARLY_NO_EXCEPTION',

  ESA_NORMAL = 'ESA_NORMAL',
  ESA_TRANSFER = 'ESA_TRANSFER',
  ESA_TRANSFER_NEW_BENEFICIARY = 'ESA_TRANSFER_NEW_BENEFICIARY',
  ESA_DISABILITY = 'ESA_DISABILITY',
  ESA_PROHIBITED = 'ESA_PROHIBITED',
  ESA_SAME_YEAR_EXCESS = 'ESA_SAME_YEAR_EXCESS',
  ESA_PRIOR_YEAR_EXCESS = 'ESA_PRIOR_YEAR_EXCESS',
  ESA_DEATH_BENE_DIST = 'ESA_DEATH_BENE_DIST',

  HSA_NORMAL = 'HSA_NORMAL',
  HSA_EXCESS_BEFORE_DEADLINE = 'HSA_EXCESS_BEFORE_DEADLINE',
  HSA_DISABILITY = 'HSA_DISABILITY',
  HSA_PROHIBITED = 'HSA_PROHIBITED',
  HSA_DEATH_DIST_SPOUSE = 'HSA_DEATH_DIST_SPOUSE',
  HSA_DEATH_DIST_NON_SPOUSE = 'HSA_DEATH_DIST_NON_SPOUSE',
  HSA_DEATH_DIST_ESTATE = 'HSA_DEATH_DIST_ESTATE',
  HSA_DEATH_DIST_OTHER = 'HSA_DEATH_DIST_OTHER',
  HSA_TRANSFER = 'HSA_TRANSFER',

  SIMPLE_IRA_EARLY = 'IRA_EARLY',
  IRA_SEP_SIMPLE_EXCESS = 'IRA_SEP_SIMPLE_EXCESS',

  // 'Q' Prefix = Over 59.5
  Q_IRA_OTHER_REVOCATION = 'Q_IRA_OTHER_REVOCATION',
  Q_IRA_REGULAR_REVOCATION = 'Q_IRA_REGULAR_REVOCATION',
  Q_IRA_SAME_YEAR_EXCESS = 'Q_IRA_SAME_YEAR_EXCESS',
  Q_IRA_PRIOR_YEAR_EXCESS = 'Q_IRA_PRIOR_YEAR_EXCESS',
  Q_IRA_EXCESS_AFTER_DEADLINE = 'Q_IRA_EXCESS_AFTER_DEADLINE',
  Q_ROTH_EXCESS_AFTER_DEADLINE_EXCEPTION = 'Q_ROTH_EXCESS_AFTER_DEADLINE_EXCEPTION',
  Q_ROTH_EXCESS_AFTER_DEADLINE_QUALIFIED = 'Q_ROTH_EXCESS_AFTER_DEADLINE_QUALIFIED',

  // 'E' Prefix = Under 59.5
  E_IRA_SAME_YEAR_EXCESS = 'E_IRA_SAME_YEAR_EXCESS',
  E_IRA_PRIOR_YEAR_EXCESS = 'E_IRA_PRIOR_YEAR_EXCESS',
  E_IRA_EXCESS_AFTER_DEADLINE = 'E_IRA_EXCESS_AFTER_DEADLINE',
  E_ROTH_EXCESS_AFTER_DEADLINE_EXCEPTION = 'E_ROTH_EXCESS_AFTER_DEADLINE_EXCEPTION',
  E_ROTH_EXCESS_AFTER_DEADLINE = 'E_ROTH_EXCESS_AFTER_DEADLINE',
  E_ROTH_EXCESS_AFTER_DEADLINE_QUALIFIED = 'E_ROTH_EXCESS_AFTER_DEADLINE_QUALIFIED',
  E_IRA_OTHER_REVOCATION = 'E_IRA_OTHER_REVOCATION',
  E_IRA_REGULAR_REVOCATION = 'E_IRA_REGULAR_REVOCATION',
  E_IRA_REGULAR_REVOCATION_NO_EARNINGS = 'E_IRA_REGULAR_REVOCATION_NO_EARNINGS',

  // 'I' Prefix = Inherited
  I_IRA_DEATH = 'I_IRA_DEATH',
  I_IRA_SAME_YEAR_EXCESS = 'I_IRA_SAME_YEAR_EXCESS',
  I_IRA_PRIOR_YEAR_EXCESS = 'I_IRA_PRIOR_YEAR_EXCESS',
  I_IRA_EXCESS_AFTER_DEADLINE = 'I_IRA_EXCESS_AFTER_DEADLINE',
}

export enum DistributionMethod {
  default = '',
  cash = 'CASH',
  check = 'CHECK',
  direct = 'DIRECT_DEPOSIT',
  transfer = 'TRANSFER',
}

export enum BeneWithholdingDistributitionOption{

  default = '',
  stateWitholdingsepform = 'STATE_WITHHOLDING_ON_SEPARATE_FORM',
  stateWitholding = 'STATE_WITHHOLDING_ON_FORM',
  noStateWithholding = 'NO_STATE_WITHHOLDING',
}


export enum BeneDistributionElectionSub {
  default = '',
  preRBD = 'PRE_RBD',
  postRBD = 'POST_RBD',
}

export enum BeneDistributionElectionSubText { 
  default = '',
  subtextWithRMD = 'SUBTEXT_WITH_RMD',
  subtextWithoutRMD = 'SUBTEXT_WITHOUT_RMD',
}

export enum BeneDistributionElectionTransfers {
  default = '',
  totalDistribution = 'TOTAL_DISTRIBUTION',
  transferToInheritedIraPayoutSchedule = 'TRANSFER_TO_INHERITED_PAYOUT_SCHEDULE',
  transferToInheritedLifeExpectancyPayoutSchedule = 'TRANSFER_TO_INHERITED_LIFE_EXPECTANCY',
  transferToInheritedFinOrg = 'TRANSFER_TO_INHERITED_FIN_ORG',
  transferToOwn = 'TRANSFER_TO_OWN',
  transferToOwnFinOrg = 'TRANSFER_TO_OWN_FIN_ORG',
}

export enum SubTextInherited {
  subtextWithRMD='SUBTEXT_WITH_RMD',
  subtextWithoutRMD='SUBTEXT_WITHOUT_RMD',
}


export interface NetIncomeAttributable {
  netIncomeAttributable: number;
  totalToWithdraw: number;
}

export interface NetIncomeAttributableResponse {
  data?: NetIncomeAttributable;
  errorMessage?: any;
}

export enum FederalTaxElectionType {
  current = 'CURRENT_ELECTION',
  suggested = 'SUGGESTED',
  none = 'DO_NOT_WITHHOLD',
}

export interface NetIncomeAttributableRequest {
  niaExcessContribution?: number | string;
  niaPreContributionAccountBalance?: number | string;
  niaCurrentAccountBalance?: number | string;
  niaTotalContributionsDuringComputationPeriod?: number | string;
  niaTotalDistributionsDuringComputationPeriod?: number | string;
}

export interface BeneDistributionElection{
  termLength?: number;
  transferToInheritedIra: Array<BeneDistributionElectionTransfers>;
  transferRBD: BeneDistributionElectionSub;
  transferRBDPayoutSchedule: BeneDistributionElectionSub;
  transferRMDSubText: BeneDistributionElectionSubText;
  signatureLabel?: string;
  optionalSignatureLabel?: string;
  notary?: boolean;
  disabilityCertification?: boolean;
  distributionMethod?: Array<DistributionMethod>;
  selectedDistOptions?: Array<{
    optionSelected: BeneDistributionElectionTransfers;
    subOptionSelected: any;
  }>;

}
