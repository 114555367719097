import { AccountType } from "./AccountApi.d";

export interface BatchInfoResponse {
  data: Array<BatchInfo>;
  error: any;
}

export interface BatchTimestamp {
  date: number;
  day: number;
  hours: number;
  minutes: number;
  month: number;
  nanos: number;
  seconds: number;
  time: number;
  timezoneOffset: number;
  year: number;
}

export enum BatchItemStatus {
  completed = 'COMPLETED',
  failed = 'FAILED',
  processing = 'PROCESSING',
  overrideable = 'FAILED_OVERRIDABLE',
}

export enum BatchStatus {
  completed = 'COMPLETED',
  userCompleted = 'COMPLETED_BY_USER',
  errors = 'COMPLETED_WITH_ERRORS',
  processing = 'PROCESSING',
  reProcessing = 'REPROCESSING',
}

export enum BatchType {
  users = 'USER_INSERT',
  accounts = 'ACCOUNT_OWNER_ACCOUNT_UPSERT',
  accountOwnerUpdate = 'ACCOUNT_OWNER_UPDATE',
  deposit = 'CONTRIBUTION_INSERT',
  distribution = 'DISTRIBUTION_UPSERT',
  recurringDistribution = 'RECURRING_DIST_INSERT',
  taxReport5498 = 'TAX_REPORTING_5498',
  taxReport1099 = 'TAX_REPORTING_1099',
  fairMarketValue = 'FMV_INSERT',
}

export interface BatchItem {
  batchId: string | number;
  batchItemAddedTimestamp: BatchTimestamp;
  batchItemId: string | number;
  batchItemProcessedTimestamp: BatchTimestamp;
  batchItemStatus: BatchItemStatus;
  batchItemObject: any; // Dynamic-could be account, user, accountOwner, etc.
  failureResponseMessage: string;
  financialOrganizationId: string | number;
}

export interface BatchInfo {
  batchAddedTimestamp: BatchTimestamp;
  batchId: string | number;
  batchItemList: Array<BatchItem>;
  batchRecordCount: number;
  batchStatus: BatchStatus;
  batchType: BatchType;
  fileName: string;
  financialOrganizationId: string | number;
  createdCount: number;
  updatedCount: number;
  completedCount: number;
  batchCompletedTimestamp: BatchTimestamp;
}

export interface TaxRepotingImportStatusDetails {
  id: number;
  irsTaxFileUploadId: number;
  record: string;
  errorDescription: string;
  data: string;
  matching: boolean;
}

export interface TaxReportingImportStatusResponse {
  id: number;
  financialOrganizationId: number;
  fileName: string;
  s3FilePath: string;
  createdBy: number;
  created: string;
  details: Array<TaxRepotingImportStatusDetails>;
  status: BatchStatus;
}

export interface AccountDetails{
    id: number,
    matching: boolean,
    field: string
    superiorValue: string,
    fileValue: string,
    irsTaxFileUploadId: string;
    record: string;
    data: string;
    accountOwnerName: string;
    accountType: AccountType;
    accountOwnerId: number;
    accountId:number;
    sequenceNumber?: number;
    errorDescription?: string;
    errorType?: string;
    distributionReason?: string;
}

export interface TaxRepotingImportDetails {
  id: number,
  matching: boolean,
  field: string
  superiorValue: string,
  fileValue: string,
  irsTaxFileUploadId: string;
  record: string;
  data: string;
  accountOwnerName: string;
  accountType: AccountType;
  accountOwnerId: number;
  accountId:number;
  sequenceNumber?: number;
  errorDescription?: string;
  errorType?: string;
  distributionReason?: string;
}


export interface TaxResult {
  createById: number;
  createdByName?: string;
  created: string;
  fileName: string;
  filePath: string;
  irsTaxFileUploadId: string;
  fileUploadDetails: Array<TaxRepotingImportDetails>;
}