import React, { ReactChild, useState } from 'react';
import { Box, IconButton, StandardTextFieldProps, TextField } from '@mui/material';
import { FieldAttributes, useField } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface SiraTextFieldProps extends StandardTextFieldProps {
  maxLength?: number;
  afterContent?: ReactChild;
  height?: string;
}

const SiraTextField: React.FC<FieldAttributes<SiraTextFieldProps>> = ({
  afterContent,
  maxLength,
  type,
  height,
  ...textFieldProps
}) => {
  const [field, meta, helpers] = useField<{}>(textFieldProps);
  const [showPassword, setShowPassword] = useState<boolean>(
    type !== 'password'
  );
  const [focus, setfocus] = useState(false);

  const errorText = meta.error && meta.touched ? meta.error : '';

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const trimmedValue = !showPassword ? event.target.value.trim() : event.target.value;
    helpers.setValue(trimmedValue);
    field.onBlur(event); // Call the original onBlur handler
    setfocus(false);
  };

  return (
    <>
    <TextField
      InputProps={{
        endAdornment: type === 'password' && (
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        ),
        style: { height: height},
        
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        maxLength,
        'data-qa': field.name, // This is the data-qa attribute
      }}
      helperText={errorText}
      error={Boolean(errorText)}
      variant="outlined"
      fullWidth
      autoComplete="off"
      type={type === 'password' && showPassword ? 'text' : type}
      data-qa={field.name}
      {...field}
      {...textFieldProps}
      onBlur={handleBlur} // This is the onBlur attribute
      onFocus={() => setfocus(true)}      
    />
    {focus &&  <Box>{afterContent}</Box>}
</>
  );
};

export default SiraTextField;
