import React, { Profiler } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import queryString from 'query-string';
import { LicenseInfo } from '@mui/x-license-pro';
import { createRoot } from 'react-dom/client';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SessionTimeoutProvider } from './utils/SessionTimeout';

LicenseInfo.setLicenseKey(
  'ea4e915a615397a7ff95e98e4795fdefTz05MTMxNixFPTE3NDgyMDk3NzQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

function onRenderCallback(
  id: string,
  phase: 'mount' | 'update',
  actualDuration: number,
  baseDuration: number,
  startTime: number,
  commitTime: number,
) {

}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Profiler id="App" onRender={onRenderCallback}>
    <SessionTimeoutProvider>
      <App />{' '}
    </SessionTimeoutProvider>
  </Profiler>
  );

if (process.env.NODE_ENV !== 'production') {
  if ((module as any).hot) {
    (module as any).hot.accept();
  }
}

// Set up query param arrays in a way Spring accepts them
// e.g. default: ?thing[]=foo&thing[]=bar => ?thing=foo&thing=bar
axios.interceptors.request.use((config) => ({
  ...config,
  paramsSerializer: (params) =>
    queryString.stringify(params, { arrayFormat: 'none' }),
}));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
