import { FrequencyValues } from '../components/form/distribution/Frequency.d';
import { AccountType } from './AccountApi.d';
import { DistributionMethod, DistributionReason } from './DistributionApi.d';

export interface OrganizationResponse {
  data?: Organization;
  errorMessage?: any;
}

export interface OrganizationEsignSettingsResponse {
  data?: Array<OrganizationEsign>;
  errorMessage?: any;
}

export interface OrganizationSearchResponse {
  data: Array<Organization>;
  errorMessage?: any;
  query?: string;
}

export enum FiduciaryType {
  default = '',
  custodian = 'CUSTODIAN',
  trustee = 'TRUSTEE',
}

export enum SpousalConsentOption {
  all = 'ALL_STATES',
  community = 'COMMUNITY_OR_MARITAL_PROPERTY_STATES',
}

export enum AccountMemberValue {
  accountNumber = 'ACCOUNT_NUMBER',
  memberNumber = 'MEMBER_NUMBER',
}

export enum SingelSignOn{
  none = 'NONE',
  microsoftAzureAD = 'MICROSOFT_AZURE_AD',
  ping= 'PING',
  saml = 'SAML',
  openID = 'OPEN_ID',
  okta = 'OKTA',
  google = 'GOOGLE',
  adfs= 'ADFS',
  adLadp = 'AD_LDAP',
}

export enum CoreIntegration{
  symitar = 'SYMITAR',
}

export interface Organization {
  planNumberEnabled?: boolean;
  displayInvestments?: boolean;
  displayFeeAmount?: boolean;
  branches?: Array<Branch>;
  federalTaxId?: string;
  fiduciaryType?: FiduciaryType;
  financialOrganizationId?: string;
  legalName?: string;
  name?: string;
  stateTaxIDS?: Array<StateTaxId>;
  logoImageString?: string;
  logoUrl?: string;
  accountTypesSupported?: Array<AccountType>;
  spousalConsentRequired?: SpousalConsentOption;
  primaryColorRGB?: string;
  secondaryColorRGB?: string;
  primaryTextRGB?: string;
  secondaryTextRGB?: string;
  solution?: Solution;
  agreementDate?: string;
  defaultRMD?: DefaultRmd;
  distributionMethodsSupported?: Array<DistributionMethod>;
  documentationAddress?: DocumentationAddress;
  investmentRatePrecision?: number;
  eSign?: boolean;
  eSignWithSuperior?: boolean;
  eSignApiKey?: string;
  eSignBaseUrl?: string;
  isEsignPassthroughId?: boolean;
  eSignPassthroughId?: boolean;
  esignDefaultAppUserId?: string;
  esignUserId?: string;
  esignHostFiid?: string;
  eSignLandingPage?: ESignLandingPage;
  autoScheduledDistributionsEnabled?: boolean;
  withholdingNotificationsEnabled?: boolean;
  rmdNotificationsEnabled?: boolean;
  immField?: string;
  superiorValue?: string;
  superiorField?: string;
  immFieldMappingId?: {
    superiorValue?: string;
    financialOrganizationId?: number;
  };
  accountNumberValue?: AccountMemberValue;
  accountNumberChecked?: boolean;
  orgTransactionEmailSetting?: TransactionEmailSettings;
  apyRatePrecision?: number;
  customerPortalUrl?: string;
  sftpFolderName?: string;
  agreementDate?: string;
  activeDate?: string;
  status?: string;
  sso?: SingelSignOn;
  ipFiltering?: boolean;  
  supportedAccountTypes?: Array<AccountType>
  accountOwnerPortalCustomError?: string;
  accountOwnerPortalCustomErrorHeader?: string;
  coreIntegration?: string;
  beneyClaimSpecialHandling?: string;
}

export interface OrganizationEsign {
  id?: number;
  immFieldMappingId?: {
    superiorValue?: string;
    financialOrganizationId?: number;
  };
  immField?: string;
  superiorValue?: string;
  superiorField?: string;
}
export enum TaxFileReportingPreference {
  spreadsheet = 'SPREADSHEET',
  taxFile = 'IRS_TAX_FILE',
}

export interface OrganizationTaxReporting{
  printableTaxFormType: string;
  isEnabled: boolean;
  reportingFilePreference: TaxFileReportingPreference;
} 
export interface OrganizationTaxReportingSettings {
  TAX_FORM_1099R?: boolean;
  TAX_FORM_1099SA?: boolean;
  TAX_FORM_1099Q?: boolean;
  TAX_FORM_5498?: boolean;
  TAX_FORM_5498SA?: boolean;
  TAX_FORM_5498ESA?: boolean;
  taxYear?: number;
  switchValue?: boolean;
  reportingFilePreference?: TaxFileReportingPreference;
  reportingFilePreference1099R?: TaxFileReportingPreference;
  reportingFilePreference1099SA?: TaxFileReportingPreference;
  reportingFilePreference1099Q?: TaxFileReportingPreference;
  reportingFilePreference5498?: TaxFileReportingPreference;
  reportingFilePreference5498SA?: TaxFileReportingPreference;
  reportingFilePreference5498ESA?: TaxFileReportingPreference;
  
}

export interface OrganizationTaxReportingSettingsResponse {
  data: Array<OrganizationTaxReporting>;
  errorMessage?: any;
}

export interface BranchResponse {
  data: Array<Branch>;
  errorMessage?: any;
}

export interface Branch {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
  zipPlus4?: string;
  branchId?: string;
  name?: string;
  primaryBranch?: boolean;
  phoneNumber?: string;
  emailAddress?: string;
}

export interface StateTaxId {
  state: string;
  taxId: string;
  stateWithholding: boolean;
  create4P: boolean;
}

export interface SupportedAccountType {
  supportedAccountType: AccountType;
}

export interface FinOrgDoc {
  key: string;
  lastModified: string;
  documentId: string;
  size: number;
  ownerDisplayName: string;
  ownerID: string;
  description?: string;
}

export enum DocumentationAddress {
  userBranch = 'USER_BRANCH',
  primaryBranch = 'PRIMARY_BRANCH',
}

// API preferred an enum in this case for simplicity on their end
// This may eventually go away in favor of booleans
export enum IncludeWithholdingCert {
  default = 'NA',
  true = 'TRUE',
  false = 'FALSE',
}

export enum Solution {
  silver = 'SILVER',
  green = 'GREEN',
  black = 'BLACK',
}

export enum EsignLandingPage {
  eSignManger = 'ESIGN_MANAGER',
  docqDetails = 'DOCQ_DETAILS',
}

export interface StateTaxIdResponse {
  data: StateTaxId;
  errorMessage?: any;
}

export interface FinOrgDocResponse {
  data: Array<FinOrgDoc>;
  errorMessage?: any;
}

export interface OrgDocData {
  file: File;
  description: string;
}
export interface DefaultRmd {
  age72FirstRmdFrequency?: FrequencyValues;
  age72FirstRmdStartDate?: string;
  age72SubsequentRmdFrequency?: FrequencyValues;
  age72SubsequentRmdStartDate?: string;
  age73CurrentRmdFrequency?: FrequencyValues;
  age73CurrentRmdStartDate?: string;
  age73SubsequentRmdFrequency?: FrequencyValues;
  age73SubsequentRmdStartDate?: string;
  age72FirstRmdIsFollowingYear?: boolean;
  defaultRmdId?: string;
  ownerResponsible?: boolean;
  distributionMethod?: DistributionMethod;
  electionDeadline?: number;
  toAccountType?: AccountType;
}

export interface CustomDistributionCodeDTO {
  distributionReason: DistributionReason;
  distributionCode: string;
  displayName: string;
  reportingCode: string;
}

export enum TransactionEmailSettings {
  immediately = 'IMMEDIATELY',
  daily = 'DAILY',
  weekly = 'WEEKLY',
  none = 'NONE',
}

export interface CustomDistributionCodeResponse {
  data: Array<CustomDistributionCodeDTO>;
  errorMessage?: any;
}
