/* eslint-disable no-script-url */
import React from 'react';
import { Typography } from '@mui/material';
import { Field } from 'formik';
import { distributionReasonHelpText } from './resource.txt';
// eslint-disable-next-line import/no-cycle
import {
  DistributionCodeGroup,
  DistributionCode,
} from './DistributionCodeForm.d';
import { DistributionReason } from '../../../api/DistributionApi.d';
import { AccountType } from '../../../api/AccountApi.d';
import DistributionTransferESA from './DistributionTransferToESA';
import SiraRadioField from '../SiraRadioField';

// Distribution reasons that carry the possibility of income on contributions above the max
export const netIncomeAttributableReasons = {
  RECHAR: [
    DistributionReason.SAME_YEAR_RECHAR,
    DistributionReason.PRIOR_YEAR_RECHAR,
  ],
  EXCESS: [
    DistributionReason.ESA_SAME_YEAR_EXCESS,
    DistributionReason.ESA_PRIOR_YEAR_EXCESS,
    DistributionReason.HSA_EXCESS_BEFORE_DEADLINE,
    DistributionReason.E_IRA_SAME_YEAR_EXCESS,
    DistributionReason.E_IRA_PRIOR_YEAR_EXCESS,
    DistributionReason.E_ROTH_EXCESS_AFTER_DEADLINE_EXCEPTION,
    DistributionReason.E_ROTH_EXCESS_AFTER_DEADLINE_QUALIFIED,
    DistributionReason.Q_IRA_SAME_YEAR_EXCESS,
    DistributionReason.Q_IRA_PRIOR_YEAR_EXCESS,
    DistributionReason.Q_ROTH_EXCESS_AFTER_DEADLINE_EXCEPTION,
    DistributionReason.Q_ROTH_EXCESS_AFTER_DEADLINE_QUALIFIED,
    DistributionReason.IRA_SEP_SIMPLE_EXCESS,
    DistributionReason.ROTH_SAME_YEAR_EXCESS,
    DistributionReason.ROTH_PRIOR_YEAR_EXCESS,
    DistributionReason.I_IRA_SAME_YEAR_EXCESS,
    DistributionReason.I_IRA_PRIOR_YEAR_EXCESS,
  ],
  REGULARREVOC: [
    DistributionReason.E_IRA_REGULAR_REVOCATION,
    DistributionReason.ROTH_REGULAR_REVOCATION,
    DistributionReason.Q_IRA_REGULAR_REVOCATION,
    DistributionReason.E_IRA_REGULAR_REVOCATION_NO_EARNINGS,
    DistributionReason.ROTH_REGULAR_REVOCATION_NO_EARNINGS,
  ],
  CONVERSIONREVOC: [DistributionReason.ROTH_OTHER_REVOCATION],
};

export function getDistributionCodeOptions(
  accountType: AccountType,
  isEarlyDistribution: boolean,
  isExcludeRmd: boolean,
) {
  const isInherited = [
    AccountType.inheritedTraditional,
    AccountType.inheritedRoth,
  ].includes(accountType);

  const excludeRMDValue = [AccountType.traditionalIra, AccountType.traditionalSep, AccountType.simpleIra].includes(accountType) && isExcludeRmd;

 const rmdOptions = [
    {
      value: 'doNotExcludeFromRmd',
      label: 'Reduce remaining required minimum distributions (RMDs) for the current year by the amount of this distribution',
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.reduceRemainingRMDs}
        </Typography>
      ),
    },  {
      value: 'excludeFromRmd',
      label: 'Do not reduce remaining RMDs for the current year by the amount of this distribution.',
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.doNotReduceRemainingRMDs}
        </Typography>
      ),
    }, 
  
  ];
  const distributionOptions = {
    // NORMAL
    IRA_NORMAL: {
      value: DistributionReason.IRA_NORMAL,
      label: 'Normal distribution',
      group: DistributionCodeGroup.default,
      afterContent: !excludeRMDValue ? (
        <Typography variant="body2">
          {distributionReasonHelpText.normalDistribution}
        </Typography>
      ):(
        <Field
        name="rmdOptions"
        options={rmdOptions}
        component={SiraRadioField}
      />
      ),
    },
    ESA_NORMAL: {
      value: DistributionReason.ESA_NORMAL,
      label: 'Normal distribution',
      group: DistributionCodeGroup.default,
    },
    HSA_NORMAL: {
      value: DistributionReason.HSA_NORMAL,
      label: 'Normal distribution',
      group: DistributionCodeGroup.default,
    },
    ROTH_QUALIFIED: {
      value: DistributionReason.ROTH_QUALIFIED,
      label: 'Qualified distribution',
      group:
        isEarlyDistribution && !isInherited
          ? DistributionCodeGroup.qualified
          : DistributionCodeGroup.default,
      afterContent: isEarlyDistribution ? (
        <Typography variant="body2">
          {distributionReasonHelpText.rothEarlyQaulifiedHelpText}
        </Typography>
      ) : (
        <Typography variant="body2">
          {distributionReasonHelpText.rothQaulifiedHelpText}
        </Typography>
      ),
    },
    ROTH_EXCEPTION: {
      value: DistributionReason.ROTH_EXCEPTION,
      label:
        isEarlyDistribution && !isInherited
          ? 'due to disability but the five-year holding period has not been met, or due to substantially equal periodic payments'
          : 'Distribution with exception',
      group:
        isEarlyDistribution && !isInherited
          ? DistributionCodeGroup.earlyPenalty
          : DistributionCodeGroup.default,
      afterContent: isEarlyDistribution ? (
        <Typography variant="body2">
          {distributionReasonHelpText.dueToDisability5yrHldingPnotMet}
        </Typography>
      ) : (
        <Typography variant="body2">
          {distributionReasonHelpText.rothQaulifiedDistributionWexception}
        </Typography>
      ),
    },

    // TRANSFER
    IRA_TRANSFER: {
      value: DistributionReason.TRANSFER,
      label: 'Transfer to another IRA',
      group: DistributionCodeGroup.default,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.transferToAnotherIRA}
        </Typography>
      ),
    },
    ESA_TRANSFER: {
      value: DistributionReason.ESA_TRANSFER,
      label: 'Transfer to another ESA with the same designated beneficiary ',
      group: DistributionCodeGroup.esaTransfer,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.transferToAnotherESASame}
        </Typography>
      ),
    },
    // switch to modal
    ESA_TRANSFER_NEW_BENEFICIARY: {
      value: DistributionReason.ESA_TRANSFER_NEW_BENEFICIARY,
      label: 'Transfer to another ESA with a different designated beneficiary ',
      group: DistributionCodeGroup.esaTransfer,
      afterContent: <DistributionTransferESA />,
    },
    HSA_TRANSFER: {
      value: DistributionReason.HSA_TRANSFER,
      label: 'Transfer to another HSA',
      group: DistributionCodeGroup.default,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.transferToAnotherHSA}
        </Typography>
      ),
    },
    ROTH_TRANSFER: {
      value: DistributionReason.TRANSFER,
      label: 'Transfer to another Roth IRA',
      group: DistributionCodeGroup.default,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.transferToAnotherIRA}
        </Typography>
      ),
    },
    I_IRA_TRANSFER: {
      value: DistributionReason.TRANSFER,
      label: 'Transfer to another IRA',
      group: DistributionCodeGroup.default,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.transferInheritedTradIra}
        </Typography>
      ),
    },
    I_IRA_ROTH_TRANSFER: {
      value: DistributionReason.TRANSFER,
      label: 'Transfer to another IRA',
      group: DistributionCodeGroup.default,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.transferInheritedRothIra}
        </Typography>
      ),
    },
    I_IRA_DEATH: {
      value: DistributionReason.I_IRA_DEATH,
      label: 'Death distribution',
      group: DistributionCodeGroup.default,
      afterContent: 
        <Field
        name="rmdOptions"
        options={rmdOptions}
        component={SiraRadioField}
      />
    },

    // ROLLOVER
    IRA_ROLLOVER: {
      value: DistributionReason.IRA_ROLLOVER,
      label:
        AccountType.traditionalIra === accountType ||
        AccountType.traditionalSep === accountType
          ? 'to an employer-sponsored retirement plan'
          : 'to an employer-sponsored retirement plan after the two-year waiting period has been met',
      group: DistributionCodeGroup.rollover,
      afterContent: (
        <Typography variant="body2">
          {AccountType.traditionalIra === accountType ||
          AccountType.traditionalSep === accountType
            ? distributionReasonHelpText.directRollOverTradSepIRAEmpSponsored
            : distributionReasonHelpText.directRollOverSimpleIRAEmpSponsored}
        </Typography>
      ),
    },
    IRA_NORMAL_ROTH_CONVERSION: { 
      value: DistributionReason.IRA_NORMAL_ROTH_CONVERSION,
      label: 'Direct Roth IRA conversion',
      group: DistributionCodeGroup.iraconversion,
    },

    // EARLY
    IRA_EARLY: {
      value: DistributionReason.IRA_EARLY,
      label: 'Early distribution without penalty exception',
      group: DistributionCodeGroup.default,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.earlyDistributionwopenalty}
        </Typography>
      ),
    },
    SIMPLE_IRA_EARLY: {
      value: DistributionReason.IRA_EARLY,
      label:
        'Early distribution without penalty exception and the two-year waiting period has been met',
      group: DistributionCodeGroup.default,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.twoYearWaitingPeriod}
        </Typography>
      ),
    },
    IRA_DISABILITY: {
      value: DistributionReason.IRA_DISABILITY,
      label: 'due to disability',
      group: DistributionCodeGroup.early,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.dueToDisability}
        </Typography>
      ),
    },
    HSA_DISABILITY: {
      value: DistributionReason.HSA_DISABILITY,
      label:
        'The account owner meets the Internal Revenue Code definition of disability',
      group: DistributionCodeGroup.disability,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.dueToDisabilityHSA}
        </Typography>
      ),
    },
    ESA_DISABILITY: {
      value: DistributionReason.ESA_DISABILITY,
      label:
        'The designated beneficiary meets the Internal Revenue Code definition of disability',
      group: DistributionCodeGroup.disability,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.dueToDisabilityESA}
        </Typography>
      ),
    },
    IRA_EARLY_IRS_LEVY: {
      value: DistributionReason.IRA_EARLY_IRS_LEVY,
      label: 'due to substantially equal periodic payments or IRS Levy',
      group: DistributionCodeGroup.early,
      afterContent:
        AccountType.simpleIra === accountType ? (
          <Typography variant="body2">
            {distributionReasonHelpText.dueToSubstantialEqualPpayIRSLevySIMP}
          </Typography>
        ) : (
          <Typography variant="body2">
            {distributionReasonHelpText.dueToSubstantialEqualPpayIRSLevy}
          </Typography>
        ),
    },
    IRA_EARLY_ROTH_CONVERSION: {
      value: DistributionReason.IRA_EARLY_ROTH_CONVERSION,
      label: 'due to direct Roth IRA conversion',
      group: DistributionCodeGroup.early,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.dueToDirectRothIRAConversion}
        </Typography>
      ),
    },
    ROTH_EARLY_EXCEPTION: {
      value: DistributionReason.ROTH_EARLY_EXCEPTION,
      label: 'due to IRS levy',
      group: DistributionCodeGroup.earlyPenalty,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.dueToIRSLevy}
        </Typography>
      ),
    },
    ROTH_EARLY_NO_EXCEPTION: {
      value: DistributionReason.ROTH_EARLY_NO_EXCEPTION,
      label: 'Early distribution without penalty exception',
      group: DistributionCodeGroup.default,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.rothEarlyDistributionwopenalty}
        </Typography>
      ),
    },
    IRA_EARLY_FIRST_TWO_YEARS: {
      value: DistributionReason.IRA_EARLY_FIRST_TWO_YEARS,
      label:
        'Early distribution without penalty exception and the two-year waiting period has not been met',
      group: DistributionCodeGroup.default,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.twoYearWaitingPeriod}
        </Typography>
      ),
    },

    // PROHIBITED
    PROHIBITED: {
      value: DistributionReason.PROHIBITED,
      label:
        'Of any of the following that occurred between the IRA and a disqualified person that is prohibited by law:',
      group: DistributionCodeGroup.prohibited,
      afterContent: (
        <Typography variant="body2" sx={{ paddingLeft: 5 }}>
          <li>the sale, exchange, or leasing of any property;</li>
          <li>the lending of money or other extension of credit;</li>
          <li>the furnishing of goods, services, or facilities</li>
          <li>
            the transfer of the IRA income or assets to, or use by or for the
            benefit of, a disqualified person
          </li>
          <li>
            any act by a fiduciary whereby the IRA income or assets are used for
            the person’s own interest; and
          </li>
          <li>
            the receipt of consideration by fiduciary for the person’s own
            account from any party dealing with the IRA in a transaction that
            involves IRA income or assets.
          </li>
        </Typography>
      ),
    },
    ESA_PROHIBITED: {
      value: DistributionReason.ESA_PROHIBITED,
      label:
        'Of any of the following that occurred between the ESA and a disqualified person that is prohibited by law:',
      group: DistributionCodeGroup.esaprohibited,
      afterContent: (
        <Typography variant="body2" sx={{ paddingLeft: 5 }}>
          <li>the sale, exchange, or leasing of any property;</li>
          <li>the lending of money or other extension of credit;</li>
          <li>the furnishing of goods, services, or facilities;</li>
          <li>
            the transfer of the ESA income or assets to, or use by or for the
            benefit of, a disqualified person;
          </li>
          <li>
            any act by a fiduciary whereby the ESA income or assets are used for
            the person’s own interest; and
          </li>
          <li>
            the receipt of consideration by fiduciary for the person’s own
            account from any party dealing with the ESA in a transaction that
            involves ESA income or assets.
          </li>
        </Typography>
      ),
    },
    HSA_PROHIBITED: {
      value: DistributionReason.PROHIBITED,
      label:
        'Of any of the following that occurred between the HSA and a disqualified person that is prohibited by law:',
      group: DistributionCodeGroup.hsaprohibited,
      afterContent: (
        <Typography variant="body2" sx={{ paddingLeft: 5 }}>
          <li>the sale, exchange, or leasing of any property;</li>
          <li>the lending of money or other extension of credit;</li>
          <li>the furnishing of goods, services, or facilities;</li>
          <li>
            the transfer of the HSA income or assets to, or use by or for the
            benefit of, a disqualified person;
          </li>
          <li>
            any act by a fiduciary whereby the HSA income or assets are used for
            the person’s own interest; and
          </li>
          <li>
            the receipt of consideration by fiduciary for the person’s own
            account from any party dealing with the HSA in a transaction that
            involves HSA income or assets.
          </li>
        </Typography>
      ),
    },

    // EXCESS
    HSA_EXCESS_BEFORE_DEADLINE: {
      value: DistributionReason.HSA_EXCESS_BEFORE_DEADLINE,
      label: 'before the excess removal deadline',
      group: DistributionCodeGroup.hsaexcess,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.hsaBeforeRemovalExcess}
        </Typography>
      ),
    },
    Q_IRA_SAME_YEAR_EXCESS: {
      value: DistributionReason.Q_IRA_SAME_YEAR_EXCESS,
      label:
        'in the same year the deposit was made, before the excess removal deadline',
      group: DistributionCodeGroup.excess,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.removalOfExcessContributionSame}
        </Typography>
      ),
    },
    E_IRA_SAME_YEAR_EXCESS: {
      value: DistributionReason.E_IRA_SAME_YEAR_EXCESS,
      label:
        'in the same year the deposit was made, before the excess removal deadline',
      group: DistributionCodeGroup.excess,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.removalOfExcessContributionSame}
        </Typography>
      ),
    },
    E_IRA_PRIOR_YEAR_EXCESS: {
      value: DistributionReason.E_IRA_PRIOR_YEAR_EXCESS,
      label:
        'in the year after the deposit was made, before the excess removal deadline',
      group: DistributionCodeGroup.excess,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.removalOfExcessContributionPrior}
        </Typography>
      ),
    },
    Q_IRA_PRIOR_YEAR_EXCESS: {
      value: DistributionReason.Q_IRA_PRIOR_YEAR_EXCESS,
      label:
        'in the year after the deposit was made, before the excess removal deadline',
      group: DistributionCodeGroup.excess,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.removalOfExcessContributionPrior}
        </Typography>
      ),
    },
    ESA_SAME_YEAR_EXCESS: {
      value: DistributionReason.ESA_SAME_YEAR_EXCESS,
      label:
        'in the same year the deposit was made, before the excess removal deadline',
      group: DistributionCodeGroup.esaexcess,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.esaBeforeRemovalExcess}
        </Typography>
      ),
    },
    ESA_PRIOR_YEAR_EXCESS: {
      value: DistributionReason.ESA_PRIOR_YEAR_EXCESS,
      label:
        'in the year after the deposit was made, before the excess removal deadline',
      group: DistributionCodeGroup.esaexcess,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.esaBeforeRemovalExcess}
        </Typography>
      ),
    },
    ROTH_SAME_YEAR_EXCESS: {
      value: DistributionReason.ROTH_SAME_YEAR_EXCESS,
      label:
        'in the same year the deposit was made, before the excess removal deadline',
      group: DistributionCodeGroup.excess,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.removalOfExcessContributionPrior}
        </Typography>
      ),
    },
    ROTH_PRIOR_YEAR_EXCESS: {
      value: DistributionReason.ROTH_PRIOR_YEAR_EXCESS,
      label:
        'in the year after the deposit was made, before the excess removal deadline',
      group: DistributionCodeGroup.excess,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.removalOfExcessContributionPrior}
        </Typography>
      ),
    },
    Q_IRA_EXCESS_AFTER_DEADLINE: {
      value: DistributionReason.Q_IRA_EXCESS_AFTER_DEADLINE,
      label: 'after the excess removal deadline',
      group: DistributionCodeGroup.excess,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.removalOfExcessContributionPrior}
        </Typography>
      ),
    },
    E_IRA_EXCESS_AFTER_DEADLINE: {
      value: DistributionReason.E_IRA_EXCESS_AFTER_DEADLINE,
      label: 'after the excess removal deadline',
      group: DistributionCodeGroup.excess,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.removalOfExcessContributionPrior}
        </Typography>
      ),
    },
    Q_ROTH_EXCESS_AFTER_DEADLINE_EXCEPTION: {
      value: DistributionReason.Q_ROTH_EXCESS_AFTER_DEADLINE_EXCEPTION,
      label:
        'after the excess removal deadline, but the five-year holding period has not been met',
      group: DistributionCodeGroup.excess,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.q_removalOfExcess5yrNotMet}
        </Typography>
      ),
    },
    Q_ROTH_EXCESS_AFTER_DEADLINE_QUALIFIED: {
      value: DistributionReason.Q_ROTH_EXCESS_AFTER_DEADLINE_QUALIFIED,
      label:
        'after the excess removal deadline, and the five-year holding period has been met',
      group: DistributionCodeGroup.excess,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.q_removalOfExcess5yr}
        </Typography>
      ),
    },
    E_ROTH_EXCESS_AFTER_DEADLINE: {
      value: DistributionReason.E_ROTH_EXCESS_AFTER_DEADLINE,
      label: 'after the excess removal deadline',
      group: DistributionCodeGroup.excess,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.removalOfExcessContributionPrior}
        </Typography>
      ),
    },
    IRA_SEP_SIMPLE_EXCESS: {
      value: DistributionReason.IRA_SEP_SIMPLE_EXCESS,
      label:
        'from a SEP or SIMPLE IRA under Employee Plans Compliance Resolution Systems (EPCRS)',
      group: DistributionCodeGroup.excess,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.tradSepSimpleIraEPCRS}
        </Typography>
      ),
    },
    I_IRA_SAME_YEAR_EXCESS: {
      value: DistributionReason.I_IRA_SAME_YEAR_EXCESS,
      label:
        'in the same year the deposit was made, before the excess removal deadline',
      group: DistributionCodeGroup.excess,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.excessSameInheritedTradIra}
        </Typography>
      ),
    },
    I_IRA_PRIOR_YEAR_EXCESS: {
      value: DistributionReason.I_IRA_PRIOR_YEAR_EXCESS,
      label:
        'in the year after the deposit was made, before the excess removal deadline',
      group: DistributionCodeGroup.excess,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.excessAfterInheritedTradIra}
        </Typography>
      ),
    },
    I_IRA_EXCESS_AFTER_DEADLINE: {
      value: DistributionReason.I_IRA_EXCESS_AFTER_DEADLINE,
      label: 'after the excess removal deadline',
      group: DistributionCodeGroup.excess,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.excessPriorInheritedTradIra}
        </Typography>
      ),
    },

    // RECHARACTERIZATION
    SAME_YEAR_RECHAR: {
      value: DistributionReason.SAME_YEAR_RECHAR,
      label: 'in the same year for which the deposit was made',
      group: DistributionCodeGroup.rechar,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.recharacterizationSame}
        </Typography>
      ),
    },
    PRIOR_YEAR_RECHAR: {
      value: DistributionReason.PRIOR_YEAR_RECHAR,
      label: 'in the year after the year for which the deposit was made ',
      group: DistributionCodeGroup.rechar,
      afterContent: (
        <Typography variant="body2">
          {distributionReasonHelpText.recharacterizationPrior}
        </Typography>
      ),
    },

    // REVOCATION
    E_IRA_OTHER_REVOCATION: {
      value: DistributionReason.E_IRA_OTHER_REVOCATION,
      label: 'of a rollover, transfer, SEP or SIMPLE IRA contribution',
      group: DistributionCodeGroup.revocation,
    },
    Q_IRA_OTHER_REVOCATION: {
      value: DistributionReason.Q_IRA_OTHER_REVOCATION,
      label: 'of a rollover, transfer, SEP or SIMPLE IRA contribution',
      group: DistributionCodeGroup.revocation,
    },
    Q_IRA_REGULAR_REVOCATION: {
      value: DistributionReason.Q_IRA_REGULAR_REVOCATION,
      label: 'of a regular contribution',
      group: DistributionCodeGroup.revocation,
    },
    E_IRA_REGULAR_REVOCATION: {
      value: DistributionReason.E_IRA_REGULAR_REVOCATION,
      label: 'of a regular contribution',
      group: DistributionCodeGroup.revocation,
    },
    ROTH_REGULAR_REVOCATION: {
      value: DistributionReason.ROTH_REGULAR_REVOCATION,
      label: 'of a regular contribution ',
      group: DistributionCodeGroup.revocation,
    },
    ROTH_OTHER_REVOCATION: {
      value: DistributionReason.ROTH_OTHER_REVOCATION,
      label:
        'of a conversion or rollover from employer-sponsored retirement plan',
      group: DistributionCodeGroup.revocation,
    },

    // BENEFICIARY DISTRIBUTION
    HSA_DEATH_DIST_SPOUSE: {
      value: DistributionReason.HSA_DEATH_DIST_SPOUSE,
      label: 'by spouse beneficiary taken at any time after death',
      group: DistributionCodeGroup.beneDistribution,
    },
    HSA_DEATH_DIST_NON_SPOUSE: {
      value: DistributionReason.HSA_DEATH_DIST_NON_SPOUSE,
      label: 'by estate or other beneficiary taken in the year of death',
      group: DistributionCodeGroup.beneDistribution,
    },
    HSA_DEATH_DIST_ESTATE: {
      value: DistributionReason.HSA_DEATH_DIST_ESTATE,
      label: 'by estate beneficiary taken after the year of death',
      group: DistributionCodeGroup.beneDistribution,
    },
    HSA_DEATH_DIST_OTHER: {
      value: DistributionReason.HSA_DEATH_DIST_OTHER,
      label: 'by other beneficiary taken after the year of death',
      group: DistributionCodeGroup.beneDistribution,
    },
    ESA_DEATH_BENE_DIST: {
      value: DistributionReason.ESA_DEATH_BENE_DIST,
      label:
        'by a death beneficiary taken after the death of the designated beneficiary',
      group: DistributionCodeGroup.beneDistribution,
    },
  };

  function getOptionsForTraditionalIra(): Array<DistributionCode> {
    if (isEarlyDistribution) {
      return [
        distributionOptions.IRA_EARLY,
        distributionOptions.IRA_TRANSFER,
        distributionOptions.IRA_ROLLOVER,
        distributionOptions.IRA_DISABILITY,
        distributionOptions.IRA_EARLY_ROTH_CONVERSION,
        distributionOptions.IRA_EARLY_IRS_LEVY,
        distributionOptions.PROHIBITED,
        distributionOptions.E_IRA_SAME_YEAR_EXCESS,
        distributionOptions.E_IRA_PRIOR_YEAR_EXCESS,
        distributionOptions.E_IRA_EXCESS_AFTER_DEADLINE,
        distributionOptions.IRA_SEP_SIMPLE_EXCESS,
        distributionOptions.SAME_YEAR_RECHAR,
        distributionOptions.PRIOR_YEAR_RECHAR,
        distributionOptions.E_IRA_REGULAR_REVOCATION,
        distributionOptions.E_IRA_OTHER_REVOCATION,
      ];
    }

    return [
      distributionOptions.IRA_NORMAL,
      distributionOptions.IRA_TRANSFER,
      distributionOptions.IRA_ROLLOVER,
      distributionOptions.IRA_NORMAL_ROTH_CONVERSION,
      distributionOptions.PROHIBITED,
      distributionOptions.Q_IRA_SAME_YEAR_EXCESS,
      distributionOptions.Q_IRA_PRIOR_YEAR_EXCESS,
      distributionOptions.Q_IRA_EXCESS_AFTER_DEADLINE,
      distributionOptions.IRA_SEP_SIMPLE_EXCESS,
      distributionOptions.SAME_YEAR_RECHAR,
      distributionOptions.PRIOR_YEAR_RECHAR,
      distributionOptions.Q_IRA_REGULAR_REVOCATION,
      distributionOptions.Q_IRA_OTHER_REVOCATION,
    ];
  }

  function getOptionsForTraditionalSep(): Array<DistributionCode> {
    if (isEarlyDistribution) {
      return [
        distributionOptions.IRA_TRANSFER,
        distributionOptions.IRA_ROLLOVER,
        distributionOptions.IRA_EARLY,
        distributionOptions.IRA_DISABILITY,
        distributionOptions.IRA_EARLY_ROTH_CONVERSION,
        distributionOptions.IRA_EARLY_IRS_LEVY,
        distributionOptions.PROHIBITED,
        distributionOptions.E_IRA_SAME_YEAR_EXCESS,
        distributionOptions.E_IRA_EXCESS_AFTER_DEADLINE,
        distributionOptions.E_IRA_PRIOR_YEAR_EXCESS,
        distributionOptions.IRA_SEP_SIMPLE_EXCESS,
        distributionOptions.E_IRA_OTHER_REVOCATION,
      ];
    }

    return [
      distributionOptions.IRA_NORMAL,
      distributionOptions.IRA_TRANSFER,
      distributionOptions.IRA_ROLLOVER,
      distributionOptions.PROHIBITED,
      distributionOptions.Q_IRA_SAME_YEAR_EXCESS,
      distributionOptions.Q_IRA_PRIOR_YEAR_EXCESS,
      distributionOptions.Q_IRA_EXCESS_AFTER_DEADLINE,
      distributionOptions.IRA_SEP_SIMPLE_EXCESS,
      distributionOptions.Q_IRA_OTHER_REVOCATION,
    ];
  }

  function getOptionsForSimpleIra(): Array<DistributionCode> {
    if (isEarlyDistribution) {
      return [
        distributionOptions.IRA_TRANSFER,
        distributionOptions.IRA_ROLLOVER,
        distributionOptions.SIMPLE_IRA_EARLY,
        distributionOptions.IRA_EARLY_FIRST_TWO_YEARS,
        distributionOptions.IRA_DISABILITY,
        distributionOptions.IRA_EARLY_IRS_LEVY,
        distributionOptions.PROHIBITED,
        distributionOptions.E_IRA_SAME_YEAR_EXCESS,
        distributionOptions.E_IRA_PRIOR_YEAR_EXCESS,
        distributionOptions.E_IRA_EXCESS_AFTER_DEADLINE,
        distributionOptions.IRA_SEP_SIMPLE_EXCESS,
        distributionOptions.E_IRA_OTHER_REVOCATION,
      ];
    }

    return [
      distributionOptions.IRA_NORMAL,
      distributionOptions.IRA_TRANSFER,
      distributionOptions.IRA_ROLLOVER,
      distributionOptions.PROHIBITED,
      distributionOptions.Q_IRA_SAME_YEAR_EXCESS,
      distributionOptions.Q_IRA_PRIOR_YEAR_EXCESS,
      distributionOptions.Q_IRA_EXCESS_AFTER_DEADLINE,
      distributionOptions.IRA_SEP_SIMPLE_EXCESS,
      distributionOptions.Q_IRA_OTHER_REVOCATION,
    ];
  }

  function getOptionsForRothIra(): Array<DistributionCode> {
    if (isEarlyDistribution) {
      return [
        distributionOptions.ROTH_QUALIFIED,
        distributionOptions.ROTH_EXCEPTION,
        distributionOptions.ROTH_EARLY_NO_EXCEPTION,
        distributionOptions.ROTH_TRANSFER,
        distributionOptions.ROTH_EARLY_EXCEPTION,
        distributionOptions.PROHIBITED,
        distributionOptions.ROTH_SAME_YEAR_EXCESS,
        distributionOptions.ROTH_PRIOR_YEAR_EXCESS,
        distributionOptions.E_ROTH_EXCESS_AFTER_DEADLINE,
        distributionOptions.SAME_YEAR_RECHAR,
        distributionOptions.PRIOR_YEAR_RECHAR,
        distributionOptions.ROTH_REGULAR_REVOCATION,
        distributionOptions.ROTH_OTHER_REVOCATION,
      ];
    }

    return [
      distributionOptions.ROTH_QUALIFIED,
      distributionOptions.ROTH_EXCEPTION,
      distributionOptions.ROTH_TRANSFER,
      distributionOptions.PROHIBITED,
      distributionOptions.ROTH_SAME_YEAR_EXCESS,
      distributionOptions.ROTH_PRIOR_YEAR_EXCESS,
      distributionOptions.Q_ROTH_EXCESS_AFTER_DEADLINE_QUALIFIED,
      distributionOptions.Q_ROTH_EXCESS_AFTER_DEADLINE_EXCEPTION,
      distributionOptions.SAME_YEAR_RECHAR,
      distributionOptions.PRIOR_YEAR_RECHAR,
      distributionOptions.ROTH_REGULAR_REVOCATION,
      distributionOptions.ROTH_OTHER_REVOCATION,
    ];
  }

  function getOptionsForHsa(): Array<DistributionCode> {
    return [
      distributionOptions.HSA_NORMAL,
      distributionOptions.HSA_TRANSFER,
      distributionOptions.HSA_DISABILITY,
      distributionOptions.HSA_PROHIBITED,
      distributionOptions.HSA_EXCESS_BEFORE_DEADLINE,
      distributionOptions.HSA_DEATH_DIST_SPOUSE,
      distributionOptions.HSA_DEATH_DIST_NON_SPOUSE,
      distributionOptions.HSA_DEATH_DIST_ESTATE,
      distributionOptions.HSA_DEATH_DIST_OTHER,
    ];
  }

  function getOptionsForEsa(): Array<DistributionCode> {
    return [
      distributionOptions.ESA_NORMAL,
      distributionOptions.ESA_TRANSFER,
      distributionOptions.ESA_TRANSFER_NEW_BENEFICIARY,
      distributionOptions.ESA_DISABILITY,
      distributionOptions.ESA_PROHIBITED,
      distributionOptions.ESA_SAME_YEAR_EXCESS,
      distributionOptions.ESA_PRIOR_YEAR_EXCESS,
      distributionOptions.ESA_DEATH_BENE_DIST,
    ];
  }

  function getOptionsForInheritedTraditional(): Array<DistributionCode> {
    const option = [
      distributionOptions.I_IRA_DEATH,
      distributionOptions.I_IRA_TRANSFER,
      distributionOptions.I_IRA_SAME_YEAR_EXCESS,
      distributionOptions.I_IRA_PRIOR_YEAR_EXCESS,
      distributionOptions.I_IRA_EXCESS_AFTER_DEADLINE,
    ]

    return option;
  }

  function getOptionsForInheritedRoth(): Array<DistributionCode> {
    const option =[
      distributionOptions.ROTH_QUALIFIED,
      distributionOptions.I_IRA_ROTH_TRANSFER,
      distributionOptions.ROTH_EXCEPTION,
      distributionOptions.ROTH_SAME_YEAR_EXCESS,
      distributionOptions.ROTH_PRIOR_YEAR_EXCESS,
      distributionOptions.Q_ROTH_EXCESS_AFTER_DEADLINE_EXCEPTION,
      distributionOptions.Q_ROTH_EXCESS_AFTER_DEADLINE_QUALIFIED,
    ]

    return option;
  }

  return (
    {
      [AccountType.traditionalIra]: getOptionsForTraditionalIra(),
      [AccountType.traditionalSep]: getOptionsForTraditionalSep(),
      [AccountType.simpleIra]: getOptionsForSimpleIra(),
      [AccountType.rothIra]: getOptionsForRothIra(),
      [AccountType.hsa]: getOptionsForHsa(),
      [AccountType.esa]: getOptionsForEsa(),
      [AccountType.inheritedTraditional]: getOptionsForInheritedTraditional(),
      [AccountType.inheritedRoth]: getOptionsForInheritedRoth(),
    }[accountType] || []
  );
}
