import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { useUser } from '../auth/useUser';
import Layout from '../components/Layout';
import FormPaper from '../components/FormPaper';
import { getAccountOwner } from '../api/AccountOwnerApi';
import AccountSearchForm from '../components/form/accountSearch/AccountSearchForm';
import AccountSearchResults from '../components/form/accountSearch/AccountSearchResults';
import ReviewDocumentForm from '../components/form/newAccount/ReviewDocumentForm';
import {
  Distribution,
  DistributionAllocation,
  DistributionReason,
  DistributionStatus,
  DistributionStatusState,
  WorkflowDates,
} from '../api/DistributionApi.d';
import {
  createDistribution,
  updateDistribution,
  saveDistributionAllocations,
  changeDistributionStatus,
  getAccountDistribution,
  createDistributionDocument,
  getDistributionDocument,
} from '../api/DistributionApi';
import DistributionCodeForm, {
  DISTRIBUTION_CODE_INIT,
} from '../components/form/distribution/DistributionCodeForm';
import DistributionAmountForm, {
  DISTRIBUTION_AMOUNT_INIT,
} from '../components/form/distribution/DistributionAmountForm';
import DistributionMethodForm, {
  DISTRIBUTION_METHOD_INIT,
} from '../components/form/distribution/DistributionMethodForm';
import DistributionAllocationForm, {
  DISTRIBUTION_ALLOCATION_INIT,
} from '../components/form/distribution/DistributionAllocationForm';
import { AccountOwner } from '../api/AccountOwnerApi.d';
import {
  addTransactionData,
  setSearchResponse,
  setSelectedAccount,
  skipStep,
  completeTransaction,
  rejectTransaction,
  useTransactionReducer,
} from './TransactionReducer';
import { useGlobalContext } from '../auth/useGlobalContext';
import { getAccount } from '../api/AccountApi';
import {
  AccountStatus,
  AccountType,
  TransactionType,
} from '../api/AccountApi.d';
import TransactionStepper, { TransactionStep } from './TransactionStepper';
import SkipChallenge from '../components/steps/SkipChallenge';
import {
  useUnsavedChangesWarning,
  RowDefinition,
} from '../components/useUnsavedChangesWarning';
import SiraAccountOwnerInfo from '../components/form/SiraAccountOwnerInfo';
import { IMMDocumentType } from '../api/ESignApi.d';
import SiraPageAlert from '../components/SiraPageAlert';
import { RmdOption } from '../components/form/distribution/DistributionCodeForm.d';
import { errorMessages } from '../utils/errorhandling.utils';
import { use } from 'chai';
import { TermEnabledOption } from '../components/form/distribution/DistributionTermForm';

function MakeDistribution() {
  let isMounted = true;
  const [pageState] = useTransactionReducer();
  const [accountOwner, setAccountOwner] = useState({} as AccountOwner);
  const [allocations, setAllocations] = useState(
    [] as Array<DistributionAllocation>
  );
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUser();
  const { organization, addGlobalMessage } = useGlobalContext();
  const queryParams = new URLSearchParams(useLocation().search);
  const { query = '' } = pageState.searchResponse || {};
  const {
    accountId = '',
    accountType = '',
    accountOwnerId = '',
    relationship = '',
  } = pageState.selectedAccount;
  const {
    distributionId = '',
    distributionStatus,
    distributionReason,
  } = pageState.distributionInformation;
  const isAwaiting = [
    DistributionStatus.signature,
    DistributionStatus.review,
    DistributionStatus.submitOwner,
  ].includes(distributionStatus);
  const isActive = [
    DistributionStatus.active,
    DistributionStatus.review,
  ].includes(distributionStatus);
  const loadingExistingDistribution =
    queryParams.get('accountId') && queryParams.get('distributionId');
  const { UnsavedChangesPrompt, setUnsavedChanges } =
    useUnsavedChangesWarning();
  const isFinalizeStatus = [
    DistributionStatus.active,
    DistributionStatus.review,
  ].includes(distributionStatus);
  // Account types and distribution codes that don't support withholding
  const showWithholding =
    ![AccountType.hsa, AccountType.esa].includes(accountType) &&
    ![
      DistributionReason.IRA_ROLLOVER,
      DistributionReason.TRANSFER,
      DistributionReason.PROHIBITED,
      DistributionReason.SAME_YEAR_RECHAR,
      DistributionReason.PRIOR_YEAR_RECHAR,
    ].includes(distributionReason);

  // Only show state withholding when an org has at least one state that supports it
  const showStateWithholding =
    organization.stateTaxIDS &&
    organization.stateTaxIDS.some(
      ({ stateWithholding = false }) => stateWithholding
    );

  // Advance the distribution status to the next when finalizing
  const progressCurrentDistributionStatus = async (
    finalizeWorkflow: boolean
  ): Promise<void> => {
    await changeDistributionStatus(
      user.organizationId,
      accountId,
      accountOwnerId,
      distributionId,
      DistributionStatusState.next,
      {} as WorkflowDates,
      user.token,
      user
    )
      .then((res) => {
        if (isMounted) {
          // Set latest status
          addTransactionData({ distributionInformation: res.data }, false);
          if (finalizeWorkflow) {
            completeTransaction();
            setUnsavedChanges(null);
          }
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Error finalizing distribution status'
        );
        if (isMounted) {
          rejectTransaction();
        }
      });
  };

  // Revert transaction to pending before saving to allow updates
  const revertToPending = async (): Promise<DistributionStatus> => {
    setIsLoading(true);

    const response = await changeDistributionStatus(
      user.organizationId,
      accountId,
      accountOwnerId,
      distributionId,
      DistributionStatusState.previous,
      {} as WorkflowDates,
      user.token,
      user
    );
    const { distributionStatus: newDistributionStatus } = response.data;

    if (response.errorMessage) {
      addGlobalMessage('Error reverting distribution status');
      return distributionStatus;
    }
    if (isMounted) {
      addTransactionData(
        {
          distributionInformation: {
            distributionStatus: newDistributionStatus,
          },
        },
        false
      );
    }

    return newDistributionStatus;
  };

  // Update transaction or create if no ID provided (revert if updating AWAITING_SIGNATURE)
  const saveDistribution = async (data: Distribution): Promise<void> => {
    const merged = {
      ...data,
      federalWithholdingAmount: '0',
      excludeFromRmd: data.excludeFromRmd,
    }; // Merge a reverted status immediately when saving

    const apiMethod = data.distributionId
      ? updateDistribution
      : createDistribution;

    setIsLoading(true);

    if (isAwaiting) {
      const newDistributionStatus = await revertToPending();
      merged.distributionStatus = newDistributionStatus;
    }

    await apiMethod(
      merged,
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user
    )
      .then((res) => {
        if (isMounted) {
          addTransactionData({ distributionInformation: res.data });
          setIsLoading(false);
        }
        const transactionData: RowDefinition = {
          accountId: pageState.selectedAccount.accountId,
          accountOwnerId: pageState.selectedAccount.accountOwnerId,
          transactionType: TransactionType.distribution,
          distributionId: res.data.distributionId,
        };
        setUnsavedChanges(transactionData);
      })
      .catch((err) => {
        addGlobalMessage(errorMessages(err) || 'Error saving distribution');
      });
  };

  // Send then store latest allocations
  const updateAndSaveAllocations = async (
    data: Array<DistributionAllocation>
  ): Promise<void> => {
    setIsLoading(true);

    if (isAwaiting) {
      await revertToPending();
    }

    const response = await saveDistributionAllocations(
      data,
      user.organizationId,
      accountId,
      accountOwnerId,
      distributionId,
      user.token,
      user
    );

    if (isMounted) {
      if (response.errorMessage) {
        addGlobalMessage(
          response.errorMessage || 'Error saving distribution allocations'
        );
        setIsLoading(false);
      } else {
        setAllocations(response.data);
        skipStep(5);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };

  // Update the status imperatively after document creation
  // The api does this but doesn't send a payload back so we'll hardcode it here for now
  const handleCreateDocument = (): void => {
    if (distributionStatus === DistributionStatus.pending) {
      addTransactionData(
        {
          distributionInformation: {
            distributionStatus: DistributionStatus.signature,
          },
        },
        false
      );
    }
  };

  // Generate the PDF in S3 and/or stream it
  const viewDocument = (): Promise<any> => {
    return isAwaiting || isActive
      ? getDistributionDocument(
          user.organizationId,
          accountOwnerId,
          accountId,
          distributionId,
          user.token,
          '',
          user
        )
      : createDistributionDocument(
          user.organizationId,
          accountOwnerId,
          accountId,
          distributionId,
          user.token,
          user
        );
  };

  // Get the accountOwner for the account loaded
  const updateAccountOwner = async (): Promise<void> => {
    setIsLoading(true);

    await getAccountOwner(accountOwnerId, user.organizationId, user.token, user)
      .then((res) => {
        if (isMounted) {
          setIsLoading(false);
          setAccountOwner(res.data);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      });
  };

  // Look up account and set it selected with query params passed
  async function fetchAndSetAccount(): Promise<void> {
    await getAccount(
      queryParams.get('accountId'),
      queryParams.get('accountOwnerId'),
      user.organizationId,
      user.token,
      user
    )
      .then((res) => {
        if (isMounted) {
          setSelectedAccount(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(
            errorMessages(err) || 'Could not fetch the preselected account'
          );
        }
      });
  }

  // Lookup distribution and set it with query params passed
  async function fetchAndSetDistributionInfo(): Promise<void> {
    await getAccountDistribution(
      queryParams.get('accountId'),
      queryParams.get('accountOwnerId'),
      user.organizationId,
      queryParams.get('distributionId'),
      user.token,
      user
    )
      .then((res) => {
        const skipToEnd = [
          DistributionStatus.signature,
          DistributionStatus.review,
          DistributionStatus.submitOwner,
        ].includes(res.data.distributionStatus);

        const initialValuesWithRmdOptions = {
          rmdOptions: !res.data.excludeFromRmd
            ? RmdOption.excludeFromRmd
            : RmdOption.doNotExcludeFromRmd,
        };

        addTransactionData(
          {
            distributionInformation: {
              ...res.data,
              ...initialValuesWithRmdOptions,
              termEnabled: res.data.ownerResponsible ? TermEnabledOption.owner : TermEnabledOption.schedule,
            },
          },
          false
        );

        if (skipToEnd) {
          skipStep(5);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (isMounted) {
          setIsLoading(false);
          addGlobalMessage(
            errorMessages(err) || 'Error fetching distribution info'
          );
        }
      });
  }

  // Optionaly set account and distribution when passed query params
  async function preselectAccountAndSetDistributionInfo(): Promise<void> {
    if (queryParams.get('accountId')) {
      setIsLoading(true);
      await fetchAndSetAccount();
    }
    if (loadingExistingDistribution) {
      setIsLoading(true);
      await fetchAndSetDistributionInfo();
    }
  }

  const makeDistributionSteps: Array<TransactionStep> = [
    {
      label: 'Find Owner and Account',
      stepContent: (
        <Box width="1" mt={4} mb={4}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Box mt={2} mb={4}>
                <AccountSearchForm setResponse={setSearchResponse} />
              </Box>
            </Grid>
          </Grid>
          {query && (
            <AccountSearchResults
              filterFunction={({
                account: {
                  accountStatus: statusMatcher = '' as AccountStatus,
                } = {},
              }) =>
                [AccountStatus.open, AccountStatus.closed].includes(
                  statusMatcher
                )
              }
              response={pageState.searchResponse}
              onResultClick={setSelectedAccount}
            />
          )}
        </Box>
      ),
    },
    {
      label: 'Select Distribution Reason',
      stepContent: (
        <Box mt={5} mb={3}>
          <DistributionCodeForm
            account={pageState.selectedAccount}
            accountOwner={accountOwner}
            onSubmit={(distributionInformation) => {
              addTransactionData({ distributionInformation });
            }}
            initialValues={{
              ...DISTRIBUTION_CODE_INIT,
              ...pageState.distributionInformation,
            }}
          />
        </Box>
      ),
    },
    {
      label: 'Provide Distribution Amounts',
      stepContent: (
        <Box mt={5} mb={3}>
          <DistributionAmountForm
            account={pageState.selectedAccount}
            accountOwner={accountOwner}
            onSubmit={(distributionInformation) => {
              addTransactionData({
                distributionInformation,
              });
            }}
            initialValues={{
              ...DISTRIBUTION_AMOUNT_INIT,
              ...pageState.distributionInformation,
            }}
            showWithholding={showWithholding}
            showStateWithholding={showStateWithholding}
          />
        </Box>
      ),
    },
    {
      label: 'Select Distribution Method',
      stepContent: (
        <Box mt={3} mb={3}>
          <DistributionMethodForm
            initialValues={{
              ...DISTRIBUTION_METHOD_INIT,
              ...pageState.distributionInformation,
            }}
            onSubmit={saveDistribution}
            distributionReason={distributionReason}
            accountType={accountType}
            relationship={relationship}
          />
        </Box>
      ),
    },
    {
      label: 'Select Distributing Investment',
      stepContent: (
        <Box mt={3} mb={3}>
          <SkipChallenge
            onSkip={() => skipStep(pageState.activeStep + 1)}
            skipText="Skip"
            acceptText="Enter Assets"
            afterContent={
              <Typography variant="body1">
                Use these investment options to document the distributing
                investment for the distribution.
              </Typography>
            }
          >
            <>
              <DistributionAllocationForm
                onSubmit={updateAndSaveAllocations}
                existingAllocations={[
                  ...DISTRIBUTION_ALLOCATION_INIT,
                  ...allocations,
                ]}
                distributionInfo={pageState.distributionInformation}
              />
            </>
          </SkipChallenge>
        </Box>
      ),
    },
    {
      label: 'Review and Sign Document',
      stepContent: (
        <>
          <ReviewDocumentForm
            searchResult={pageState.selectedAccount}
            allowSignature={isAwaiting}
            hideStepButtonBar={pageState.completed}
            getDocument={viewDocument}
            onGetDocument={handleCreateDocument}
            onEsignClick={completeTransaction}
            submitName="Done"
            onSubmit={async () => {
              if (isAwaiting) {
                await progressCurrentDistributionStatus(true);
              }
            }}
            documentType={IMMDocumentType.distribution}
            transactionName="Distribution"
            transactionStatus={
              pageState.distributionInformation.distributionStatus
            }
          />

          {isFinalizeStatus && (pageState.completed || pageState.rejected) && (
            <SiraPageAlert
              pageStatus={pageState.rejected}
              transactionName="Distribution"
              accountId={accountId}
              accountOwnerId={accountOwnerId}
            />
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (user.token || user.organizationId) {
      preselectAccountAndSetDistributionInfo();
    }

    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId]);

  useEffect(() => {
    setIsLoading(true);
    updateAccountOwner();
  }, [accountOwnerId]);

  return (
    <Layout>
      <FormPaper>
        <>
          <Typography variant="overline" gutterBottom>
            Transactions
          </Typography>

          <Typography color="secondary" variant="h1" gutterBottom>
            Distribute Money
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            Find the account owner and select the correct account to take a
            distribution.
          </Typography>

          {accountId && (
            <Box mt={2}>
              <Paper elevation={3}>
                <SiraAccountOwnerInfo
                  selectedAccount={pageState.selectedAccount}
                  accountOwner={accountOwner}
                />
              </Paper>
            </Box>
          )}

          <Box mt={5}>
            <TransactionStepper
              steps={makeDistributionSteps}
              activeStep={pageState.activeStep}
              isLoading={isLoading}
              onStepClick={(index) => {
                if (
                  !loadingExistingDistribution ||
                  (loadingExistingDistribution && index > 0)
                ) {
                  skipStep(index);
                }
              }}
            />
          </Box>
          {UnsavedChangesPrompt}
        </>
      </FormPaper>
    </Layout>
  );
}

export default MakeDistribution;
