import { FrequencyValues } from '../components/form/distribution/Frequency';
import { Distribution } from './DistributionApi.d';

export interface RecurringDistributionResponse {
  data?: RecurringDistribution;
  errorMessage?: any;
  status?: number;
}

export enum LifeExpectancyTable {
  jle = 'JOINT_LIFE_EXPECTANCY',
  ule = 'UNIFORM_LIFETIME',
  sle = 'SINGLE_LIFE_EXPECTANCY',
  slenonrecalc = 'SINGLE_LIFE_EXPECTANCY_NON_RECALCULATED',
  term = 'TERM',
  amortization = 'AMORTIZATION',
  annuitization = 'ANNUITIZATION',
}

export interface RecurringDistribution extends Distribution {
  frequency?: FrequencyValues;
  recurringDistributionId?: string;
  createdByDefault?: boolean;
  term?: string;
  lifeExpectancyTable?: LifeExpectancyTable;
  distributionAmountType?: DistributionChoices;
  recurringSelection?: string;
  grossAmount?: number;
  accountBalance?: string;
  startDate?: string;
  endDate?: string;
  termChoice?: TermChoiceOption;
  parentRecurringDistributionId?: string;
  termEndDateAction?: TermEndDateAction;
}

export enum RecurringDistributionStatus {
  active = 'ACTIVE',
  inActive = 'INACTIVE',
  signature = 'AWAITING_SIGNATURE',
  pending = 'PENDING',
  review = 'PENDING_REVIEW',
}

export enum StatusState {
  next = 'NEXT',
  previous = 'PREVIOUS',
}

export enum TermEndDateAction {
  end = 'END_DISTRIBUTION',
  createRmd = 'CREATE_RMD',
}
export interface RecurringDistributionStatusResponse {
  data?: RecurringDistribution;
  errorMessage?: any;
}

// These options are used to calculate validation and sometimes set the termLength/term but are not shared with api
export enum TermChoiceOption {
  empty = '',
  tradInfinite = 'TRADITIONAL_INFINITE',
  rothInfinite = 'ROTH_INFINITE',
  rothTerm = 'ROTH_TERM',
  rothTermJle = 'ROTH_TERM_JLE',
  rothTermInherited = 'ROTH_TERM_INH',
  rothTermInheritedUle = 'ROTH_TERM_INH_ULE',
  tradInherited = 'TRAD_INH',
  inheritedRoth10 = 'INH_ROTH_10',
  inheritedRoth5 = 'INH_ROTH_5',
  inheritedTrad10 = 'INH_TRAD_10',
  inheritedTrad5 = 'INH_TRAD_5',
  ult = 'UNIVERSAL_LIFETIME',
  jle = 'JOINT_LIFE_EXPECTANCY',
  sle = 'SINGLE_LIFE_EXPECTANCY',
  slenonrecalc = 'SINGLE_LIFE_EXPECTANCY_NON_RECALCULATED',
  manual = 'TRADITIONAL_TERM',
  stop72 = 'STOP_72',
  before72 = 'BEFORE_72',
  continuePast72 = 'CONTINUE_PAST_72',
  stop73 = 'STOP_73',
  before73 = 'BEFORE_73',
  continuePast73 = 'CONTINUE_PAST_73',
}

export interface RecurringDistributionImportColumns {
  ourHeaders?: Array<string>;
  fileHeaders?: Array<string>;
  rowData?: Array<any>;
}

export interface RecurringDistributionImportColumnsResponse {
  data?: DistributionImportColumns;
  errorMessage?: any;
}
