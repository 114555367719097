import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  LinearProgress,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TransactionSignatureIcon from '../../../icons/TransactionSignature';

import SiraCheckbox from '../SiraCheckbox';
import StepButtonBar from '../../steps/StepButtonBar';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import {
  getCustomerPortalEsignUrl,
  getIMMSessionUrl,
  getSuperioruserPortalEsignUrl,
  postCustomerPortalSignUrl,
  putCustomerPortalSignUrl,
  putsuperioruserPortalSignUrl,
} from '../../../api/ESignApi';
import { useUser } from '../../../auth/useUser';
import {
  IMMDocumentType,
  IMMSignatureUserType,
  ImmSessionRequest,
  SuperiorIraEsignReponse,
} from '../../../api/ESignApi.d';
import { createFileBlobFromBase64 } from '../../../utils/App.utils';
import { AccountStatus } from '../../../api/AccountApi.d';
import { InheritingAccountOwner } from '../inheritedIra/InheritedAccountOwner';
import TransactionSubStepper, {
  TransactionSubStep,
} from '../../../page/TransactionSubStepper';
import { UserRole } from '../../../api/UserApi.d';
import SiraModal from '../../SiraModal';
import { AccountDetailAccordionType } from '../../ownerProfile/OwnerAccountDetails';
import { errorMessages } from '../../../utils/errorhandling.utils';

export interface Review {
  ownerVerified?: boolean;
  IMMSessionUrl?: string;
  userCreatedSignature?: boolean;
}

export const REVIEW_INIT: Review = {
  ownerVerified: false,
  IMMSessionUrl: '',
  userCreatedSignature: false,
};

export const REVIEW_SCHEMA = yup.object({
  ownerVerified: yup.boolean().oneOf([true], 'Owner must review document'),
  userCreatedSignature: yup.boolean().oneOf([true], 'Signature is required'),
});

interface ReviewDocumentsProps {
  searchResult: any;
  allowSignature?: boolean;
  getDocument: Function;
  onGetDocument?: Function;
  onSubmit?: Function;
  onReset?: Function;
  submitName?: string;
  resetName?: string;
  hideStepButtonBar?: boolean;
  onEsignClick?: Function;
  documentType: IMMDocumentType;
  transactionName?: string;
  linkLabel?: string;
  inheritedOwner?: InheritingAccountOwner;
  account?: string;
  accountOwner?: string;
  accountTransaction?: AccountDetailAccordionType;
  transactionStatus?: AccountStatus;
}

export const ReviewDocumentSchema = {};

function ReviewDocumentForm({
  searchResult = {},
  allowSignature = false,
  getDocument,
  onGetDocument = () => { },
  onSubmit,
  onReset,
  submitName,
  resetName,
  hideStepButtonBar = false,
  onEsignClick = () => { },
  documentType,
  transactionName = '',
  linkLabel = 'View Account',
  inheritedOwner = {},
  account = '',
  accountOwner = '',
  accountTransaction,
  transactionStatus,
}: ReviewDocumentsProps) {
  let isMounted = true;
  const { addGlobalMessage, organization } = useGlobalContext();
  const { user } = useUser();
  const [enableSignature, setEnableSignature] = useState(allowSignature);
  const [isButtonLoaded, setIsButtonLoaded] = useState(true as boolean);
  const [s3Path, setS3Path] = useState('' as string);
  const [IMMSignatureUsers, SetIMMSignatureUsers] = useState(
    [] as Array<ImmSessionRequest>
  );
  const [IMMSessionUrl, setIMMSessionUrl] = useState('' as string);
  const [isIMMLoading, setIsIMMLoading] = useState(false as boolean);
  const [IMMChosen, setIMMChosen] = useState(false as boolean);
  const { accountOwnerId, accountId } = searchResult;
  const { eSign: eSignEnabled } = organization;
  const [activeStep, setActiveStep] = useState(0);
  const [buttonClicked, setButtonClicked] = useState(false as boolean);
  const [ownerPortal, setOWnerPortal] = useState(false as boolean);
  const [open, setOpen] = useState(false);
  const [userSignature, setUserSignature] = useState(null as string);
  const [userSigned, setUserSigned] = useState(false as boolean);
  const [signingCustomerDocument, setSigningCustomerDocument] = useState(
    false as boolean
  );
  const [customerSignedDocument, setCustomerSignedDocument] = useState(
    false as boolean
  );

  const contentStyle = {
    height: '100%',
  };
  const buttonContentStyle = {
    height: '100%',
    display: 'flex',
    justifyContent: 'right',
    paddingTop: 2,
  };

  // Just a standalone IMM session launcher with the stored url
  const launchEsign = () => {
    if (IMMSessionUrl) {
      window.open(IMMSessionUrl);
    }
  };

  // Fetch the url for the eSign session based on the s3Path that was passed from the document stream via header
  const getAndSetEsignSessionUrl = async (): Promise<void> => {
    setIsIMMLoading(true);
    setIMMChosen(true);
    onEsignClick();

    let accountIdCheck;
    let accountOwnerIdCheck;

    if (
      searchResult.accountId === undefined &&
      searchResult.accountOwnerId === undefined
    ) {
      accountIdCheck = account;
      accountOwnerIdCheck = accountOwner;
    } else {
      accountIdCheck = searchResult.accountId;
      accountOwnerIdCheck = searchResult.accountOwnerId;
    }

    if (!IMMSessionUrl && accountOwnerIdCheck && accountIdCheck) {
      await getIMMSessionUrl(
        IMMSignatureUsers,
        user.organizationId,
        accountOwnerIdCheck,
        accountIdCheck,
        s3Path,
        documentType,
        user.token
      )
        .then(({ data: url = '' }) => {
          if (url) {
            setIMMSessionUrl(url);
            setUserSigned(true);
          }
        })
        .catch((err) => {

          addGlobalMessage(errorMessages(err) || 'Error navigating to eSign provider'
          );
        });
    } else {
      launchEsign();
    }

    setIsIMMLoading(false);
  };

  // get and set customer portal esign value
  const getUserSignature = async (): Promise<void> => {
    let createSignatureMethod: Promise<SuperiorIraEsignReponse>;

    if (user.roles.includes(UserRole.accountOwner)) {
      createSignatureMethod = getCustomerPortalEsignUrl(
        user.organizationId,
        accountOwnerId,
        accountId,
        user.token,
        user
      );
    } else {
      createSignatureMethod = getSuperioruserPortalEsignUrl(
        user.organizationId,
        user.token,
        user
      );
    }
    await createSignatureMethod.then((res) => {
      if (res.data) {
        if (res.data.signatureText.trim() !== '') {
          setUserSignature(res.data.signatureText);
          setUserSigned(true);
        }
      } else {
        setUserSigned(false);
      }
    })
      .catch((err) => {

        addGlobalMessage(errorMessages(err) || 'Error getting user signature');
      });
  };

  // Stream in the document and pick off the s3Path fromt he headers for use when eSign is clicked
  const streamPdfDocument = async (): Promise<void> => {
    setIsButtonLoaded(false);
    await getDocument()
      .then((res) => {
        const { data } = res;
        const {
          s3FilePath = '',
          bytes: base64 = '',
          signatureUsers = [],
        } = data;
        // Create a file blob from incoming base64 (this will only happen for the initial POST)
        // The GET in this case will return the raw blob as the response and should skip the base64 conversion
        const fileBlob =
          createFileBlobFromBase64(base64, 'application/pdf') || data;

        if (isMounted) {
          setIsButtonLoaded(true);
          setButtonClicked(true);
          onGetDocument(res);
          setEnableSignature(true);
          SetIMMSignatureUsers(signatureUsers);
          setS3Path(s3FilePath);
          if (!ownerPortal || transactionStatus === AccountStatus.submitOwner) {
            setUserSigned(true);
          } else {
            setUserSigned(false);
          }
        }
        if (res && data) {
          const winURL = window.URL.createObjectURL(fileBlob);
          window.open(winURL, '_blank');
        }
      })
      .catch((err) => {

        addGlobalMessage(errorMessages(err) || 'Error opening document');
      });
  };

  // call update of signature when user hits accept button
  const updateSignature = async (): Promise<void> => {
    let createSignatureMethod: Promise<SuperiorIraEsignReponse>;

    if (user.roles.includes(UserRole.accountOwner)) {
      createSignatureMethod = putCustomerPortalSignUrl(
        user.organizationId,
        accountOwnerId,
        accountId,
        user.token,
        userSignature,
        user
      );
    } else {
      createSignatureMethod = putsuperioruserPortalSignUrl(
        user.organizationId,
        user.token,
        userSignature,
        user
      );
    }
    await createSignatureMethod
      .then(({ data: { signature = '' } = {} }) => {
        if (signature) {
          setUserSigned(true);
        }
      })
      .catch((err) => {

        addGlobalMessage(errorMessages(err) || 'Error updating user signature');
        // setUserSigned(false);
      });
  };

  // post customer portal signature
  const postSignature = async (values): Promise<void> => {
    setSigningCustomerDocument(true);
    let signatureType = IMMSignatureUserType.customerPortal;
    let createSignatureMethod: Promise<SuperiorIraEsignReponse>;
    if (user.roles.includes(UserRole.accountOwner)) {
      signatureType = IMMSignatureUserType.customerPortal;
      createSignatureMethod = postCustomerPortalSignUrl(
        user.organizationId,
        accountOwnerId,
        accountId,
        user.token,
        s3Path,
        signatureType,
        user
      );
    } else {
      signatureType = IMMSignatureUserType.trustee;
      createSignatureMethod = postCustomerPortalSignUrl(
        user.organizationId,
        accountOwnerId,
        accountId,
        user.token,
        s3Path,
        signatureType,
        user
      );
    }

    await createSignatureMethod
      .then(() => {
        setSigningCustomerDocument(false);
        setCustomerSignedDocument(true);
        onSubmit({ ...values });
      })
      .catch((err) => {

        setSigningCustomerDocument(false);
        setCustomerSignedDocument(false);
        addGlobalMessage(errorMessages(err) || 'Error posting user signature');
      });
  };

  useEffect(() => {
    setEnableSignature(allowSignature);

    return () => {
      isMounted = false;
    };
  }, [allowSignature]);

  useEffect(() => {
    launchEsign();
  }, [IMMSessionUrl]);


  const labelValue = () => {
    if (transactionName === 'Inherited account') {
      return `/accountOwner/${inheritedOwner.accountOwnerId}`;
    }

    if (linkLabel === 'Request a Rollover/Transfer for this account') {
      return `/transactions/rolloverTransfer?accountId=${accountId}&accountOwnerId=${accountOwnerId}`;
    }
    if (linkLabel === 'View Claim') {
      return `/beneficiaryClaims/viewBeneficiaryClaim/beneficiaryClaimsProfile?accountId=${accountId}&accountOwnerId=${accountOwnerId}`;
    }

    return `/accountOwner/${accountOwnerId}/account/${accountId}`;
  };

  const reviewSignSubSteps: Array<TransactionSubStep> = [
    {
      label: 'Review Document',
      stepContent: (
        <>
          {isButtonLoaded ? (
            <Button
              data-qa="view-pdf-button"
              variant="contained"
              color="primary"
              startIcon={<PictureAsPdfIcon />}
              onClick={streamPdfDocument}
            >
              View PDF Document
            </Button>
          ) : (
            <Box width="1">
              <LinearProgress color="secondary" />
            </Box>
          )}
          <Divider sx={{ mt: 3, mb: 1 }} />
          <Field
            name="ownerVerified"
            label="I have reviewed the document"
            component={SiraCheckbox}
            disabled={!buttonClicked}
          />
        </>
      ),
      validationFields: ['ownerVerified'],
    },
    {
      label: 'Create Signature',
      stepContent: (
        <>
          {userSignature ? (
            // disable signed document in italic signed font display an editicon to allow user to edit the signed document
            //
            <Box display="flex" flexWrap="nowrap">
              <Typography variant="h1" style={{ fontFamily: 'Tangerine' }}>
                {userSignature}
              </Typography>
              <Box ml={2}>
                <IconButton
                  data-qa="edit-signature-button"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <>
              <Button
                data-qa="view-pdf-button"
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Create Signature{' '}
              </Button>
            </>
          )}
          {/* add a field but hide it and use it as a validation field to display error message and prevent user from going to next */}
          <Field
            name="userCreatedSignature"
            component={SiraCheckbox}
            disabled={false}
            style={{ display: 'none' }}
          />
          <Box mt={3}>
            <Typography variant="body2">
              By creating your signature above and clicking the “NEXT” button
              you are consenting to use electronic records and signatures in
              connection with this transaction and confirm your name is correct.
              Your electronic signature will be just as enforceable as signing
              the same document using a handwritten signature.
            </Typography>
          </Box>
        </>
      ),
      validationFields: ['userCreatedSignature'],
    },
    {
      label: 'Sign Document',
      stepContent: (
        <>
          <Box mt={5} mb={5}>
            <Typography variant="h4" pb={1}>
              Electronic Signature Disclosure and Consent
            </Typography>
            <Divider sx={{ mt: 3, mb: 1 }} />
            <Typography variant="body1">
              By selecting the “SIGN” button, I am signing this document
              electronically. I agree that my electronic signature is the legal
              equivalent of my manual/handwritten signature on this document. By
              selecting “SIGN” using any device, means, or action, I consent to
              the legally binding terms and conditions of this document. I
              further agree that my signature on this document is as valid as if
              I signed the document in writing. I am also confirming that I am
              authorized to enter into this Agreement. If I am signing this
              document on behalf of a minor, I represent and warrant that I am
              the minor’s parent or legal guardian.
            </Typography>
          </Box>
        </>
      ),
      validationFields: [],
    },
  ];

  const handleClose = () => {
    setOpen(false);
    setUserSigned(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserSignature(event.target.value);
    if (event.target.value.trim() !== '' && event.target.value !== null) {
      setUserSigned(true);
    }
  };

  const handleAccept = () => {
    if (userSignature.trim() !== '') {
      setOpen(false);
      // make a call to put updated signature
      setUserSigned(true);
      updateSignature();
    } else {
      setUserSigned(false);
    }
  };

  useEffect(() => {
    if (!ownerPortal) {
      setUserSigned(true);
    }
  }, [ownerPortal]);

  // set display for user esign
  useEffect(() => {
    if (user.roles) {
      if (
        (user.roles.includes(UserRole.accountOwner) &&
          organization.eSignWithSuperior) ||
        transactionStatus === AccountStatus.submitOwner
      ) {
        setOWnerPortal(true);
        getUserSignature();
      }
    }
  }, [user.roles, user.organizationId]);

  useEffect(() => { }, [linkLabel]);

  return (
    <>
      {' '}
      <Formik
        initialValues={REVIEW_INIT}
        onSubmit={async (values) => {
          // if user is customer portal
          if (ownerPortal) {
            postSignature(values);
          } else {
            await onSubmit({ ...values, IMMChosen });
          }
        }}
        onReset={() => onReset()}
        validationSchema={REVIEW_SCHEMA}
      >
        {({ isSubmitting, setFieldValue }) => {

          useEffect(() => {
            if (userSigned || userSignature !== null) {
              setFieldValue('userCreatedSignature', true);
            } else {
              setFieldValue('userCreatedSignature', false);
            }
          }, [userSigned]);

          return (
            <Form>
              {!ownerPortal ? (
                <>
                  <Box mt={5} mb={5}>
                    {isButtonLoaded ? (
                      <Button
                        data-qa="view-pdf-button"
                        variant="contained"
                        color="primary"
                        startIcon={<PictureAsPdfIcon />}
                        onClick={streamPdfDocument}
                      >
                        View PDF Document
                      </Button>
                    ) : (
                      <Box width="1">
                        <LinearProgress color="secondary" />
                      </Box>
                    )}

                    <Divider sx={{ mt: 3, mb: 1 }} />

                    {eSignEnabled && (
                      <Box mt={3}>
                        <Button
                          disabled={!s3Path || !enableSignature || isIMMLoading}
                          data-qa="e-sign-button"
                          variant="contained"
                          color="secondary"
                          startIcon={
                            isIMMLoading ? (
                              <CircularProgress size={24} />
                            ) : (
                              <BorderColorIcon />
                            )
                          }
                          onClick={getAndSetEsignSessionUrl}
                          endIcon={<OpenInNewIcon />}
                        >
                          Sign Electronically
                        </Button>
                      </Box>
                    )}

                    {!IMMChosen && (
                      <Box mt={3}>
                        <Field
                          name="ownerVerified"
                          label="Account Owner has reviewed and signed the printed document"
                          component={SiraCheckbox}
                          disabled={!enableSignature}
                        />
                      </Box>
                    )}


                  </Box>
                  {IMMChosen && (
                    <Alert severity="success">
                      <AlertTitle>
                        {transactionName &&
                          `${transactionName} successfully added. `}{' '}
                        Taking you to eSign documents
                      </AlertTitle>
                      <Typography variant="body2">
                        The document status will be updated after the signed
                        document is archived. If prompted when leaving this
                        page, select KEEP so that the transaction is not
                        discarded.{' '}
                        <Link sx={{ fontStyle: 'italic' }} href={labelValue()}>
                          {linkLabel}
                        </Link>
                      </Typography>
                      {
                        transactionName === 'Inherited account' ? (
                          <Typography variant="body2">
                            Complete the{' '}
                            <Link
                              href={`/transactions/recurringDistributions?accountId=${account}&accountOwnerId=${accountOwner}`}
                              sx={{ cursor: 'pointer' }}
                            >
                              Add Recurring Distribution
                            </Link>{' '}
                            workflow to set up the inherited IRA owner’s required distribution schedule.
                          </Typography>
                        ) : null 
                      }
                    </Alert>
                  )}
                  {!hideStepButtonBar && !IMMChosen && (
                    <StepButtonBar
                      isSubmitting={isSubmitting}
                      submitName={submitName}
                      resetName={resetName}
                    />
                  )}
                </>
              ) : (
                <>
                  <TransactionSubStepper
                    steps={reviewSignSubSteps}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    explodeSteps={false}
                  />

                  {!customerSignedDocument ? (
                    <StepButtonBar
                      isSubmitting={signingCustomerDocument}
                      submitName="Sign Electronically"
                      resetName={resetName}
                    />
                  ) : (
                    <Alert severity="success">
                      <AlertTitle>Esign documents</AlertTitle>
                      <Typography variant="body2">
                        This document has been signed and submitted for
                        processing. You will receive an email notification once
                        the transaction has been processed.{' '}
                        <Link
                          sx={{ cursor: 'pointer', fontStyle: 'italic' }}
                          onClick={streamPdfDocument}
                        >
                          View Signed Document{' '}
                          <Icon component={OpenInNewIcon} fontSize="inherit" />
                        </Link>
                      </Typography>
                      <Typography variant="body2" pt={2}>
                        <Link
                          sx={{ cursor: 'pointer', fontStyle: 'italic' }}
                          href="/"
                        >
                          Return to Account
                        </Link>
                      </Typography>
                    </Alert>
                  )}
                </>
              )}
            </Form>
          );
        }}
      </Formik>
      <SiraModal
        title="Electronic Signature"
        open={open}
        handleClose={handleClose}
      >
        <>
          <Typography variant="body2" pt={2}>
            I understand that the electronically stored copy of my signature is
            considered to be the true, accurate, and complete record, legally
            enforceable in any proceeding to the same extent as if such
            documents were originally generated and maintained in printed form.
          </Typography>
          <Box sx={contentStyle} pt={2}>
            <TextField
              fullWidth
              value={userSignature}
              label="Name"
              onChange={handleChange}
            />
          </Box>
          <Box sx={buttonContentStyle}>
            <Box pr={2}>
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{ whiteSpace: 'nowrap' }}
              >
                Cancel
              </Button>
            </Box>
            <Box pr={2}>
              <Button
                data-qa="esign-modal_accept"
                variant="contained"
                color="primary"
                onClick={handleAccept}
                disabled={!userSigned}
                sx={{ whiteSpace: 'nowrap' }}
                startIcon={<TransactionSignatureIcon sx={{ mr: 1 }} />}
              >
                Accept
              </Button>
            </Box>
          </Box>
        </>
      </SiraModal>
    </>
  );
}

export default ReviewDocumentForm;
