import React from 'react';
import { Typography } from '@mui/material';

import { AccountOwner } from '../../../api/AccountOwnerApi.d';
import { Account, AccountType } from '../../../api/AccountApi.d';
import { LifeExpectancyTerm } from '../../../api/LifeExpectancyApi.d';
import { RadioGroupOption } from '../SiraRadioField';
import { determineAgeGroup } from '../../../app.constants';
import { TermChoiceOption } from '../../../api/RecurringDistributionApi.d';
import { BeneficiaryTypes } from '../../../api/BeneficiariesApi.d';

export function getTermChoices(
  rmd: LifeExpectancyTerm = {}
): Array<RadioGroupOption> {
  const {
    account,
    accountOwner,
    uniformLifetimeRMD,
    jointLifeExpectancyRMD,
    primaryBeneficiaries,
    singleLifeExpectancyRMD,
    singleLifeExpectancyNonRecalculatedRMD,
  } = rmd || {};
  const { accountType, eligibleDesignatedBeneficiary, relationship } =
    account as Account;
  const { dateOfBirth } = accountOwner as AccountOwner;
  const radioOptions = [] as Array<RadioGroupOption>;
  const {
    currently73Years,
    over73,
    underHalf59,
    betweenHalf59and73,
    turning73ThisYear,
    yearsTo73,
  } = determineAgeGroup(dateOfBirth);

  // Inherited Accounts
  if (
    [AccountType.inheritedRoth, AccountType.inheritedTraditional].includes(
      accountType
    )
  ) {
    if (eligibleDesignatedBeneficiary) {
      if (relationship !== BeneficiaryTypes.SPOUSE) {
        radioOptions.push({
          label: `${singleLifeExpectancyNonRecalculatedRMD.lifeExpectancy} years based on the account owner’s life expectancy.`,
          value: TermChoiceOption.slenonrecalc,
          afterContent: (
            <Typography variant="body2">
              Eligible designated beneficiaries are any of the following:
              <li>Spouse of the deceased owner</li>
              <li>Disabled</li>
              <li>Chronically ill</li>
              <li>
                Beneficiary is not more than 10 years younger than the decedent
              </li>
              <li>Child of the decedent who are under the age of majority</li>
            </Typography>
          ),
        });


        radioOptions.push({
          label: `Fewer than ${singleLifeExpectancyNonRecalculatedRMD.lifeExpectancy} years.`,
          value: [AccountType.inheritedRoth].includes(accountType)
            ? TermChoiceOption.rothTermInherited
            : TermChoiceOption.tradInherited,
        });
      } else {
        radioOptions.push({
          label: `${uniformLifetimeRMD.distributionPeriod} years based on the account owner’s age on the uniform lifetime table.`,
          value: TermChoiceOption.ult,
        });
        
        radioOptions.push({
          label: `${singleLifeExpectancyRMD.lifeExpectancy} years based on the account owner’s age on the single life expectancy table.`,
          value: TermChoiceOption.sle,
          afterContent: (
            <Typography variant="body2">
              Eligible designated beneficiaries are any of the following:
              <li>Spouse of the deceased owner</li>
              <li>Disabled</li>
              <li>Chronically ill</li>
              <li>
                Beneficiary is not more than 10 years younger than the decedent
              </li>
              <li>Child of the decedent who are under the age of majority</li>
            </Typography>
          ),
        });


        radioOptions.push({
          label: `Fewer than ${singleLifeExpectancyRMD.lifeExpectancy} years.`,
          value: [AccountType.inheritedRoth].includes(accountType)
            ? TermChoiceOption.rothTermInherited
            : TermChoiceOption.tradInherited,
        });
      }
    }

    // Human owner has 'firstName' and 10-year rule
    if (
      accountOwner.firstName &&
      [AccountType.inheritedRoth].includes(accountType)
    ) {
      radioOptions.push({
        label: `10 years or fewer`,
        value: TermChoiceOption.inheritedRoth10,
      });
    } else if (
      accountOwner.firstName &&
      [AccountType.inheritedTraditional].includes(accountType)
    ) {
      radioOptions.push({
        label: `10 years or fewer`,
        value: TermChoiceOption.inheritedTrad10,
      });
    }

    // Non-Human owner has 'name' and 5-year rule
    if (
      accountOwner.name &&
      [AccountType.inheritedRoth].includes(accountType)
    ) {
      radioOptions.push({
        label: `5 years or fewer`,
        value: TermChoiceOption.inheritedRoth5,
      });
    } else if (
      accountOwner.name &&
      [AccountType.inheritedTraditional].includes(accountType)
    ) {
      radioOptions.push({
        label: `5 years or fewer`,
        value: TermChoiceOption.inheritedTrad5,
      });
    }

    return radioOptions;
  }

  // Roth
  if ([AccountType.rothIra].includes(accountType)) {
    radioOptions.push({
      label: `Distribute a specific amount until the IRA is depleted or a new election is made.`,
      value: TermChoiceOption.rothInfinite,
    });
    radioOptions.push({
      label: `Distribute a specific amount over a certain number of years.`,
      value: TermChoiceOption.rothTerm,
    });

    return radioOptions;
  }

  // Traditional
  // YR24-1054 Add a term of -1 years for Traditional IRA recurring distribution
  if (
    ([
      AccountType.traditionalIra,
      AccountType.traditionalSep,
      AccountType.simpleIra,
    ].includes(accountType) &&
    betweenHalf59and73 && !turning73ThisYear)
  ) {
    radioOptions.push({
      label: `Distribute a specific amount until the IRA is depleted or a new election is made.`,
      value: TermChoiceOption.tradInfinite,
    });
  }



  // All Others by Age Group
  if (over73 || turning73ThisYear || currently73Years) {
    // greater between the two years
    const termYears =
      jointLifeExpectancyRMD?.lifeExpectancyFactor >
      uniformLifetimeRMD.distributionPeriod
        ? jointLifeExpectancyRMD.lifeExpectancyFactor
        : uniformLifetimeRMD.distributionPeriod;

    radioOptions.push({
      label: `Distributions over ${uniformLifetimeRMD.distributionPeriod} years based on the account owner’s life expectancy.`,
      value: TermChoiceOption.ult,
    });
    if (jointLifeExpectancyRMD) {
      radioOptions.push({
        label: ` Distributions over ${jointLifeExpectancyRMD.lifeExpectancyFactor}
              years based on the joint life expectancy of the account owner and
              the spouse. All of the following must be true:`,
        value: TermChoiceOption.jle,
        afterContent: (
          <Typography variant="body2">
            <li>The account owner is married,</li>
            <li>
              the spouse is the sole primary beneficiary for the entire
              distribution year, and
            </li>
            <li>the spouse is more than 10 years younger than the owner.</li>
          </Typography>
        ),
        forceAfterContent: true,
      });
    } else if (primaryBeneficiaries.length === 0) {
      radioOptions.push({
        label: `Distributions based on the joint life expectancy of the account
            owner and the spouse. All of the following must be true:`,
        value: TermChoiceOption.jle,
        afterContent: (
          <Typography variant="body2">
            <li>The account owner is married,</li>
            <li>
              the spouse is the sole primary beneficiary for the entire
              distribution year, and
            </li>
            <li>the spouse is more than 10 years younger than the owner.</li>
          </Typography>
        ),
        forceAfterContent: true,
      });
    }
    radioOptions.push({
      label: `Distributions for fewer than ${termYears} years.`,
      value: TermChoiceOption.rothTermJle,
    });
  }

  if (underHalf59) {
    radioOptions.push({
      label: `Calculate distributions using the Uniform Lifetime Table`,
      value: TermChoiceOption.ult,
      afterContent: (
        <Typography variant="body2">
          Distributions will be based on the account owner’s life expectancy
          divisor of {uniformLifetimeRMD.distributionPeriod} years.
        </Typography>
      ),
    });

    if (singleLifeExpectancyRMD) {
      radioOptions.push({
        label: `Calculate distributions using the Single Life Expectancy Table.`,
        value: TermChoiceOption.sle,
        afterContent: (
          <Typography variant="body2">
            Distributions will be based on the account owner’s life expectancy
            divisor of {singleLifeExpectancyRMD.lifeExpectancy} years.
          </Typography>
        ),
      });
    }

    // if user does not get jls back we shld default message to be based of ule
    if (jointLifeExpectancyRMD || primaryBeneficiaries.length === 0) {
      radioOptions.push({
        label: `Calculate distributions using the Joint Life and Last Survivor Expectancy Table.`,
        value: TermChoiceOption.jle,
        afterContent:
          rmd && jointLifeExpectancyRMD ? (
            <Typography variant="body2">
              Distributions will be based on the joint life expectancy divisor
              of the account owner and the account owner’s oldest primary
              beneficiary
              {rmd &&
                jointLifeExpectancyRMD &&
                ` -- ${jointLifeExpectancyRMD.lifeExpectancyFactor} years`}
              .
            </Typography>
          ) : (
            <Typography variant="body2">
              Distributions will be based on the account owner’s life expectancy
              divisor of {uniformLifetimeRMD.distributionPeriod} years.
            </Typography>
          ),
      });
    }
  }

  if (betweenHalf59and73 && !turning73ThisYear) {
    radioOptions.push({
      label: 'Stop distributions in the year the owner attains age 73',
      value: TermChoiceOption.stop73,
      afterContent: (
        <Typography variant="body2">
          Upon attaining age 73, the account owner must complete a new RMD
          Election to select a distribution schedule to receive the RMD.
        </Typography>
      ),
    });
    radioOptions.push({
      label: 'Continue distributions after the owner attains age 73',
      value: TermChoiceOption.continuePast73,
      afterContent: (
        <Typography variant="body2">
          Upon attaining age 73, the distribution amounts will equal the greater
          of the amount originally selected by the account owner or the owner’s
          RMD.
        </Typography>
      ),
    });

    if (yearsTo73 > 0) {
      radioOptions.push({
        label: `Distribute a specific amount over a certain number of years `,
        value: TermChoiceOption.before73,
      });
    }
  }

  return radioOptions;
}
